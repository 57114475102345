import React, {useState } from "react";
import {Container } from "react-bootstrap";
import "./SignupPage.css";
import fantasyCards from "../../asset/FantasyCards.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../asset/loginPageBg.png";
import { BPasswordInput } from "../../components/bPasswordInput";
import { BInput } from "../../components/bInput";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";


const ChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  // const [lastName, setLastName] = useState("");
  // const email = location.state && location.state.email;
  const [email, setEmail] = useState(location.state && location.state.email)
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passErr, setPassErr] = useState("");
  const [passErrCon, setPassErrCon] = useState("");
  // const [emailErr, setEmailErr] = useState("");
  const [fnameErr, setFnameErr] = useState("");
  // const [lnameErr, setLnameErr] = useState("");
  // const [fname, setFname] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const handleFirstName = (value) => {
  //   setFname(value);
  // };

  const handleOtp = (value) => {
    const numericValue = value.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 6);
    setOtp(truncatedValue);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    //setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value === password) {
      setPasswordsMatch(true);
      //setPassErrCon("");
    } else {
      setPasswordsMatch(false);
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const isPasswordValid = (email) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{7,}$/.test(email);
  };

  const validateForm = () => {
    let passErr = "";
    let passErrCon = "";

    if (isPasswordValid(password)) {
      setPassErr("");
    } else if (password.length < 7) {
      let passErr = "Password must have 7 characters.";
      setPassErr("Password must have 7 characters.");
    } else {
      passErr =
        "Password must contain 1 uppercase, \n 1 lowercase 1 number and 1 special character.";
      setPassErr(
        "Password must contain 1 uppercase, \n 1 lowercase 1 number and 1 special character."
      );
    }
    if (password === confirmPassword) {
      setPassErrCon("");
    } else {
      passErrCon = "Passwords do not match";
      setPassErrCon("Passwords do not match");
    }
    if (passErr.length === 0 && passErrCon.length === 0 && otp.length === 6) {
      changePassword();
    }
  };

  const changePassword = () => {
    setIsLoading(true);
    const userData = {
      email: localStorage.getItem("forgotEmail"),
      password,
      otp,
    };
    axios
      .post(`/resetPassword`, userData)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(false);
          localStorage.clear("forgotEmail");
          toast.success("password has been changed successfully");
          navigate("/login", { state: { email: email }, replace: true });
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong try again later"
        );
      });
  };

  return (
    <div className="container-fluid">
      <div className="background-image">
        <img
          src={backgroundImage}
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
      </div>
      <Container className="d-flex justify-content-center align-items-center vheight">
        <div
          className="bg-white rounded changePwd"
        
        >
          <h2 className="mb-4 text-center">
            <img src={fantasyCards} alt="logo" />
          </h2>
          <div className="signin-text">Change YOUR Password</div>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
          ></div>
          <div>
            <BPasswordInput
              previousSibling={
                <div>
                  <span className="formLabel">New Password</span>
                </div>
              }
              placeholder={"Password"}
              className="formControl"
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              errorMsg={passErr}
              isError={passErr.length > 0}
              autoFocus={true}
            />
          </div>
          <div>
            <BPasswordInput
              previousSibling={
                <div>
                  <span className="formLabel">Confirm Password</span>
                </div>
              }
              placeholder={"Confirm Password"}
              className="formControl"
              value={confirmPassword}
              onChange={(e) => handleConfirmPasswordChange(e)}
              errorMsg={passErrCon}
              isError={passErrCon.length > 0}
              passwordEqual={passwordsMatch}
              onPressEnter={() => validateForm}
            />
          </div>
          <div>
            <BInput
              previousSibling={
                <div className="formLabel">Verification Code</div>
              }
              placeholder={"Verification Code"}
              //autoFocus={true}
              value={otp}
              onChange={(value) => handleOtp(value)}
              isError={fnameErr.length > 0}
              errorMsg={fnameErr}
              className="formControl"
            />
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
              fontSize: "10px",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              variant="primary"
              type="submit"
              //disabled={!formValid}
              className="submit"
              onClick={validateForm}
            >
              {/* CHANGE PASSWORD */}
              {isLoading
                ? "CHANGING PASSWORD PLEASE WAIT..."
                : "CHANGE PASSWORD"}
            </button>
          </div>
          <div className="signup-text">
            <div>
              Already have an account?
              <Link to="/login" relative="path">
                {" "}
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ChangePassword;
