import React, { useState, useEffect, useContext} from "react";
// import { useForm } from "react-hook-form";
import { Container, Col, Row } from "react-bootstrap";
import styles from "../Cart/Cart.module.css";
import OrderSummary from "../OrderSummary/OrderSummary";
import "./Payment.css";
// import mastercard from "../../asset/payment-icons/mastercard.svg";
import emptyPayment from "../../asset/payment-icons/add-payment.svg";
import visa from "../../asset/payment-icons/visa.svg";
import { Form } from "react-bootstrap";
import { AddNewCard } from "./AddNewCard";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import StripeCard from "./StripeCard";
import { SummaryContext } from "../../Context/OrderSummaryContext";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { PaymentCardContext } from "../../Context/PaymentCardContext";
import { OrderContext } from "../../Context/OrderContextProvider";
import { useNavigate } from 'react-router-dom'
import { CartContext } from '../../Context/CartItemContextProvider';
import cardRemove from "../../asset/removeCard.svg"
import removeRedCard from "../../asset/removeRedCard.svg";
import { usaPriceformat } from "../../utils/commonFunction";
const stripePromise = window.location.origin == 'https://www.fantasy-cards.com' ?
  (loadStripe('pk_live_51Jmf0DL0lWtv0rcv6Io6EKVJWtgXPgTnLWriDdgfgDu59ia2knOU3oTm5y1iu66JzENpLxgW0xjv03bPAQib7PND00sJiiA4iq')):
  (loadStripe('pk_test_51NifcpAl3Rt3hI9zkENdKXoCxQWc50p5o7dECIxU78g2h1Y2B7ShIUeziDPqwSUII0AhY29wgJEnvT8VCeZOQL8n00XgtTMyfU'));

export default function Payment(props) {
 const navigate = useNavigate()
  const isAuthenticated = localStorage.getItem("token") ? true : false;
  const {cardData,fetchCardData, selectedCard, createPayment, isLoading} = useContext
  (PaymentCardContext);
  const { orderDetail } = useContext(OrderContext);
  const [checkOutData, setCheckOutData] = useState()
    const { getSummaryData, setSummaryData } = useContext(SummaryContext);
  const [show, setShow] = useState(false);
  // const [isCardSelected, setIsCardSelected] = useState(false);
  // const [isLoading, setIsLoading] = useState(false)
     const { itemCounter } = useContext(CartContext);

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  }

  const toggle = () => setShow(!show);
  const addNewCardDeatils = () => {
  setShow(!show);
  };

  const onSubmit = () => {
    if (!selectedCard) {
      toast.error("Please select a payment card before proceeding.");
      return;
    }
    createPayment(orderDetail?.orderId, selectedCard);
  };

    const getCardDetails = (listingId) => {
    const url = `listing/${listingId}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
   .request(config)
  .then((response) => {
    if (response?.data?.statusCode === 200) {
      setCheckOutData(response?.data?.data?.listing);
      if (props?.type && props?.bidId) {
    getSummaryData(props?.type, props?.bidId, '').then((res) => {
        setSummaryData(res?.data?.data?.orderSummary);
      });
    } else if (props.buynow && props.buynow?.listingId && props.buynow?.qty) {
        getSummaryData('buynow',props.buynow?.listingId,props.buynow?.qty).then((res) => {
        setSummaryData(res?.data?.data?.orderSummary);
      });
     }
    }
  })
      .catch((error) => {
      });
  };
  
  
  useEffect(() => {
    isAuthenticated && fetchCardData();
    window.scrollTo({ top: 0, behavior: "smooth" });
      props?.buynow?.cardTypeforPay=="buynow" && getCardDetails(props?.buynow?.listingId);
   props?.buynow?.cardTypeforPay=="auction" && getCardDetails(props?.buynow?.listingId);
  }, []); 


   return (
    <>
     <Elements stripe={stripePromise}>

    <Container>
      <Row className="col-12 ">
             <Col lg={6} md={12} className=' mx-md-5 mx-2'>
            <Col  className={`${styles.card_mainContainer}`}>
          <h3 className={`${styles.cartHeading}`}>Description</h3>
         {props?.buynow?.cardTypeforPay!="buynow" &&  props?.buynow?.cardTypeforPay!="auction" && <Col className={styles.card_contentSection}>
            {itemCounter?.cartItem?.map((item, idx) => {
             return <Row className={styles.card_Container} key={idx}>
                <Col lg={11} md={12}>
                  <div className="d-flex justify-content-between cartMain">
                    <img src={item?.listingInfo?.cardInfo?.image?.thumbnail ||item?.listingInfo?.cardInfo?.image?.front} onClick={() => onClickHandler(item?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                    <div className={`row-1 ${styles.card_content}`}>
                     <div className='col-sm-10 col'>
                       {/* <div className='d-flex flex-wrap infocartDiv'> */}
                       <div className={`${styles.card_title}`}>{item?.listingInfo?.cardInfo?.title}</div>
                         <div className={`cardCat`}>{item?.listingInfo?.cardInfo?.categoryInfo?.name}</div>
                        <div className={`${styles.card_price}`}>{usaPriceformat(item?.listingInfo?.marketplace?.price)}</div>
                         {/* </div> */}
                       
                      </div>
                      <div>
                       <div className="cardQty">
                         <div>Net Quantity </div>
                         <div>{item?.qty}</div>
                       </div>
                       
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
})}
            </Col> }
           {(props?.buynow?.cardTypeforPay=="buynow" && checkOutData) && <Col className={styles.card_contentSection}>
              <Row className={styles.card_Container} >
                <Col lg={11} md={12}>
                  <div className="d-flex justify-content-between cartMain">
                    <img src={checkOutData?.cardInfo?.image?.thumbnail || checkOutData?.cardInfo?.image?.front} onClick={() => onClickHandler(checkOutData?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                    <div className={`row-1 ${styles.card_content}`}>
                      <div className='col-sm-10 col'>
                        {/* <div className='d-flex flex-wrap infocartDiv'> */}
                        <div className={`${styles.card_title}`}>{checkOutData?.cardInfo?.title}</div>
                        <div className={`cardCat`}>{checkOutData?.categoryInfo?.name}</div>
                        <div className={`${styles.card_price}`}>{usaPriceformat(checkOutData?.marketplace?.price)}</div>
                        {/* </div> */}
                       
                      </div>
                      <div>
                        <div className="cardQty">
                          <div>Net Quantity </div>
                          <div>{props?.buynow?.qty}</div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col> }
                 {(props?.buynow?.cardTypeforPay == "auction" && checkOutData) && <Col className={styles.card_contentSection}>
                   <Row className={styles.card_Container} >
                     <Col lg={11} md={12}>
                       <div className="d-flex justify-content-between cartMain">
                         <img src={checkOutData?.cardInfo?.image?.thumbnail || checkOutData?.cardInfo?.image?.front} onClick={() => onClickHandler(checkOutData?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                         <div className={`row-1 ${styles.card_content}`}>
                           <div className='col-sm-10 col'>
                             {/* <div className='d-flex flex-wrap infocartDiv'> */}
                             <div className={`${styles.card_title}`}>{checkOutData?.cardInfo?.title}</div>
                             <div className={`cardCat`}>{checkOutData?.categoryInfo?.name}</div>
                             <div className={`${styles.card_price}`}>{usaPriceformat(checkOutData?.auction?.currentBid)}</div>
                        
                             {/* </div> */}
                       
                           </div>
                           <div>
                             <div className="cardQty">
                               <div>Net Quantity </div>
                               <div>1</div>
                             </div>
                       
                           </div>
                         </div>
                       </div>
                     </Col>
                   </Row>
                 </Col>}
          
        </Col>
        <Col
          className={`${styles.card_mainContainer} md-mx-2 savedCardSection mt-5`}
        >
          <h3 className={`${styles.cartHeading} `}>Payments</h3>
          <div className="paymentSection">
              {cardData && cardData.length>0 ? (cardData.map((item, index)=>{
                const {brand, last4, expMonth, expYear, paymentCardId} = item
                return(
                  <div key={index}>
                  <SavedCards brand={brand} last4 = {last4} expMonth={expMonth} expYear={expYear} cardId = {paymentCardId} fetchCardData={fetchCardData}/>
                  </div>
                )
              })) : (
                      isLoading ? <Loader /> :
                <div className="d-block text-center" >
                  <img src={emptyPayment} className="emptyImg my-2"/>
                  <p>No Payment Card Added 💳</p>
                    <p>Uh-oh! It looks like you haven't added a payment card to your account yet.</p>
                </div>
               )}
            </div>
            </Col>
          <Col md={12} lg={6} className="">
            <div className="addPaymentMethod" onClick={() => addNewCardDeatils()}><span className="plusBtn">+</span>Add Payment method</div>
            <AddNewCard show={show} toggle={toggle}/>
      </Col>
        </Col>
        <Col lg={4} md={12}  className=' mx-2'>
          <OrderSummary activeStep={props.activeStep} setActiveStep={props.setActiveStep} onSubmit={onSubmit} isValid={selectedCard} type={props.type}/>
        </Col>
      </Row>
      
    </Container>
    {/* <StripeCard /> */}
    </Elements>
    </>
  );
}

export function SavedCards({brand, last4, expMonth, expYear, cardId}) {
  
  const {fetchCardData, selectedCard, setSelectedCard} = useContext(PaymentCardContext);
const [isHovered, setIsHovered] = useState(false);
  const removeCard = (cardId) =>{
    axios
    .delete(`/paymentCard/${cardId}`)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Card removed successfully");
        fetchCardData()
      } else {
        toast.error("Failed to remove card");
      }
    })
    .catch((error) => {
      toast.error("Error removing card:", error);
    });
  };
   const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


// useEffect(() => {
//   if (cardData && cardData.length > 0) {
//     setSelectedCard(cardData[0]?.paymentCardId);
//   }
// }, [cardData]);


  
  return (
    <Form.Group tag="fieldset">
      <Form.Group className="cardSection" onClick={()=>setSelectedCard(cardId)} >
        <label className="w-100" >
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex w-75 align-items-center">
              <input type="radio" name="radio1" defaultChecked={selectedCard === cardId} />
              {/* <img src={visa} className="mx-2" alt= 'visa'></img> */}
              <div className="d-flex align-items-center cardDetails" title={brand}>
                <div className="cardNumber">•••• {last4}</div>
                <div className="expireDate">Expires {expMonth}/{expYear}</div>
              </div>
            </div>

            <button className="remove_btn" onClick={() => removeCard(cardId)}>
              {!isHovered ? 
                <img src={cardRemove} title='Remove Card'
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={handleMouseLeave}
                /> :
                <img src={removeRedCard} title='Remove Card' alt="removeBtn"
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={handleMouseLeave}
                />
               }
            </button>
          </div>
        </label>
      </Form.Group>
    </Form.Group>
  );
}


