import React, { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from './Containers/Dashboard/Dashboard';
import LoginPage from './Containers/Login/LoginPage';
import SignupPage from './Containers/Signup/SignupPage';
import OtpVerification from './Containers/OTPVerify/OTPVerify';
import ForgotPassword from './Containers/ForgotPassword/ForgotPassword';
import ChangePassword from './Containers/Signup/ChangePassword';
import Profile from './Containers/Profile/Profile';
import Wishlist from './Containers/Wishlist/Wishlist';
import Address from './Containers/Address/Address';
import SoldItems from './Containers/Transaction/Solditems';
import Vault from './Containers/Vault/Vault';
import Categories from './Containers/Categories/Categories';
import CardDetails from './Containers/CardDetails/CardDetails';
import ContactUs from './Containers/ContactUs/Contact';
import PaymentStepper from './Containers/PaymentStepper/PaymentStepper';
import DirectCheckoutStepper from './Containers/PaymentStepper/DirectCheckoutStepper';
import SearchResult from './Containers/SearchResult/SearchResult';
import { SearchContext } from './Context/SearchContextProvider';
import Notfound from './Containers/NotFound/Notfound';
import Faqs from './Containers/FAQs/faqs';
import AboutUs from './Containers/AboutUs/AboutUs';
import Team from './Containers/TeamMember/Team';
import CardListing from './Containers/ListCards/CardListing';
import ShipmentDetails from './Containers/ShipmentDetails/ShipmentDetails';
import MyOrder from './Containers/Transaction/MyOrder';
import Earnings from './Containers/Earnings/Earnings';
import AdminView from './admin/AdminView/AdminView';
import AdminLoginPage from './admin/Login/AdminLoginPage';
import AdminForgotPassword from './admin/Login/AdminForgotPassword';
import AdminChangePassword from './admin/Login/AdminChangePassword';
import ApprovalView from './admin/Approval/ApprovalView';
import AdminListCardView from './admin/ListDigital/AdminListCardView';
import AdminCardDetailsView from './admin/AdminCardDetails/AdminCardDetailsView';
import PrivacyPolicy from './Containers/PrivacyPolicy/PrivacyPolicy';

const Routing = () => {
    const isAuthenticated = localStorage.getItem('token') ? true : false;
    const adminIsAuthenticated = localStorage.getItem('adminToken') ? true : false; // Example check, adjust as needed
    const { searchKeyword } = useContext(SearchContext);

    const commonRoutes = [
        { path: '/login', element: <LoginPage /> },
        { path: '/', element: <Dashboard /> },
        { path: '/forgotPassword', element: <ForgotPassword /> },
        { path: '/changePassword', element: <ChangePassword /> },
        { path: '/signup', element: <SignupPage /> },
        { path: '/otpVerify', element: <OtpVerification /> },
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/category/:categoryId', element: <Categories /> },
        { path: '/card-details/:listingId', element: <CardDetails /> },
        { path: '/shipment-details/:cardTransactionId', element: <ShipmentDetails /> },
        { path: '/contact-us', element: <ContactUs /> },
        { path: '/faqs', element: <Faqs /> },
        { path: '/about-us', element: <AboutUs /> },
        // { path: '/privacy-policy', element: <PrivacyPolicy /> },
        { path: '/team', element: <Team /> },
        { path: '*', element: <Notfound /> },
        searchKeyword !== undefined && { path: '/search', element: <SearchResult /> },
    ].filter(Boolean);

    const authenticatedRoutes = [
        { path: '/cart', element: <PaymentStepper /> },
        { path: '/checkout', element: <DirectCheckoutStepper /> },
        { path: '/wishlist', element: <Wishlist /> },
        { path: '/address', element: <Address /> },
        { path: '/orders', element: <MyOrder /> },
        { path: '/sold-items', element: <SoldItems /> },
        { path: '/vault', element: <Vault /> },
        { path: '/list-card', element: <CardListing /> },
        { path: '/profile', element: <Profile /> },
        { path: '/earnings', element: <Earnings /> },
        
    ];

    const adminAuthenticatedRoutes = [
        // { path: '/admin-dashboard', element: <AdminView /> },
        // { path: '/admin-digital-card', element: <AdminListCardView /> },
        // { path: '/admin-approvals', element: <ApprovalView /> },
        // { path: '/admin-approvals/:listingId', element: <AdminCardDetailsView /> },
        // { path: '*', element: <Navigate to="/admin-dashboard" /> },
    ];

    const adminRoutes = [
        // { path: '/admin', element: <AdminLoginPage /> },
        // { path: '/forgot-password', element: <AdminForgotPassword /> },
        // { path: '/change-password', element: <AdminChangePassword /> },
    ];

    const routes = useRoutes([
        ...commonRoutes,
        ...(!isAuthenticated ? authenticatedRoutes.map(route => ({ ...route, element: <Navigate to="/login" /> })) : authenticatedRoutes),
        ...(!adminIsAuthenticated ? adminAuthenticatedRoutes.map(route => ({ ...route, element: <Navigate to="/admin" /> })) : adminAuthenticatedRoutes),
        ...adminRoutes,
        { path: '*', element: <Notfound /> },
    ]);

    return routes;
};

export default Routing;
