import React, { useEffect, useState } from "react";
import Navbar from "../Navigation/Navbar";
import Footer from "../Footer/Index";
import { useForm } from "react-hook-form";
import axios from "../../utils/axiosConfig";
import "./ShipmentDetails.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Container, Breadcrumb, Col, Row, Form, Button } from "react-bootstrap";
import { ShipmentSuccessPopUp } from "./ShipmentSucess";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
export default function ShipmentDetails() {
  const navigate = useNavigate();
  const { state, location } = useLocation();
  const { cardTransactionId } = useParams();
  const [show, setShow] = useState(false);
  const [shipDetails, setShipDetails] = useState();
  const [shipmentModalData, setShipmentModalData] = useState();
  const toggle = () => {
    setShow(!show);
  };
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();
  const onSubmit = () => {
    // return;
    let data = JSON.stringify({
      cardTransactionId: cardTransactionId,
    });
    let config = {
      method: "post",
      url: `shipment`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setShipmentModalData(response?.data?.data?.shipment);
          setShow(!show);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    if (state != null) {
      getShipmentFilledDetails(state?.shipmentId);
    }
  }, [state]);
  const handleDownloadLabel = () => {
        window.open(shipDetails?.label, "_blank");
    // return;
    // if (shipDetails?.label) {
    //   const link = document.createElement("a");
    //   link.href = shipDetails?.label;
    //   link.download = "shipping_label.pdf";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  };

  const getShipmentFilledDetails = (shipmentId) => {
    // return;
    const url = `shipment/${shipmentId}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(true);
          setShipDetails(response?.data?.data?.shipment);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        toast.error(error?.response?.data?.message);
      });
  };

  const getShipmentDetails = (cardTransactionId) => {
    // return;
    const url = `shipment/transaction/${cardTransactionId}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsLoading(true);
          setShipDetails(response?.data?.data);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    !state?.shipmentId && getShipmentDetails(cardTransactionId);
  }, []);
  function formatString(str) {
    return (
      str &&
      str
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    );
  }
  return (
    <>
      {/* <Navbar /> */}
      {!isLoading ? (
        <Loader />
      ) : (
        <>
          <Container fluid>
            <Row className="breadcrumbBg">
              <Col>
                <div className="container">
                  <Breadcrumb
                    className="Breadcrumb-container"
                    aria-label="breadcrumb"
                  >
                    <Breadcrumb.Item
                      onClick={() => {
                        navigate("/dashboard", { replace: true });
                      }}
                    >
                      Home
                    </Breadcrumb.Item>
                    <>
                      <span className="breadcrumb-arrow">{">"}</span>
                      <Breadcrumb.Item
                        onClick={() => {
                          navigate(`/sold-items`, { replace: true });
                        }}
                      >
                        Sold Items
                      </Breadcrumb.Item>
                      <span className="breadcrumb-arrow">{">"}</span>
                      <Breadcrumb.Item>Shipment Detail Page</Breadcrumb.Item>
                    </>
                  </Breadcrumb>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="py-4 px-4">
            <Row>
              <Col md={6} className={`addRessSection`}>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="shipmentDetails"
                >
                  <div className={`row g-3`}>
                    <h5>Shipper Information</h5>
                    <Form.Group className="col-md-6">
                      <Form.Label>First Name </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={15}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.firstName}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                      <Form.Label>Last Name </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={15}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.lastName}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                      <Form.Label>Email </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={50}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.emailId}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                      <Form.Label>Mobile Number </Form.Label>
                      <Form.Control
                        type="tel"
                        maxLength={18}
                        minLength={6}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.phoneNumber}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>
                        House No., Apartment, Flat, Building, or Suite
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={28}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.addressLine1}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Street, Area, Sector, Village </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={28}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.addressLine2}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={shipDetails?.shipperAddress?.townCity}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>State </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={shipDetails?.shipperAddress?.state}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Zip Code </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={5}
                        minLength={5}
                        placeholder=""
                        value={shipDetails?.shipperAddress?.postCode}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        maxLength={28}
                        value={shipDetails?.shipperAddress?.country}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-12">
                      <Form.Label>Landmark</Form.Label>
                      <Form.Control
                        maxLength={28}
                        type="text"
                        placeholder=""
                        value={shipDetails?.shipperAddress?.landmark}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Col>
              <Col md={6} className={`addRessSection`}>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="shipmentDetails"
                >
                  <div className={`row g-3`}>
                    <h5>Recipient Information</h5>
                    <Form.Group className="col-md-6">
                      <Form.Label>First Name </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={15}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.firstName}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                      <Form.Label>Last Name </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={15}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.lastName}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                      <Form.Label>Email </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={50}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.emailId}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                      <Form.Label>Mobile Number </Form.Label>
                      <Form.Control
                        type="tel"
                        maxLength={18}
                        minLength={6}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.phoneNumber}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>
                        House No., Apartment, Flat, Building, or Suite
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={28}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.addressLine1}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Street, Area, Sector, Village </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={28}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.addressLine2}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={shipDetails?.receiverAddress?.townCity}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>State </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={shipDetails?.receiverAddress?.state}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Zip Code </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={5}
                        minLength={5}
                        placeholder=""
                        value={shipDetails?.receiverAddress?.postCode}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        maxLength={28}
                        value={shipDetails?.receiverAddress?.country}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="col-md-12">
                      <Form.Label>Landmark</Form.Label>
                      <Form.Control
                        maxLength={28}
                        type="text"
                        placeholder=""
                        value={shipDetails?.receiverAddress?.landmark}
                        disabled
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Col>
              <Col md={12} className={`addRessSection`}>
                <Form className="shipmentDetails">
                  <div className={`row g-3  align-items-baseline`}>
                    <h5>Package Details</h5>
                    <Form.Group className="col-md-2 m-0">
                      <Form.Label>Shipment Cost</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={
                          shipDetails?.shippingCost
                            ? "$" + shipDetails.shippingCost
                            : ""
                        }
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="col-md-2 m-0">
                      <Form.Label>Shipment Method</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={formatString(shipDetails?.deliveryServiceType)}
                        disabled
                      />
                    </Form.Group>
                    <div className="packageSize">
                      <Form.Group className="col-md-2 col-4">
                        <Form.Label>Package Weight </Form.Label>
                      </Form.Group>
                      <Form.Group className="col-md-2 col-4">
                        {window.innerWidth > 991 && (
                          <Form.Label>Size </Form.Label>
                        )}
                      </Form.Group>
                      {window.innerWidth > 991 && (
                        <>
                          <Form.Group className="col-md-2 col-4">
                            <Form.Label> </Form.Label>
                          </Form.Group>
                          <Form.Group className="col-md-2 col-4">
                            <Form.Label></Form.Label>
                          </Form.Group>
                          <Form.Group
                            className="col-md-2 col-4"
                            style={{ width: "max-content" }}
                          ></Form.Group>
                        </>
                      )}
                    </div>

                    <Form.Group className="col-md-2 m-0">
                      <Form.Control
                        type="text"
                        min={1}
                        max={1000}
                        placeholder=""
                        value={shipDetails?.packageDetail?.weight?.ounces}
                        disabled
                      />
                      <span className="userInstruction">oz.</span>
                    </Form.Group>

                    <Form.Group className="col-md-2 col-4 m-0">
                      <Form.Control
                        type="text"
                        min={1}
                        max={100}
                        placeholder=""
                        value={shipDetails?.packageDetail?.dimensions?.length}
                        disabled
                      />
                      <span className="userInstruction">In.</span>
                    </Form.Group>
                    {window.innerWidth > 991 ? "x" : ""}
                    <Form.Group className="col-md-2 col-4 m-0">
                      <Form.Control
                        type="text"
                        min={1}
                        max={100}
                        placeholder=""
                        value={shipDetails?.packageDetail?.dimensions?.width}
                        disabled
                      />
                      <span className="userInstruction">In.</span>
                    </Form.Group>
                    {window.innerWidth > 991 ? "x" : ""}
                    <Form.Group className="col-md-2 col-4 m-0">
                      <Form.Control
                        type="text"
                        min={1}
                        max={100}
                        placeholder=""
                        value={shipDetails?.packageDetail?.dimensions?.height}
                        disabled
                      />
                      <span className="userInstruction">In.</span>
                    </Form.Group>
                  </div>
                </Form>
              </Col>
              {state?.shipmentId && (
                <>
                  <div className="shipmentTrackingId my-2">
                    Tracking ID: {shipDetails?.trackingNumber}
                  </div>
                  <Button
                    className="addressSubmitBtn w-auto m-0"
                    // disabled={isLoading}
                    onClick={()=>handleDownloadLabel()}
                  >
                    Download Label
                  </Button>
                </>
              )}
              {!state?.shipmentId && (
                <>
                  <Button
                    className="addressSubmitBtn w-auto"
                    type="submit"
                    // disabled={isLoading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Proceed
                  </Button>
                  {show && (
                    <ShipmentSuccessPopUp
                      show={show}
                      toggle={toggle}
                      cardContent={`Your shipment has been created.`}
                      shipmentModalData={shipmentModalData}
                    />
                  )}
                </>
              )}
            </Row>
          </Container>
        </>
      )}
      {/* <Footer /> */}
    </>
  );
}
