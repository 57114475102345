import React from 'react'
import SkeletonCard from '../../asset/skeleton-card.svg'
import './SkeletonImg.css'

export default function SkeletonImg({className,  isVisible }) {
    return (
        <div className={`${className} skeleton-container container`} style={{ display: isVisible ? "block" : "none" }}>
          <img src={SkeletonCard} alt='fantasy-card'/>
        </div>
      );
}
