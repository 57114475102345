import React, { useState, useEffect } from "react";
import "./Index.css";
import SkeletonImg from '../../../components/SkeletonImage/SkeletonImg'
import { BiStopwatch } from 'react-icons/bi'
import { getTimeRemaining, usaPriceformat } from "../../../utils/commonFunction";
import { MdOutlineStarRate, MdOutlineDiamond } from 'react-icons/md'
import heartFillBtn from "../../../asset/heartFillBtn.svg";
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { toast } from "react-toastify";
import GuestLogin from "../../../Containers/Login/GuestLogin";
const Index = ({ title, price, imgUrl, textClr, type, bidTime, minBid, currentBid, rarity, listingId, isFavourite }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(bidTime));
  const [heartBtns, setHeartBtns] = useState(isFavourite);
  const [guestLogin, setGuestLogin] = useState()
  const [showGuest, setShowGuest] = useState(false);
  const toggleGuest = () => setShowGuest(!showGuest);
  const navigate = useNavigate();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = imgUrl;

    const timerInterval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(bidTime));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [bidTime, imgUrl]);
  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
     window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const addRemoveToWishList = () => {
    
    if (guestLogin == "true" || guestLogin==null) {
      setShowGuest(!showGuest);
    } else {
      setHeartBtns(value => !value);
      let data = JSON.stringify({
        listingId: listingId,
      });
      let config = {
        method: "post",
        url: `addRemoveWishlist`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);         
          }
        })
        .catch((error) => {
          
        });
    }
  }
   useEffect(() => {
    const gLogin = localStorage.getItem('guestLogin');
    setGuestLogin(gLogin);
  }, []);

  return (
    <div className="col my-3">
      
      <div className="card-container">
        <div className="img-container">
          <SkeletonImg className={`skeleton-img ${imageLoaded ? 'hidden' : ''}`} isVisible={!imageLoaded} />
          {imageLoaded && (
            <img
              src={imgUrl}
              alt={title}
              onClick={() => onClickHandler(listingId)}
              className={`card-img cat-img`}
            /> )}
          {(rarity === 'signature'||rarity === 'rare') && <div className="sign-tag">
            {rarity === 'signature' && <>
              <MdOutlineStarRate color="white" className="tag-icon"/>
              <span>{rarity.replace(/^./, rarity[0].toUpperCase())}</span>
            </>}
            {rarity === 'rare' && <>
              <MdOutlineDiamond color="white" className="tag-icon"/>
              <span>{rarity.replace(/^./, rarity[0].toUpperCase())} </span>
            </>}   
          </div>}
        </div>
          <div className="align-items-center d-flex justify-content-between">
        <div className="card-title" style={{ color: textClr }}>
          {title}
          </div>
           {type === 'marketplace' && <div className="card-wishlist cursor-pointer" >
            <img className='whiteWishlist' src={heartBtns ? heartFillBtn : "/Images/wishlistBlank.svg"} onClick={()=>addRemoveToWishList(listingId)} />
            </div> }
          </div>

        {type === 'marketplace' ? (
          <div className="card-price" style={{ color: textClr }}>
            {`Price: ${usaPriceformat(price)}`}
          </div>
        ) : type === 'auction' ? (
          <div className="card-price-auction" style={{ color: textClr }}>
            {currentBid ? (currentBid  && <span className="bid">{`Current Bid: ${usaPriceformat(currentBid)}`}</span>):(<span className="bid">{`Min Bid: ${usaPriceformat(minBid)}`}</span>)}
              <span className="time"><BiStopwatch color='#9547cf' />&nbsp;
          { timeRemaining !== "0:00:00:00" ? `${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s` : timeRemaining }
              </span>
          </div>
        ) : null}
      </div>
       {(guestLogin=="true" || guestLogin==null) && <GuestLogin
                show={showGuest}
              toggle={toggleGuest} />
            }
    </div>
  );
};

export default Index;
