import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./DoubleRangePrice.css";
import useDebounce from './useDebounce'

const DoubleRangePrice = ({ min, max, onChange, resetPriceFilters, setResetPriceFilters}) => {
  //debounce here is used for the change in the input
  const [minVal, setMinVal] = useDebounce(min, 300);
  const [maxVal, setMaxVal] = useDebounce(max, 300);

  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (resetPriceFilters) {
      setMinVal(min);
      setMaxVal(max);
      // Reset the input fields to their initial values
      minValRef.current = min;
      maxValRef.current = max;
      
      setResetPriceFilters && setResetPriceFilters(prevVal => !prevVal)
    }
  }, [min, max, resetPriceFilters]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (minVal > maxVal) {
          setMinVal(0)
          setMaxVal(1000000)
      } else if(isNaN(maxVal)){
        setMaxVal(1000000)
      } else if(isNaN(minVal)){
        setMinVal(0)
      }
    }, 2000);
    onChange({ min: minVal, max: maxVal });
    return () => {
      clearTimeout(timeoutId);
    };
  }, [minVal, maxVal, onChange]);

  const handleChange = (event) => {

    const { name, value, type} = event.target;
    const inputValue = parseFloat(value);

    if (type === 'range' || type === 'number') {
      if (name === 'minVal') {
        if (inputValue < 0) {
          setMinVal(0);
        } else {
          const newValue = Math.min(1000000, inputValue);
          setMinVal(newValue);
        }
      } else if (name === 'maxVal') {
        if (inputValue > 1000000) {
          setMaxVal(1000000);
        } else {
          const newValue = Math.max(0, inputValue);
          setMaxVal(newValue);
        }
      }
    }
  };

  return (
    <div className="container container-fluid" style={{paddingBottom:'40px'}}>
      <input
        type="range"
        name= "minVal"
        min={min}
        max={max}
        value={minVal}
        onChange={handleChange}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        name = "maxVal"
        min={min}
        max={max}
        value={maxVal}
        onChange={handleChange}
        className="thumb thumb--right"
      />
      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        {/* <div className="slider__left-value">{`$ ${minVal}`}</div>
        <div className="slider__right-value">{`$ ${maxVal}`}</div> */}
      
        <div className="slider__left-value">
        <label htmlFor="minVal">$ </label>
        <input
        name='minVal'
        type="number"
        value={minVal} 
        onChange={handleChange}
        />
        <div>Min.</div>
        </div>
      
      <div className="slider__right-value">
      <label htmlFor="maxVal">$ </label>
      <input
        name='maxVal'
        type="number"
        value={maxVal}
        onChange={handleChange}
      />
      <div>Max.</div>
      </div>
    </div> 
    </div>
  );
};

DoubleRangePrice.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  resetPriceFilters: PropTypes.bool.isRequired,
  setResetPriceFilters:PropTypes.func.isRequired,
};

export default DoubleRangePrice;
