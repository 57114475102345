import React, { useContext, useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import styles from "../Cart/Cart.module.css";
import OrderSummary from "../OrderSummary/OrderSummary";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import "./CheckoutForm.css";
import img1 from "../../asset/cart-page/img1.svg";
import img2 from "../../asset/cart-page/img2.svg";
import img3 from "../../asset/cart-page/img3.svg";
// import { ProfileContext } from "../../Context/ProfileContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { OrderContext } from "../../Context/OrderContextProvider";
import { SummaryContext } from "../../Context/OrderSummaryContext";
// import AddressModal from "../Address/AddressModal";
import { CartContext } from '../../Context/CartItemContextProvider';
import Loader from "../../components/Loader/Loader";
import { useNavigate } from 'react-router-dom'
import ChangeAddressModal from "../Address/ChangeAddressModal";
import { usaPriceformat } from "../../utils/commonFunction";
import ServiceList from "../../components/ServiceList/ServiceList";
import PaymentServiceList from "../../components/ServiceList/paymentServiceList";
export default function Checkout(props) {
  const [modalAddress, setModalAddress] = useState(false);
   const toggleAddress = () => {
    setModalAddress(!modalAddress);
  };
  const navigate = useNavigate()
  const [addressDefault, setAddressDefault] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [checkOutData, setCheckOutData] = useState()
  const [serviceType, setServiceType] = useState(false);
  const [shippingCost, setShippingCost] = useState(false);
  const [addressUpdation, setAddressUpdation] = useState();
  const { itemCounter } = useContext(CartContext);
  var isPhysicalCardInCart;
  if (props?.buynow?.cardDetailType === "physical") {
    isPhysicalCardInCart = true;
  } else if (props?.buynow?.cardDetailType === "digital") {
    isPhysicalCardInCart = false;
  } else {
    isPhysicalCardInCart = itemCounter?.cartItem?.some(item => item?.listingInfo?.cardInfo?.type === "physical") ? true : false
  }
// Now you have a boolean variable isPhysicalCardInCart which will be true if any card in the cart is physical

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  }
  const { setOrderDetail, PostOrderDetails , isOrderDone} = useContext(OrderContext);
  const { getSummaryData, setSummaryData, cardType } = useContext(SummaryContext);
  const [cartType, setCartType] = useState(cardType || "marketplace")
  


    const getCardDetails = (listingId) => {
    const url = `listing/${listingId}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
   .request(config)
  .then((response) => {
    if (response?.data?.statusCode === 200) {
      setCheckOutData(response?.data?.data?.listing);
      if (props?.type && props?.bidId) {
    getSummaryData(props?.type, props?.bidId, '').then((res) => {
        setSummaryData(res?.data?.data?.orderSummary);
      });
    } else if (props?.buynow && props?.buynow?.listingId && props?.buynow?.qty) {
        getSummaryData('buynow',props?.buynow?.listingId,props?.buynow?.qty, JSON?.parse(sessionStorage?.getItem('serviceType'))).then((res) => {
        setSummaryData(res?.data?.data?.orderSummary);
      });
     }
    }
  })
      .catch((error) => {
      });
  };

  const onSubmit = (obj) => {
    if (!obj) {
      toast.error("please add your address to proceed") 
      return;
    }
    const data = {
       "firstName": obj?.firstName,
        "lastName": obj?.lastName,
        "emailId": obj?.emailId,
        "phoneNumber": obj?.phoneNumber,
        "country": obj?.country,
        "postCode": obj?.postCode,
        "addressLine1": obj?.addressLine1,
        "addressLine2":obj?.addressLine2,
        "townCity": obj?.townCity,
        "state": obj?.state,
   }

    PostOrderDetails(data, props?.type, props?.bidId,props?.buynow, JSON?.parse(sessionStorage?.getItem('serviceType')))
      .then((res) => {
        setOrderDetail(res?.data?.data?.order);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ||
            "Something went wrong, try again later"
        );
      });
  };

  const getDefaultAddress = () => {
    // return;
    const url = `address/default`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setAddressDefault(response?.data?.data?.address);
           setIsLoading(true);
        }
      })
      .catch((error) => {
          toast.error(error?.response?.data?.message)
      });
  };
  useEffect(() => {
   props?.buynow?.cardTypeforPay=="buynow" && getCardDetails(props?.buynow?.listingId);
   props?.buynow?.cardTypeforPay=="auction" && getCardDetails(props?.buynow?.listingId);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  useEffect(() => {
    getDefaultAddress();
  }, [modalAddress])


   useEffect(() => {
     if (addressUpdation !== undefined) {
      if (props?.type && props?.bidId) {
         getSummaryData(props?.type, props?.bidId, '').then((res) => {
           setSummaryData(res?.data?.data?.orderSummary);
         });
       } else if (props?.buynow && props?.buynow?.listingId && props?.buynow?.qty) {
         getSummaryData('buynow', props?.buynow?.listingId, props?.buynow?.qty, JSON?.parse(sessionStorage?.getItem('serviceType'))).then((res) => {
           setSummaryData(res?.data?.data?.orderSummary);
         });
       }
       else if (cartType !== undefined) {
         getSummaryData('',cartType).then((res) => {
          setSummaryData(res?.data?.data?.orderSummary);
        });
       }
      else {
        setSummaryData({});
       }
     }
  }, [addressUpdation])

  return (
    <Container>
      <Row className="col-12 ">
         <Col lg={6} md={12} className=' mx-md-5 mx-2'>
          <Col  className={`${styles.card_mainContainer}`}>
          <h3 className={`${styles.cartHeading}`}>Description</h3>
         {props?.buynow?.cardTypeforPay!="buynow" && props?.buynow?.cardTypeforPay!="auction" && <Col className={styles.card_contentSection}>
            {itemCounter?.cartItem?.map((item, idx) => {
             return <Row className={styles.card_Container} key={idx}>
                <Col lg={11} md={12}>
                  <div className="d-flex justify-content-between cartMain">
                    <img src={item?.listingInfo?.cardInfo?.image?.thumbnail ||item?.listingInfo?.cardInfo?.image?.front} onClick={() => onClickHandler(item?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                    <div className={`row-1 ${styles.card_content}`}>
                     <div className='col-sm-10 col'>
                       {/* <div className='d-flex flex-wrap infocartDiv'> */}
                       <div className={`${styles.card_title}`}>{item?.listingInfo?.cardInfo?.title}</div>
                         <div className={`cardCat`}>{item?.listingInfo?.cardInfo?.categoryInfo?.name}</div>
                        <div className={`${styles.card_price}`}>{usaPriceformat(item?.listingInfo?.marketplace?.price)}</div>
                         {/* </div> */}
                       
                      </div>
                      <div>
                       <div className="cardQty">
                         <div>Net Quantity </div>
                         <div>{item?.qty}</div>
                       </div>
                       
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
})}
            </Col> }
            {(props?.buynow?.cardTypeforPay == "buynow" && checkOutData) && <Col className={styles.card_contentSection}>
              <Row className={styles.card_Container} >
                <Col lg={11} md={12}>
                  <div className="d-flex justify-content-between cartMain">
                    <img src={checkOutData?.cardInfo?.image?.thumbnail || checkOutData?.cardInfo?.image?.front} onClick={() => onClickHandler(checkOutData?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                    <div className={`row-1 ${styles.card_content}`}>
                      <div className='col-sm-10 col'>
                        {/* <div className='d-flex flex-wrap infocartDiv'> */}
                        <div className={`${styles.card_title}`}>{checkOutData?.cardInfo?.title}</div>
                        <div className={`cardCat`}>{checkOutData?.categoryInfo?.name}</div>
                        <div className={`${styles.card_price}`}>{usaPriceformat(checkOutData?.marketplace?.price)}</div>
                        {/* </div> */}
                       
                      </div>
                      <div>
                        <div className="cardQty">
                          <div>Net Quantity </div>
                          <div>{props?.buynow?.qty}</div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>}
            {(props?.buynow?.cardTypeforPay == "auction" && checkOutData) && <Col className={styles.card_contentSection}>
              <Row className={styles.card_Container} >
                <Col lg={11} md={12}>
                  <div className="d-flex justify-content-between cartMain">
                    <img src={checkOutData?.cardInfo?.image?.thumbnail || checkOutData?.cardInfo?.image?.front} onClick={() => onClickHandler(checkOutData?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                    <div className={`row-1 ${styles.card_content}`}>
                      <div className='col-sm-10 col'>
                        {/* <div className='d-flex flex-wrap infocartDiv'> */}
                        <div className={`${styles.card_title}`}>{checkOutData?.cardInfo?.title}</div>
                        <div className={`cardCat`}>{checkOutData?.categoryInfo?.name}</div>
                        <div className={`${styles.card_price}`}>{usaPriceformat(checkOutData?.auction?.currentBid)}</div>
                        {/* </div> */}
                       
                      </div>
                      <div>
                        <div className="cardQty">
                          <div>Net Quantity </div>
                          <div>1</div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>}
          
        </Col>
          <Col  className={`${styles.card_mainContainer} mt-5`}>
            <h3 className={`${styles.cartHeading} }`}>Delivery Information</h3>
            <div>
              {!isLoading ? <Loader /> : addressDefault ? <Col className={`checkOutSection`}>
                <h6 className={`cartHeading`}>Billing Details</h6>
                <div className="addressCheckoutConatiner" >
                  <div className="d-flex justify-content-between flex-wrap">
                    <h4>
                      {addressDefault?.firstName} {addressDefault?.lastName}{" "}
                    </h4>
                    <div className="addressemailId">
                      {addressDefault?.emailId}
                    </div>
                  </div>
                  <div className="address">
                    <p>
                      {addressDefault?.addressLine1}, {addressDefault?.addressLine2}{addressDefault?.landmark ? ", " + addressDefault?.landmark : ""}, {addressDefault?.postCode},

                        
                    </p>
                      {addressDefault?.townCity}, {addressDefault?.state}, {addressDefault?.country}{" "}
                    <p>
                    </p>
                    <p>Phone Number : {addressDefault?.phoneNumber}</p>
                    <ButtonIcon
                      text="Change Address"
                      className="changeAddressbtn"
                      clickHandler={(event) => {
                        event.preventDefault()
                        setModalAddress(!modalAddress);
                        // navigate("/address")
                  
                      }}
                    />
                  </div>
                </div>
                <div className="hideshipment">
                  {isPhysicalCardInCart && <PaymentServiceList setServiceType={setServiceType} serviceType={serviceType} paymentType={props}  />}
                  </div>
                  {/* <div className={`${styles.deliveryContainer} d-flex`}>
                    <div className='d-flex align-items-start vaulttabs'>
                      <img src={img1} className='mRignt' />
                      <div>Estimated  Delivery on time in 3 Days</div>
                    </div>
                    <div className='d-flex align-items-start vaulttabs'>
                      <img src={img3} className='mRignt' />
                      <div>Guranteed 100% Original Product</div>
                    </div>
                    <div className='d-flex align-items-start vaulttabs'>
                      <img src={img2} className='mRignt' />
                      <div>1 Day Returns if you change your mind</div>
                    </div>
                  </div> */}
              </Col> :
                   <Col className={`checkOutSection`}>
                <h6 className={`cartHeading`}>Billing Details</h6>
                <div className="addressCheckoutConatiner" >
                  
                  <div className="address">
                   

                 
                    <ButtonIcon
                      text="Add Address"
                      className="changeAddressbtn"
                      clickHandler={(event) => {
                        event.preventDefault()
                        setModalAddress(!modalAddress);
                        // navigate("/address")
                  
                      }}
                    />
                  </div>
                  </div>
                  {/* <div className={`${styles.deliveryContainer} d-flex`}>
                    <div className='d-flex align-items-start vaulttabs'>
                      <img src={img1} className='mRignt' />
                      <div>Estimated  Delivery on time in 3 Days</div>
                    </div>
                    <div className='d-flex align-items-start vaulttabs'>
                      <img src={img3} className='mRignt' />
                      <div>Guranteed 100% Original Product</div>
                    </div>
                    <div className='d-flex align-items-start vaulttabs'>
                      <img src={img2} className='mRignt' />
                      <div>1 Day Returns if you change your mind</div>
                    </div>
                  </div> */}
              </Col> 
              }

               
            </div>
        </Col>
        </Col>
        {modalAddress && (
          <ChangeAddressModal modalAddress={modalAddress} toggleAddress={toggleAddress} changesAddressID={addressDefault?.addressId} setAddressUpdation={setAddressUpdation} addressUpdation={addressUpdation} />
        )}
         <Col lg={4} md={12}   className=' mx-2'>
            <OrderSummary
              activeStep={props.activeStep}
              setActiveStep={props.setActiveStep}
              onSubmit={() => onSubmit(addressDefault)} 
              isOrderDone={isOrderDone}
              isValid = {addressDefault !=null ? true: false}
              type={props.type}
            serviceType={
              !isPhysicalCardInCart ? true :
              (JSON?.parse(sessionStorage?.getItem('serviceType')) || false)
            }
            
            />
          </Col>
        
         
        </Row>
    </Container>
  );
}
