import React, { useEffect, useState } from "react";
import "./Index.css";
import fantasyCards from "../../asset/fc_log_header.svg";
import {LiaFacebookF, LiaTwitter,LiaInstagram , LiaLinkedinIn} from 'react-icons/lia'
import { FaYoutube,FaPhoneAlt} from 'react-icons/fa'
import {IoMdMail} from 'react-icons/io'
import {MdLocationOn} from 'react-icons/md'
import {BiRightArrowAlt} from 'react-icons/bi'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../components/Loader/Loader";

const Index = () => {
  const navigate = useNavigate();
  const [instaPhotos, setInstaPhotos] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState()
  const [subscribing, setSubscribing] = useState(false);

  const Social = [
    {
      icon: <LiaLinkedinIn className="social_icon" />,
      link: "https://www.linkedin.com/company/fantasy-card-usa/?viewAsMember=true",
    },
    {
      icon: <LiaInstagram className="social_icon" />,
      link: "https://www.instagram.com/fantasycardsusa/",
    },
    {
      icon: <LiaFacebookF className="social_icon" />,
      link: "https://www.facebook.com/people/Fantasy-Cards/61553796886367/",
    },
    // {
    //   icon: <FaYoutube className="social_icon" />,
    //   link: "https://www.youtube.com/",
    // },
  ];

  const Contact = [
    // {
    //   icon: <MdLocationOn />,
    //   info:"6890 Blvd, The Bronx, NY 1058 New York, USA"
    // },
    {
      icon:<IoMdMail />,
      info:{emal:'info@fantasy-cards.com'}
    },
    {
      icon:<FaPhoneAlt />,
      info:{phone: "+1 605-340-0690"}
    }

  ]
  const fetchInstaPhotos = () => {
        setIsLoading(true)
    
    const url = `instaPhotos`;
    axios
    .get(url)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        setInstaPhotos(response?.data?.data)
        }
      })
      .catch((error) => {
        setInstaPhotos([])
        if (error?.response?.data.message) {
        }
      })
       .finally(() => {
      setIsLoading(false);
    });
      ;
  };

  const subscribeNow = () => {
     if (subscribing) {
      return;
    }
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  if (!isValidEmail(email)) {
    toast.error("Please enter a valid email address.");
    return;
    }
    setSubscribing(true);

  let config = {
    method: "post",
    url: `subscription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { email },
  };

  axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        setEmail("");
      } else {
        toast.error(
          response?.data?.message || "Something went wrong, try again later."
        );
      }
    })
    .catch((error) => {
      toast.error(
        error.response?.data.message || "Something went wrong, try again later."
      );
    })
       .finally(() => {
        setSubscribing(false); // Reset subscribing state variable after subscription process is complete
      });
};
  useEffect(() => {
    fetchInstaPhotos()
  },[])
  const Weblinks = [
    {url:'/about-us',
    text: 'About' },
    {url:'/team',
    text: 'Team' },
    {url:'/faqs',
    text: 'Help (FAQ)' },
    {url:'/contact-us',
      text: 'Contact'
    },
    // {url:'/privacy-policy',
    // text: 'Privacy Policy' }

  ]
  return (
    <footer id="footer" className="footer-1">
      <div className="pt-5">
        <div className="container my-3">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="widget subscribe no-box">
                <img src={fantasyCards} alt="logo" className="mb-2" style={{cursor:"pointer"}} onClick={() => navigate('/dashboard')}/>
                <h5 className="title-news">Subscribe to Newsletter</h5>
                <div className="emailsection d-flex">
                  <input
                  type="email"
                  value={email}
                  className="subscribe-Input"
                  placeholder="Enter email address"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  />
                  <BiRightArrowAlt className="arrow" onClick={() => subscribeNow()} />
               </div>
                  <div className="mt-2">
                  {Social.map((val, idx) => (
                    <a target="_blank" href={val.link} key={idx}>
                      {val.icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="widget no-box">
                <h5 className="widget-title">
                  Contact Us<span></span>
                </h5>
                <div className="mt-2 thum-widget">
                  {Contact.map((val, idx) => 
                    { return(
                      <div className="thumb-content" key={idx}>
                        <div className='weblinks-icon' >
                          {val.icon}
                        </div>
                          {typeof val.info === 'string'? (<div className='weblinks-text'>{val.info}</div>):(
                            Object.keys(val.info).map((key, index) => (
                              <div  className="weblinks-text" key={index}>{val.info[key]}</div>))
                        )}
                     
                      </div>
                    )
                    }
                    )}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="widget no-box">
                <h5 className="widget-title">
                  Additional Links<span></span>
                </h5>
                <ul className="thumbnail-widget">
                {Weblinks.map((item, index)=>{
                  return(
                    <li className="thumb-content" key={index}>
                      <a onClick={() => navigate(item.url)}>{item.text}</a>
                    </li>
                )})}
                </ul>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="widget no-box">
                <h5 className="widget-title">
                  Instagram<span></span>
                </h5>
           {isLoading ? (
                  // <Loader />
           <></>
              ) : instaPhotos?.length > 0 ? (
                <div className="row row-cols-3">
                  {instaPhotos?.map((val, idx) => (
                    <div className="col my-2" key={idx}>
                      <a target="_blank" href={val?.permalink}><img src={val?.media_url} alt={`post${idx + 1}`} className="insta-img" /></a>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Check back later, photos are coming soon!</p>
              )}
              
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center py-3">
          <p className="text">
            Copyright © {new Date().getFullYear()} Fantasy card. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Index;
