import React, { useEffect, useState, useContext } from "react";
import styles from "./Dashboard.module.css";
import CarouselFC from "../Carousel/HeroCarousel/carousel"
import service from "../../asset/service.png"
import service1 from "../../asset/homeImage/trade1.svg"
import service2 from "../../asset/homeImage/trade2.svg"
import service3 from "../../asset/homeImage/trade3.svg"
import service4 from "../../asset/homeImage/trade4.svg"
import CustomTabBar from "../../components/CustomTabBar/Index";
import ProductCard from "../../components/ProductCard/Index";
import Footer from "../Footer/Index";
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon';
import axios from "../../utils/axiosConfig";
import NavScrollBar from '../Navigation/Navbar';
import { useNavigate } from "react-router-dom";
import CategoryTab from '../../components/CustomTabBar/CategoryTab/CategoryTab';
import SideTitle from '../../components/CommonTitle/SideTitle/Index';
import CommonTitle from '../../components/CommonTitle/Index';
import Loader from '../../components/Loader/Loader'
import TrendingCarousel from "../Carousel/TrendingCarousel/trendingcarousel";
import { CategoryContext } from "../../Context/CategoryContext";
import noCard from "../../asset/noCard.svg"
import { toast } from "react-toastify";

export default function Dashboard() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(1);
  const [allCards, setAllCards] = useState([])
  const [trendcards, setTrendingCards] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false)
  const [allPages, setAllPages] = useState('')
  const [email, setEmail] = useState()
  const [subscribing, setSubscribing] = useState(false);
  const {catTabs, setCatTabs, catSelectedTab, setCatSelectedTab,fetchCategory} = useContext(CategoryContext)
  const [isDisabled, setIsDisabled] = useState(false)
  const [type, setType] = useState('')
  // const [isLastPage, setIsLastPage] = useState(false);
  const Tabs = [
    {
      name: "All  Cards",
      id: 1,
      type:''
    },
    {
      name: "Marketplace",
      id: 2,
      type:'marketplace'
    },
    {
      name: "Public Auction",
      id: 3,
      type:'auction'
    },
  ];
  
  const catTabHandler = (id, name) =>{
    setCatSelectedTab({id,name})

    navigate(`/category/${name}`)
  }

  const tabsClickHandler = (v) => {
    setSelectedTab(v);
    setCurrentPage(1);
    setIsDisabled(false)  
  
    if(v===1){
      setType('')
    } else if(v===2){
      setType('marketplace');
    } else {
      setType('auction')
    }
    
  };

  const cardClickHandler = (listingId)=>{
    navigate(`/card-details/${listingId}`);
  }

  const handleShowMore = () =>{
    if(currentPage<allPages || currentPage==1){
    const nextPage = currentPage + 1;
    setIsLoading(true);
    setCurrentPage(nextPage);
    setIsDisabled(false)

    fetchcards(nextPage, selectedTab === 2 ? "marketplace" : selectedTab === 3 && "auction");

    } else {
       if (currentPage === allPages) {
    setIsDisabled(true)
  } 
  }
  };

const fetchcards = (page, type) => {
  setIsLoading(true);

 const url = type ? `listing?sort=createdAt&page=${page}&type=${type}` : `listing?sort=createdAt&page=${page}`;
  let config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        const catData = response?.data?.data?.listings;
        const pages = response?.data?.data?.totalPages;
        setAllPages(pages);

        if (page === 1) {
          const uniqueListingIds = new Set(catData.map((item) => item.listingId));

          setAllCards([...uniqueListingIds].map((id) => catData.find((item) => item.listingId === id)));
        } else {
          setAllCards((prevData) => {
            const existingIds = new Set(prevData.map((item) => item.listingId));
            const uniqueData = catData.filter((item) => !existingIds.has(item.listingId));
            return [...prevData, ...uniqueData];
          });
        }
      }
    })
    .catch((error) => {
      setAllPages([]);
    })
    .finally(() => {
      setIsLoading(false);
    });
};


  const subscribeNow = () => {
     if (subscribing) {
      return;
    }
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  if (!isValidEmail(email)) {
    toast.error("Please enter a valid email address.");
    return;
    }
     setSubscribing(true);

  let config = {
    method: "post",
    url: `subscription`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { email },
  };

  axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        setEmail("");
      } else {
        toast.error(
          response?.data?.message || "Something went wrong, try again later."
        );
      }
    })
    .catch((error) => {
      toast.error(
        error.response?.data.message || "Something went wrong, try again later."
      );
    })
     .finally(() => {
        setSubscribing(false); // Reset subscribing state variable after subscription process is complete
      });
};


  const trendingCards =()=>{ 
  const url = 'trending'
  let config = {
  method: "get",
  url: url,
  headers: {
    "Content-Type": "application/json",
  },
};  
  axios
  .request(config)
  .then((response) => {
    if (response?.data?.statusCode === 200) {
      const trendCard = response?.data.data.trending
      setTrendingCards(trendCard)
    }
  })
  .catch((error) => {
  });
  };
  
  useEffect(()=>{
    fetchCategory()
    trendingCards();
  },[])

  useEffect(() => {
     if (currentPage === allPages) {
    setIsDisabled(true)
  }
  }, [currentPage]);

  useEffect(() => {
  setAllCards([]); 
  fetchcards(1, type);
  }, [selectedTab, type]);

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Home Page',
      });
    }, [])
  return (
    <div className={styles.dbContainer}>
      {/* <NavScrollBar /> */}
      <div className={styles.dbContent}>
        
        <CarouselFC />
        <CustomTabBar
          tabs={Tabs}
          tabsClickHandler={tabsClickHandler}
          selectedTab={selectedTab}
        />
        <CommonTitle
          title="Let’s Start the Trade"
          subTitle="Our Top Categories"
        />

        <CategoryTab tabs={catTabs} tabsClickHandler={catTabHandler} selectedTab={catSelectedTab}/>
        <div className="container my-2 " id="marg">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
            {allCards!== undefined && allCards?.map((val, index) =>{   
              const {cardInfo, marketplace, listingId, type, auction, isFavourite} = val

              let productCardProps = {};

              if (type === 'marketplace') {
                productCardProps = {
                  key: listingId,
                  title: cardInfo?.title,
                  price: marketplace?.price,
                  imgUrl: cardInfo?.image?.thumbnail || cardInfo?.image?.front,
                  clickHandler: () => cardClickHandler(listingId),
                  type: type,
                  listingId: listingId,
                  isFavourite:isFavourite
                };
              } else {
                productCardProps = {
                  key: listingId,
                  title: cardInfo?.title,
                  minBid: auction?.minBid,
                  currentBid: auction?.currentBid,
                  imgUrl: cardInfo?.image?.thumbnail || cardInfo?.image?.front,
                  clickHandler: () => cardClickHandler(listingId),
                  type: type,
                  bidTimer: auction?.endDate,
                  listingId: listingId,
                };
              }
             return(
              <div key={index} >
              <ProductCard {...productCardProps}/>   
            </div>
            )
    
          })}
          </div>
          {isLoading ? <Loader /> :
          
           allCards?.length<1 && 
            <div className="d-block text-center noCard" style={{ fontSize: "18px" }}>
                <img src={noCard} className="d-block text-center m-auto"  />
                <div style={{padding:"5px 0", fontWeight:"bold"}}>No Cards Found!</div>
                <div style={{color:"#878A99", fontSize:"13px"}}>You don’t have any cards available.</div>
              </div>
          }
        
          {((allPages>1 && allCards?.length>7) && !isDisabled) && <div className={styles.buttonCenter}>
            <ButtonIcon
              text="SEE MORE CARDS"
              clickHandler={()=>handleShowMore()}
              isButtonDisable={isDisabled}
            />
          </div>}
          <div className="row row-cols-1 row-cols-sm-2 my-5" id="margY">
            <div className="col mx-auto">
              <SideTitle
                title="What we Do for"
                subTitle="Cards for Trade"
              />
              <p>
                Introducing "Fantasy Cards" – an exhilarating digital trading cards platform that brings the thrill of collecting and trading to the virtual realm. Immerse yourself in a world where captivating artwork meets online card collections, allowing users to buy, trade, and auction unique digital cards. Participate in live auctions, showcase your prized collection, and engage in seamless transactions with fellow enthusiasts. With an expansive range of cards and dynamic features, Fantasy Cards is not just an app; it's a community where passion for collecting meets the excitement of digital innovation. Join us in reshaping the trading card experience – one digital card at a time!
              </p>
            </div>
            <div className="col mx-auto">
              <div className="d-flex flex-wrap">
              <div className="serViceMain first">
                <img src={service1} alt="service" />
                <p>Diverse Collection</p>
                <div>Fantasy Cards presents a diverse collection of digital cards, from digital monsters to beloved athletes, catering to a variety of interests.</div>
              </div>
               <div className="serViceMain second">
                <img src={service2} alt="service" />
                <p>Interactive Trading</p>
                <div>Engage in real-time trades, negotiate deals, and build your collection with our user-friendly trading platform.</div>
              </div>
               <div className="serViceMain third">
                <img src={service3} alt="service" />
                <p>Live Auctions</p>
                <div>Experience the excitement of live auctions, where you can bid on coveted cards and exclusive items in real-time.</div>
              </div>
               <div className="serViceMain fourth">
                <img src={service4} alt="service"  />
                <p>User-Driven Marketplace</p>
                <div>Take control of your collection by listing cards for auction, making strategic trades, and engaging with a vibrant community.</div>
                </div>
                </div>
            </div>
          </div>
        </div>
        {trendcards?.length > 0 && <div className="bg-dark">
          <div className="container my-5 ">
            <CommonTitle
              title="Latest CARDS"
              subTitle="Trending Cards"
              textClr="#FFF"
            />
            <div className="row row-cols-1 my-5 row-cols-sm-2 row-cols-md-4">
            {trendcards!==undefined && <TrendingCarousel Cards={trendcards} />}
            </div>
          </div>
        </div>}

        <div className={styles.subcriptionbar}>
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 my-3">
              <div>
                <h1>Subscribe Now</h1>
                <span>Enter your email to get a started.</span>
              </div>
              <div className={`d-flex flex-row align-items-center rounded ${styles.subsriptionInput}`}>
                <input
                  type="email"
                  value={email}
                  className="form-control shadow-none o"
                  placeholder="Enter your email"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
                <button
                  className="btn btn-dark text-nowrap text-uppercase"
                  type="submit"
                  onClick={() => subscribeNow()}
                >
                  get started
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <CommonTitle title="Latest Blog" subTitle="Get The Latest News" />
        </div> */}
        {/* <Footer /> */}
      </div>
    </div>
  );
}
