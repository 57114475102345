import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import "./AddressModal.css";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../../utils/axiosConfig";

function AddressModal({ show, toggle, addressByID }) {
  const [isPincodeAvailable, setIsPincodeAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValues: {
      country:"USA"
    },
  });
  useEffect(() => {
    setValue("firstName",addressByID?.firstName || "");
    setValue("lastName", addressByID?.lastName || "");
    setValue("emailId", addressByID?.emailId || "");
    setValue("phoneNumber", addressByID?.phoneNumber || "");
    setValue("country", addressByID?.country || "USA");
    setValue("postCode", addressByID?.postCode || "");
    setValue("addressLine1", addressByID?.addressLine1 || "");
    setValue("addressLine2", addressByID?.addressLine2 || "");
    setValue("landmark", addressByID?.landmark || "");
    setValue("townCity", addressByID?.townCity || "");
    setValue("state", addressByID?.state || "");
    setIsPincodeAvailable(addressByID?.postCode);
  }, [addressByID]);
  const onSubmit = (data) => {
     setIsLoading(true);
    if (addressByID?.addressId) {
      let config = {
        method: "put",
        url: `address/${addressByID?.addressId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
              toggle()
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message)
        });
    } else {
      let config = {
        method: "post",
        url: `address`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
            // setTimeout(() => {
              toggle()
            // }, 1000)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          toast.error(error?.response?.data?.message)
        });
    }
  };

  const onPincodeChange = (event) => {
    setIsPincodeAvailable(false);
  const pincode = event.target.value;
  if (pincode.length >= 5) {
    const url = `zipcode/${pincode}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setValue(
            "state",
            response?.data?.data?.state?.state || ""
          );
          setValue(
            "townCity",
            response?.data?.data?.state?.city || ""
          );
           setIsPincodeAvailable(true);
          // Remove validation for state and town/city
          clearErrors("state");
          clearErrors("townCity");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
         setValue("postCode", "");
      });
  } else {
    // setValue("state", "");
    // setValue("townCity", "");
     setIsPincodeAvailable(false);
    
  }
};


  return (
    <>
      <Modal show={show} onHide={toggle} dialogClassName="address-modal">
        <Modal.Header closeButton>
          <Modal.Title> {addressByID ? "Update Address" : "Add New Address"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className={`addRessSection`}>
                <Form onSubmit={handleSubmit(onSubmit)} >
                  <div className={`row g-3`}>
                   <Form.Group className="col-md-6">
                    <Form.Label>First Name <span className="error-message">*</span></Form.Label>
                    <Form.Control
                        type="text"
                      maxLength={15}
                      {...register("firstName", {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i // Characters and spaces allowed
                      })}
                      isInvalid={errors.firstName}
                      placeholder="First Name"
                    />
                    {errors.firstName && (
                      <Form.Control.Feedback type="invalid">
                        First name is required and should contain only characters.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                    <Form.Group className=" col-md-6">
                      <Form.Label>Last Name <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="text"
                      maxLength={15}
                        {...register("lastName", {
                          required: true,
                          pattern: /^[A-Za-z\s]+$/i
                        })}
                        isInvalid={errors.lastName}
                        placeholder="Last Name"
                      />
                      {errors.lastName && (
                        <Form.Control.Feedback type="invalid">
                          Last name is required and should contain only characters.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className=" col-md-6">
                      <Form.Label>Email <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="text"
                      maxLength={50}
                        {...register("emailId", {
                          required: true,
                          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                        })}
                        isInvalid={errors.emailId}
                        placeholder="Email Address"
                      />
                      {errors.emailId && (
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email address.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className=" col-md-6">
                      <Form.Label>Mobile Number <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="tel"
                       maxLength={18}
                       minLength={6}
                        {...register("phoneNumber", {
                          required: true,
                            pattern: /^(\+[0-9]{1,3})?[\s-]?(\([0-9]{3}\)|[0-9]{3})[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/
                        })}
                        isInvalid={errors.phoneNumber}
                        placeholder="Mobile Number"
                      />
                      {errors.phoneNumber && (
                        <Form.Control.Feedback type="invalid">
                           Please enter a valid mobile number.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  <Form.Group className="col-md-6">
                      <Form.Label>
                        House No., Apartment, Flat, Building, or Suite <span className="error-message">*</span>
                      </Form.Label>
                      <Form.Control
                        
                        type="text"
                      maxLength={28}
                        {...register("addressLine1", { required: true })}
                        isInvalid={errors.addressLine1}
                        placeholder=""
                      />
                      {errors.addressLine1 && (
                        <Form.Control.Feedback type="invalid">
                          This field is required.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                      <Form.Group className="col-md-6">
                      <Form.Label>Street, Area, Sector, Village <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="text"
                      maxLength={28}
                        {...register("addressLine2", { required: true })}
                        isInvalid={errors.addressLine2}
                        placeholder=""
                      />
                      {errors.addressLine2 && (
                        <Form.Control.Feedback type="invalid">
                           This field is required.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                      <Form.Group className="col-md-6">
                      <Form.Label>City <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        {...register("townCity", { required: true })}
                        isInvalid={errors.townCity}
                        placeholder="City"
                        // style={{ pointerEvents: "none" }}
                        // className={isPincodeAvailable ? "disbledClass" : ""}
                        disabled={isPincodeAvailable}
                      />
                      {errors.townCity && (
                        <Form.Control.Feedback type="invalid">
                           City is required.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                       <Form.Group className="col-md-6">
                      <Form.Label>State <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        {...register("state", { required: true })}
                        isInvalid={errors.state}
                        placeholder="State"
                        // style={{ pointerEvents: "none" }}
                        // className={isPincodeAvailable ? "disbledClass" : ""}
                        disabled={isPincodeAvailable}
                      />
                      {errors.state && (
                        <Form.Control.Feedback type="invalid">
                          State is required.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                        <Form.Group className="col-md-6">
                      <Form.Label>Zip Code <span className="error-message">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={5}
                        minLength={5}
                        
                         {...register("postCode", {
                          required: true,
                          onChange: onPincodeChange,
                        })}
                        isInvalid={errors.postCode}
                        placeholder="Zip Code"
                      />
                      {errors.postCode && (
                        <Form.Control.Feedback type="invalid">
                          Please enter valid zipcode.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Country</Form.Label>
                       <Form.Select
                      className="form-control"
                      aria-label="Country Region"
                      name="country"
                    >
                    <option value="USA">USA</option>
                    </Form.Select>
                    </Form.Group>
                
                 

                  
                   

                  
                    <Form.Group className="col-md-12">
                      <Form.Label>Landmark</Form.Label>
                      <Form.Control
                         maxLength={28}
                        type="text"
                        {...register("landmark")}
                        isInvalid={errors.landmark}
                        placeholder=""
                      />
                    </Form.Group>
                  </div>

                  <Button className="addressSubmitBtn" type="submit" disabled={isLoading}>
                    {addressByID ? "Update Address" : "Add Address"}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddressModal;
