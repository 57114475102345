// import React from 'react'
// import { render } from 'react-dom'
// import App from './App'

// render(<App />, document.getElementById('root'))


import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
// import { createRoot } from 'react-dom/client';
// import 'normalize.css';

// import { store } from './store';
// import { Provider } from 'react-redux';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <App tab='home' />   
   </React.StrictMode>
);

