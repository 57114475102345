import { Link, useLocation } from 'react-router-dom'
import React, { useContext, useEffect, useState } from "react";
// import arrow from "../../asset/arrow.svg"
import "./BreadCrumbs.css";

function BreadCrumbs() {
  const location = useLocation();


  return (
    <div className='breadcrumbBg'>
    <div className='container'>
    <nav className='customBreadcrumb'>
      <Link to="/dashboard"
        className={location.pathname === "/dashboard" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
        Home
      </Link>
      {location.pathname.startsWith("/wishlist") && <>
      <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/wishlist"
        className={location.pathname.startsWith("/wishlist") ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
        Wishlist
        </Link></>}
       {location.pathname.startsWith("/profile") && <>
             <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/profile"
        className={location.pathname === "/profile" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Profile
          </Link>
        </>}
        {location.pathname.startsWith("/orders") && <>
            <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/orders"
        className={location.pathname === "/orders" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       orders
            </Link></>}
            {location.pathname.startsWith("/sold-items") && <>
            <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/sold-items"
        className={location.pathname === "/sold-items" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Sold Items
        </Link></>}
       {location.pathname.startsWith("/vault") && <>
         <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/vault"
        className={location.pathname === "/vault" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       vault
        </Link></>}
       {location.pathname.startsWith("/contact-us") && <>
         <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/contact-us"
        className={location.pathname === "/contact-us" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Contact Us
          </Link></>}
          {location.pathname.startsWith("/cart") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/cart"
        className={location.pathname === "/cart" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Cart
            </Link></>}
            {location.pathname.startsWith("/checkout") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/checkout"
        className={location.pathname === "/checkout" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Checkout
          </Link></>}
        {location.pathname.startsWith("/faqs") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/faqs"
        className={location.pathname === "/faqs" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Frequently Asked Questions
          </Link></>}
         {location.pathname.startsWith("/about-us") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/about-us"
        className={location.pathname === "/about-us" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       About Us
          </Link></>}
      {location.pathname.startsWith("/team") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/team"
        className={location.pathname === "/team" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Team
      </Link></>}
       {location.pathname.startsWith("/address") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/address"
        className={location.pathname === "/address" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Address
      </Link></>}
        {location.pathname.startsWith("/list-card") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/list-card"
        className={location.pathname === "/list-card" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       List Physical Card
      </Link></>}
       {location.pathname.startsWith("/shipment-details") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/shipment-details"
        className={location.pathname === "/shipment-details" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Shipment Detail Page
      </Link></>}
      {location.pathname.startsWith("/earnings") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/earnings"
        className={location.pathname === "/earnings" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Earnings
      </Link></>}
      {location.pathname.startsWith("/privacy-policy") && <>
        <span className="breadcrumb-arrow">{'>'}</span>
      <Link to="/privacy-policy"
        className={location.pathname === "/privacy-policy" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Privacy Policy
      </Link></>}
      </nav>
      </div>
      </div>
  );
}

export default BreadCrumbs;