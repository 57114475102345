import React, {useContext} from "react";
import styles from "./CategoryTab.module.css"
import { Nav, Dropdown} from "react-bootstrap";
import { CategoryContext } from '../../../Context/CategoryContext';


const CardCategoryTab = ({ tabs, tabsClickHandler}) => {
  const { catSelectedTab } = useContext(CategoryContext);

  return (
    <>
    <Nav
    variant="pills"
    className="d-sm-none d-none d-md-flex d-ld-flex justify-content-center mt-3 mb-3 text-uppercase "
    // activeKey={activeTab}
    // onClick={tabsClickHandler}
  >
       {tabs.map((value, index) =>{
          
           const {categoryId, name} = value
            return(
            <Nav.Item key={index.toString()}>
              <Nav.Link 
                key={index} 
                 
                onClick={() => {
                  tabsClickHandler(categoryId,name);
                }}
                
                className={value.categoryId === `${catSelectedTab.id}` 
                ? `${styles.activeTabs} ${styles.hoverTab}` 
                : `${styles.hoverTab}`}
                
                >{value.name==="all-cards" ? "All Cards" : value.name }</Nav.Link>
            </Nav.Item>
          )})} 
    </Nav>

    {/* Dropdown for mobile screens */}
    <Dropdown className="d-grid d-md-none mt-3 d-lg-none text-uppercase w-100">
          <Dropdown.Toggle variant="success" className = {`${styles.activeTabs} ${styles.hoverTabs}  text-uppercase d-flex align-items-center justify-content-between`}>
          {(catSelectedTab.name === "all-cards" ? "All Cards" : catSelectedTab.name) || 'All Cards'}
          
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {tabs.map((value, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => {
                  tabsClickHandler(value.categoryId, value.name);
                }}
              >
                {/* {value.name} */}
                {value.name==="all-cards" ? "All Cards" : value.name }
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
    </>
  );
};

export default CardCategoryTab;

