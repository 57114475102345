import React, { useState, useEffect } from "react";
import styles from "./Card.module.css";
import ButtonIcon from "../../ButtonWithIcon/buttonIcon";
import tree_fire from "../../../asset/tree_fire.png";
import two_fire from "../../../asset/two_fire.png";
import one_fire from "../../../asset/one_fire.png";
import { ImHistory } from "react-icons/im";
import timer from "../../../asset/timer.svg";
import removeCard from "../../../asset/removeCard.svg";
import removeRedCard from "../../../asset/removeRedCard.svg";
import AllBidModal from "../../../Containers/Vault/AllBidModal";
import BidModal from "../../../Containers/CardDetails/BidModal";
import { getTimeRemaining, usaPriceformat } from "../../../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { ConfirmBox } from "../../ConfirmBox/ConfirmBox";
import SkeletonImg from '../../../components/SkeletonImage/SkeletonImg'

const Index = ({
  imgUrl,
  title,
  description,
  bidTimer,
  topBids,
  price,
  approvalStatus,
  qty,
  marketQty,
  auctionQty,
  isTypeBid = false,
  isMyVault = false,
  isAuction = false,
  marketPlacebtn = false,
  auctionBtn = false,
  handleClick,
  openHistory,
  handleClickAuction,
  listingId,
  currentBid,
  cardInfo,
  bidStatus,
  bidId,
  handleTimerExpiration,
  auctionstatus,
  cardType,
  paymentTimer,
  cardDetailType
}) => {
  const [timeRemaining, setTimeRemaining] = useState(
    getTimeRemaining(bidTimer)
  );
  const [paymentTimeRemaining, setPaymentTimeRemaining] = useState(
    getTimeRemaining(paymentTimer)
  );
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);

   const [confirmBox, setConfirmBox] = useState(false);
  const confirmToggle = () => setConfirmBox(!confirmBox);
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
   const [imageLoaded, setImageLoaded] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(bidTimer));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [bidTimer]);
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setPaymentTimeRemaining(getTimeRemaining(paymentTimer));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [paymentTimer]);

  const onClickHandler = (listingId) => {
    listingId && navigate(`/card-details/${listingId}`);
  };
  useEffect(() => {
    if (timeRemaining === "0:00:00:00" && bidStatus !== "lost" && isTypeBid) {
       handleTimerExpiration()
    } else if (timeRemaining === "0:00:00:00" && auctionstatus != "suspended" && isAuction) {
      handleTimerExpiration()
    }
  }, [timeRemaining]);
  useEffect(() => {
     if (paymentTimer !== null && paymentTimeRemaining === "0:00:00:00" && bidStatus !== "lost" && isTypeBid) {
       handleTimerExpiration()
    } else if (paymentTimer !== null && paymentTimeRemaining === "0:00:00:00" && auctionstatus != "suspended" && isAuction) {
      handleTimerExpiration()
    }
    
  }, [paymentTimeRemaining]);
  // useEffect(() => {
  //  setImageLoaded(false)
  //    const img = new Image();
  //   img.onload = () => {
  //     setImageLoaded(true);
  //   };
  //   img.src = imgUrl;
  // },[imgUrl])
  return (
    <div className="d-flex align-items-start my-3 vaulttabs">
      <div  onClick={() => onClickHandler(listingId)} className={`${!isMyVault && 'cursor-pointer'}`}>
       {/* <SkeletonImg className={`skeleton-img ${imageLoaded ? 'hidden' : ''}`}    isVisible={!imageLoaded} /> */}
        {/* {imageLoaded && */}
          <img
        src={imgUrl}
        alt={imgUrl}
       
        className={`${(isAuction || isTypeBid) && styles.Auncard_img} ${styles.card_img
          }`}
        />
      {/* } */}
</div>
      {/* <ImHistory className={styles.card_img} /> */}
      <div className="mx-md-3 w-100" >
       {(isTypeBid || ((isAuction && approvalStatus =="approved") || cardType === 'digital')) && (
          <div className="d-flex align-items-center">
            <img src={timer} style={{ width: "20px" }} alt="timer-icon"></img>
            <span className={styles.timerText}>
              {" "}
              {timeRemaining !== "0:00:00:00" ? (
                <span
                  className={styles.timerText}
                >{`${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`}</span>
              ) : (
                <span className={styles.timerText}>{timeRemaining}</span>
              )}
            </span>
          </div>
        )}
        <div className={styles.card_Title}>
          {title}{" "}
          {(marketPlacebtn ||
            (auctionBtn && timeRemaining !== "0:00:00:00")) && (
            <>
              {/* <img src={removeCard} onClick={handleClick} title={"Remove"} /> */}
              <ConfirmBox show={confirmBox}
                toggle={confirmToggle}
                handleClick={() => handleClick()}
                cardContent={`Are you sure you want to remove this card from ${marketPlacebtn ? "Marketplace?" : "Auction?"}`}

              />
              <img
                src={removeCard}
                alt="removeBtn"
                // onClick={handleClick}
                onClick={()=>setConfirmBox(!confirmBox)}
                className="btn-img cursor-pointer"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                style={{ display: isHovered === true ? "none" : "block" }}
              />
              <img
                src={removeRedCard}
                alt="removeBtn"
                // onClick={handleClick}
                onClick={()=>setConfirmBox(!confirmBox)}
                className="btn-img cursor-pointer"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                style={{ display: isHovered === false ? "none" : "block" }}
              />
            </>
          )}
          {isMyVault && (
            <ImHistory onClick={openHistory} className={styles.historyIcon} />
          )}
        </div>

     <div className={styles.card_description}>
  {description.split(" ").length > 10 ? (
    <>
      {description.split(" ").slice(0, 10).join(" ")}...
    </>
  ) : (
    description
  )}
</div>

        <div>
          <div>
            {isTypeBid && (
              <span className={`${styles.isTypeBid} Bidtxt`}>My Bid</span>
            )}
            {marketPlacebtn && <span className={`${styles.isTypeBid} Bidtxt`}>Price</span>} {price && (
              <span className={`${styles.price} minBidPrice`}>
                {" "}
                {usaPriceformat(price)}
              </span>
            )}
            {(marketPlacebtn || auctionBtn ) && approvalStatus !=="approved" && 
                <div className="approvalsBtn">
                   <span className={`status ${approvalStatus?.replace(' ', '-').toLowerCase()}`}>
                      {approvalStatus}
                    </span>
                    </div> 
            }
          </div>
          {isTypeBid && (
            <>
              <div>
                <span className={`${styles.isTypeBid} Bidtxt`}>
                  {bidStatus == "won" ? "Highest Bid " : "Current Bid "}
                </span>
                <span className={`${styles.price} curreBidPrice`}>
                  {" "}
                  {usaPriceformat(currentBid)}
                </span>
              </div>

              {bidStatus == "active" && (
                <>
                  {" "}
                  <ButtonIcon
                    iconPath=""
                    text="Revise Bid"
                    clickHandler={() => {
                      setShow(!show);
                    }}
                    className="my-2"
                  />
                  <BidModal
                    show={show}
                    toggle={toggle}
                    type="revise"
                    cardDetail={cardInfo}
                    listingId={listingId}
                  />
                </>
              )}
              {bidStatus == "lost" && (
                <p className={styles.bidStatusTxt}>Sorry! You lost the Bid</p>
              )}
              {bidStatus == "won" && (
                <p className={styles.bidStatusTxt}>
                  Congratulations! You won the Bid
                </p>
              )}
              {bidStatus == "suspended" && (
                <p className={styles.bidStatusTxt}>
                  Auction has suspended due to reserve limit
                </p>
              )}
              {(paymentTimeRemaining !== "0:00:00:00" && bidStatus != "won") && (
                <>
                  <span className="Bidtxt">Payment Timer </span>
                  <img
                    src={timer}
                    style={{ width: "15px" }}
                    alt="timer-icon"
                  ></img>
                  <span className={styles.timerText}>
                    {" "}
                    {paymentTimeRemaining !== "0:00:00:00" ? (
                      <span
                        className={styles.timerText}
                      >{`${paymentTimeRemaining.days}d ${paymentTimeRemaining.hours}h ${paymentTimeRemaining.minutes}m ${paymentTimeRemaining.seconds}s`}</span>
                    ) : (
                      <span className={styles.timerText}>
                        {paymentTimeRemaining}
                      </span>
                    )}
                  </span>
                </>
              )}
              {(bidStatus == "payment_pending" && paymentTimeRemaining !== "0:00:00:00") && (
                <ButtonIcon
                  iconPath=""
                  text="Make Payment"
                  clickHandler={() =>
                    navigate("/checkout", {
                      state: {
                        currentStep: 0,
                        showCartStep: true,
                        bidId: bidId,
                        listingId: listingId,
                        cardTypeforPay: 'auction',
                        cardDetailType:cardDetailType
                      },
                    })
                  }
                  className="my-2"
                />
              )}
            </>
          )}

          {isMyVault && (
            <>
              <span className={styles.isMyVault}>
                Qty{" "}
                <em style={{ fontStyle: "normal", color: "#333E56" }}>{qty}</em>
              </span>
              {marketQty !== undefined && (
                <span className={styles.isMarketPlace}>
                  {marketQty} Marketplace
                </span>
              )}
              {auctionQty !== undefined && (
                <span className={styles.isAuction}>{auctionQty} Auction</span>
              )}
            </>
          )}
        </div>

        {isMyVault && (
          <>
            <div className="mt-3 d-flex align-items-center">
              <ButtonIcon
                iconPath=""
                text="Add to Marketplace"
                clickHandler={handleClick}
                className={styles.vaultBtn}
                isButtonDisable={qty === marketQty + auctionQty ? true : false}
              />
              <ButtonIcon
                iconPath=""
                text="Add to Auction"
                clickHandler={handleClickAuction}
                className={`${styles.button} ${styles.vaultBtn}`}
                isButtonDisable={qty === marketQty + auctionQty ? true : false}
              />
            </div>
          </>
        )}
        {isAuction && (
          <>
           {(auctionstatus && approvalStatus === "approved") || cardType === 'digital' ? (
      <div className={styles.auctionStatus}>
        <b>Auction Status:</b>{" "}
        <span>
          {auctionstatus === "auction_completed"
            ? "auction completed"
            : auctionstatus}
        </span>
      </div>
    ) : null}

            {topBids?.length > 0 && (
              <div className={styles.isAuctionCardContainer}>
                <div className={styles.top_container}>Top 3 Bids</div>

                <div className={styles.priceContainer}>
                  {topBids?.length > 0 && topBids?.[0] && (
                    <div className={styles.fire_cont}>
                      <div className={styles.fire_Img}>
                        <img
                          src={tree_fire}
                          alt="service"
                          className={styles.serviceImg}
                        />
                      </div>
                      <div
                        className={styles.price}
                      >{`${usaPriceformat(topBids[0]?.price)}`}</div>
                    </div>
                  )}
                  {topBids?.length > 0 && topBids?.[1] && (
                    <div className={styles.fire_cont}>
                      <div className={styles.fire_Img}>
                        <img
                          src={two_fire}
                          alt="service"
                          className={styles.serviceImg}
                        />
                      </div>
                      <div
                        className={styles.price}
                      >{`${usaPriceformat(topBids[1]?.price)}`}</div>
                    </div>
                  )}
                  {topBids?.length > 0 && topBids?.[2] && (
                    <div className={styles.fire_cont}>
                      <div className={styles.fire_Img}>
                        <img
                          src={one_fire}
                          alt="service"
                          className={styles.serviceImg}
                        />
                      </div>
                      <div
                        className={styles.price}
                      >{`${usaPriceformat(topBids[2]?.price)}`}</div>
                    </div>
                  )}
                </div>
                {topBids?.length > 0 && (
                  <ButtonIcon
                    iconPath=""
                    text="Show all Bids"
                    clickHandler={() => {
                      setShow(!show);
                    }}
                    className={styles.bidsBtn}
                  />
                )}
              </div>
            )}
           {show && <AllBidModal
              listingId={listingId}
              show={show}
              toggle={toggle}
              title={title}
              imgUrl={imgUrl}
              description={description}
            /> }
          </>
        )}
        {/* {(marketPlacebtn) && (
          <>
            <div className="mt-3 d-flex align-items-center">
              <ButtonIcon
                iconPath=""
                text="Remove"
                clickHandler={handleClick}
                className={`${styles.button}`}
              />
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default Index;
