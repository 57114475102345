import React, { useEffect } from 'react'
import BreadCrumbs from '../BreadCrumb/BreadCrumbs';
import "./Team.css";
import Navbar from "../Navigation/Navbar";
import Footer from "../Footer/Index";
import image_1 from "../../asset/about-us/image_1.jpeg" 
import image_2 from "../../asset/about-us/image_2.png" 



const team = [{
    name: 'Chandler Kubas',
    position:'President & Founder',
    url:image_1
},{
    name: 'Aaron Meyer',
    position:'Vice President',
    url:image_2
    },
]

const data = [
    {
        title:'Company Vision',
        description:'At Fantasy Cards, we envision a world where the thrill of collecting and trading digital cards knows no bounds. Our mission is to create an immersive and dynamic web application that brings collectors, enthusiasts, and traders together in a seamless, thrilling, and secure environment.  We strive to be the go-to destination for anyone passionate about collecting cards, whether they are digital assets or traditional trading cards.',
        bullet_points:[]
    }, {
        title:'Our Purpose',
        description:'Our purpose is to ignite the imagination of collectors and traders worldwide, revolutionizing the way they interact with their favorite cards in a digital trading environment. Fantasy Cards seeks to:',
        bullet_points:[
            "Connect Communities: We aim to connect collectors from every corner of the globe, fostering a vibrant community where friendships are formed, knowledge is shared, and experiences are cherished.", 
            "Unleash Creativity: Fantasy Cards is a platform where the creative spirit thrives. We provide tools and features that empower users to personalize and showcase their card collections like never before.",
            "Elevate Trading: Trading becomes an art form at Fantasy Cards. We are committed to developing cutting-edge trading features that offer an authentic, real-world trading experience in the digital realm.",
            "Security and Trust: Trust is at the core of what we do. We prioritize the security of every transaction, ensuring that every user feels confident in trading and collecting within our platform."
        ]
    },{
        title:'Our Commitment to Innovation',
        description:'Innovation is the heartbeat of Fantasy Cards. We are dedicated to:',
        bullet_points:[
            "Constant Evolution: We will continually evolve our web application, introducing new features and technologies that push the boundaries of what's possible in the world of online card collecting.",
            "User-Centric Design: Our development is guided by user feedback and needs, making Fantasy Cards an application that truly belongs to its community.", 
            "Blockchain Integration: We recognize the potential of blockchain technology in securing and authenticating digital collectibles. We are committed to exploring and implementing blockchain solutions for enhanced card ownership and provenance."
        ]
    },{
        title:'Our Values',
        description:'',
        bullet_points:["Passion: We are fueled by a deep passion for trading card collectibles and the joy they bring to people's lives",
        "Integrity: We uphold the highest standards of integrity, ensuring that every user can trust Fantasy Cards with their collections.",
        "Inclusivity: We celebrate diversity and believe that everyone, regardless of background or expertise, should have the opportunity to engage in the world of digital collectibles.",
        "Innovation: We embrace innovation as a means to enrich the user experience and drive the evolution of our platform.", 
        "Community: We cherish the sense of belonging and camaraderie within our community and actively foster connections among users."]
    },{
        title:'Join Us on this Journey',
        description:"Fantasy Cards is not just an application; it's a gateway to a world of endless possibilities in the realm of online card collecting. We invite you to join us on this thrilling journey as we strive to empower collectors, elevate trading, and celebrate the art of card collecting on a digital platform.",
        bullet_points:[]
    }


]

const firstThree = data.slice(0, 3);
const remainingData = data.slice(3);

export default function Team() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Team Page',
      });
     },[])
       
    return (
        <>
        {/* <Navbar /> */}
        <BreadCrumbs/>
            <div >
                <section className='container mb-5 mt-4'>
                    <h2 className='my-4'>Team Work</h2>
                        {firstThree.map((item, index)=>{
                            const {title, description, bullet_points} = item
                            return(
                                <div key={index}>
                                    <h5 className='abouttitle'>{title}</h5>
                                    <p className='aboutdesc'>{description}</p>
                                    {bullet_points.length > 0 && (
                                    <ul>
                                        {bullet_points.map((elem, elemIndex) => (
                                            <li className='aboutdesc' key={elemIndex}>
                                                {elem.split(':').map((text, subIndex) => (
                                                <span key={subIndex}>
                                                    {subIndex === 0 ? <strong>{text}</strong> : `: ${text}`}
                                                </span>
                                                ))}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                </div>
                            )
                        })}
                </section>
                <section className='profile-section-main'>
                    <div className='container profile-section'>
                    <h2>Our Creative Team</h2>
                    <div className='profile-cards'>
                    {team?.map((item, index)=>{
                        const {name, position, url} = item
                        return(
                            <div className="card" key={index}>
                            <img src={url} alt={name} />
                            <h3 className='name'>{name}</h3>
                            <p className="title">{position}</p>
                            </div>
                        )})
                    }
                    </div>
                    </div>
                </section>
                <section className='container my-5'>
                {remainingData.map((item, index)=>{
                            const {title, description, bullet_points} = item
                            return(
                                <div key={index}>
                                    <h4 className='abouttitle'>{title}</h4>
                                    <p className='aboutdesc'>{description}</p>
                                    {bullet_points.length > 0 && (
                                    <ul>
                                        {bullet_points.map((elem, elemIndex) => (
                                            <li className='aboutdesc' key={elemIndex}>
                                                {elem.split(':').map((text, subIndex) => (
                                                <span key={subIndex}>
                                                    {subIndex === 0 ? <strong>{text}</strong> : `: ${text}`}
                                                </span>
                                            ))}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                </div>
                            )
                        })}
                        <p className='aboutdesc'>Together, let's build a future where Fantasy Cards is synonymous with innovation, community, and boundless excitement for collectors worldwide.</p>
                </section>       
            </div>
        {/* <Footer /> */}
               </>
     )
}
