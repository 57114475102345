import React, { Fragment, useEffect } from 'react'
import BreadCrumbs from '../../Containers/BreadCrumb/BreadCrumbs';
import Faq from "react-faq-component";
import "./faqs.css";
import Navbar from "../Navigation/Navbar";
import Footer from "../Footer/Index";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function Faqs() {
     const navigate = useNavigate()
    const data = {
    rows: [
        {
            title: "What is Fantasy Cards?",
            content: `Fantasy Cards is a digital platform that allows users to buy, sell, and trade digital cards from various categories such as Pokemon, Soccer, Fortnite, Cricket, Hockey, Baseball, Basketball and Golf.`,
        },
            {
                title: "How do I get started on Fantasy Cards?",
            content: (
    <>
        To get started, <Link to="/signup">simply sign up for an account on our platform</Link>. Once registered, you can explore the available digital cards, make purchases,
        and even list your own cards for sale. You do not need to register in order to peruse our selection!
    </>
)
    },
        {
            title: "What types of digital cards are available on Fantasy Cards?",
            content: `Fantasy Cards offers a diverse range of digital cards spanning categories like Pokemon, Soccer, Fortnite, Cricket, Hockey, Baseball, Basketball and Golf, providing a wide variety for collectors and enthusiasts.`,
        },
        {
            title: "How do I purchase digital cards on Fantasy Cards?",
            content: `To purchase digital cards, browse through the available categories, select the cards you want, and proceed to checkout. Follow the payment instructions to complete your purchase securely.`,
            },
         {
            title: "Can I sell my digital cards on Fantasy Cards?",
            content:
                (
                    <>
                        Absolutely! You may list your digital cards which you have purchased from the Fantasy Cards platform for sale on our Marketplace or Auction.Simply go to the <span onClick={() => navigate('/vault', { state: { currActive:3 } })} ><Link>My Auction</Link></span> section of your profile, Click on Add to Marketplace or Add to Auction button, enter a price, and click submit.Your cards then will be visible to potential buyers on the Fantasy Cards dashboard.
                    </>
                 )
        },
        {
            title: "Is there a fee for selling cards on Fantasy Cards?",
            content: `Yes, Fantasy Cards charges a 9.99% fee on the final sale price when you successfully sell a card on our platform.`,
            },
         {
            title: "How do card auctions work on Fantasy Cards?",
            content:
                "Fantasy Cards allows users to list their cards for auction where other users can then place bids on these cards. The highest bidder at the end of the auction wins the card.",
        },
        {
            title: "Can I participate in card auctions as a buyer?",
            content: `Absolutely! You can participate in card auctions by placing bids on cards you're interested in. Keep an eye on the auction status to see if you're the highest bidder. Furthermore, you will receive an email notification if you succeed in winning an auction.`,
            },
         {
            title: "What happens after winning a card auction?",
             content: (
                <>
                After winning a card auction, the winner will receive an email confirmation requesting for them to make the payment.The winner will have 24 hours to make the payment for the card that has been won.Upon payment confirmation, the card will be moved to winner’s <Link to="/vault">My Vault</Link> section where winner can choose to list it on the marketplace or auction it again.If the winner does not respond within the 24 hour period, the card will be awarded to the 2nd highest bidder, who will then be notified regarding making a payment.In the case that the 2nd highest bidder does not respond, the auction will be then marked as cancelled.
                 </>
             )
        },
          {
            title: `What is the "My Vault" section?`,
              content: (
                <>
                      The <Link to="/vault">My Vault</Link> section is where all your purchased and won digital cards are stored. From here, you can manage your collection, list cards for sale, or put them up for auction.
                  </>
              )
            },
           {
            title: "Can I list cards from My Vault on the marketplace or for auction?",
               content: (
                   <>
                   Yes, you can easily list cards from your vault on the marketplace or for auction directly from the <Link to="/vault">My Vault</Link> section.,
                   </>
               )
         },
            {
            title: "Can others see My Vault?",
            content: `No, your Vault is private and can only be viewed by you. It's a personal space for storing your cards.`,
        },
         {
            title: `What does the "Buy Now" option mean?`,
            content: `The "Buy Now" option allows you to purchase a digital card instantly at the listed price without going through an auction. It provides a quick and straightforward way to add cards to your collection.`,
        },
         {
            title: `How do I use the "Buy Now" feature?`,
            content: `Simply click on the "Buy Now" button next to the card you want to purchase, proceed to checkout, and complete the payment process to instantly secure the card.`,
        },
         {
            title: `What is the purpose of the "Add to Cart" feature?`,
            content: `The "Add to Cart" feature allows you to collect multiple cards in your cart before making a single transaction. It's convenient for users who want to purchase several cards in one go.`,
        },
         {
            title: "Can I edit the contents of my cart before making a purchase?",
            content: `Yes, you can easily edit your cart before completing the purchase. You can add or remove items, adjust quantities, and review your selections before proceeding to checkout.`,
        },
         {
            title: "How does the Wishlist feature work on Fantasy Cards?",
            content: `The Wishlist feature allows you to save cards you're interested in but might not be ready to purchase immediately. It serves as a curated collection of cards you have your eye on.`,
        },
         {
            title: "Can others see my Wishlist?",
            content: `No, your Wishlist is private and can only be viewed by you. It's a personal space for you to keep track of cards you find interesting.`,
        },
         {
            title: "Where can I track the status of my orders on Fantasy Cards?",
             content: (
                    <>
                     You can view the status of your orders in the <Link to="/orders">My Orders</Link> section.Here, you'll find information about your purchases, including order status, tracking details (if applicable), and more.
                 </>
             )
        },
         {
            title: "How can I cancel or modify an order?",
            content: `On digital card purchases, once an order is placed, it cannot be modified or canceled. Please review your order carefully before completing the purchase.`,
        },
         {
            title: "How can I track the cards I've sold on Fantasy Cards?",
            content: `The "Sold Items" section provides a record of the cards you have successfully sold on the platform. You can use this section to keep track of your sales history.`,
        },
         {
            title: "What happens after a card is sold?",
             content: (
                    <>
                    After a card is sold, it will move from your <Link to ="/vault">My Vault</Link> section of your Profile to the <Link to ="/sold-items">Sold Items</Link> section.The transaction details, including the sale price, will be recorded for your reference.
                </>
                     )
            },
          {
            title: "How can I contact Fantasy Cards for assistance?",
              content: (
                  <>
                      You can reach out to our support team through the <Link to="/contact-us">contact us</Link> page.Simply fill out the contact form with your inquiry or concern, and we'll get back to you as soon as possible.
                  </>
              )
        },
         {
            title: "What kind of issues can I contact Fantasy Cards about?",
            content: `Feel free to contact us regarding any issues related to your account, purchases, listings, or general questions about using the Fantasy Cards platform.`,
            },
          {
            title: "What should I do if I encounter payment issues during checkout?",
              content: (
                <>
                      If you experience any payment issues, please double- check your payment information, ensure that your payment method is valid, and try again. If the problem persists, contact our support team through the <Link to="/contact-us">contact us</Link> page for assistance.
                  </>
              )
            },
           {
            title: "Are my payment details secure on Fantasy Cards?",
            content: `Yes, we take the security of your payment information seriously. Fantasy Cards employs industry-standard encryption and security measures to protect your sensitive data.`,
            },
            {
            title: "What payment methods are accepted on Fantasy Cards?",
            content: `We currently accept Credit Cards & Debit Cards. You can choose any of those as payment method during the checkout process.`,
            },
             {
            title: "How can I view the Order Summary for my purchases on Fantasy Cards?",
                 content: (
                    <>
                         You can view the Order Summary for your purchases in the <Link to="/orders">My Orders</Link> section of your profile.Click on the specific order to see a detailed breakdown of the total price, including tax details.
                     </>)
            },
              {
            title: "What information is included in the Order Summary?",
            content: `The Order Summary includes a detailed breakdown of your purchase, including the total price, taxes, and any applicable fees.`,
            },
                  {
            title: "Can I download or print my Order Summary for reference?",
            content: `Absolutely, when you visit your order history, you can simply click the "See Detail" button to view a transaction.  From there, you can either click the Email or Download icons located in the top right corner to download/print your order summary.`,
        },

    ],
    };
const styles = {
    bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    rowContentColor: 'grey',
    arrowColor: "#3A3EBA",
};


const config = {
    animate: true,
    arrowIcon: "",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "−",
};


   
      useEffect(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'FAQs Page',
      });
  },[])
    
    return (

        <>
             {/* <Navbar /> */}
          <BreadCrumbs/>
      <div className='container mt-4'>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
            </div>
               {/* <Footer /> */}
            </>
  )
}
