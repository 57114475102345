import React, {useEffect, useState, useCallback, useContext} from 'react'
import Navbar from '../Navigation/Navbar'
import Footer from "../Footer/Index";
import {Breadcrumb,Container,Row,Col} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import CategoryTab from '../../components/CustomTabBar/CategoryTab/CategoryTab';
import "./category.css"
import SortFilters from "../../components/Sort/Sort";
// import axios from "../../utils/axiosConfig";
import ProductList from "../../Containers/ProductList/ProductList";
import {BsGridFill,BsListUl} from 'react-icons/bs'
import DoubleRangePrice from '../../components/DoubleRangePriceSlider/DoubleRangePrice';
import Paginations from '../../components/Pagination/Paginations';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Dropdown from 'react-bootstrap/Dropdown';
import { CategoryContext } from "../../Context/CategoryContext";
import crossIcon from "../../asset/Frame.svg"
import ButtonDetail from '../../components/ButtonWithIcon/ButtonDetail';



const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default function Categories() {
  const { categoryId } = useParams() || {}; 
  const { state } = useLocation();
  const navigate =useNavigate()
  const fields = [{name:'Newest', id:'createdAt'},{name:'High to Low', id:'highToLow'},{name:'Low to High', id:'lowToHigh'}]
  const [isGrid, setIsGrid] = useState(true)
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState('')
  const [noProduct, setNoProduct] = useState(true)
  const {catTabs, setCatTabs, catSelectedTab, setCatSelectedTab, fetchCategory} = useContext(CategoryContext)
  const rarity = ['basic', 'rare', 'signature','other']
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [resetPriceFilters, setResetPriceFilters] = useState(false)
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  const [filterState, setFilterState] = useState({
    sort: {name:'', id:''},
    rarity: [],
    price: {min:0, max:1000000},
    type: 'marketplace'
  });
  useEffect(() => {
    if (state != null) {
      setFilterState({
        sort: {name:'', id:''},
        rarity: [],
        price: {min:0, max:1000000},
        type: state?.Type ? state?.Type : 'marketplace'
      })
    }
  }, [state])
  const clearAll = () => {
    setFilterState({
        sort: {name:'', id:''},
        rarity: [],
        price: {min:0, max:1000000},
        type:'marketplace'
    });

    setAppliedFilters([]);
    setResetPriceFilters(prevValue => !prevValue)
    // setIsLoading(false)
  navigate(`/category/${categoryId}`)
    // window.location.reload();
  };
  const toggleSortFilter = (name,id) =>{
    const filterStateCopy = { ...filterState };

  if (filterStateCopy.sort.id === id) {

    filterStateCopy.sort = { name: '', id: '' };
  } else {
    filterStateCopy.sort = { name, id };
  }

  setFilterState(filterStateCopy);
  setCurrentActivePage(1);
  }

  const togglePriceFilter = (min, max) => {
    const filterStateCopy = { ...filterState };
    setResetPriceFilters(prevValue => !prevValue)

    if (filterStateCopy.price.min === min && filterStateCopy.price.max === max) {
      filterStateCopy.price = { min: 0, max: 1000000 };
  
    } else {
      filterStateCopy.price = { min, max };

    }
    setFilterState(filterStateCopy);
    setCurrentActivePage(1);
  };

  const toggleFilter = (filters) => {

    if (appliedFilters.includes(filters)) {
      setAppliedFilters((prevFilters) =>
        prevFilters.filter((f) => f !== filters)
      );
      const filterStateCopy = { ...filterState };
  
     if (filterStateCopy.rarity.includes(filters)) {
        filterStateCopy.rarity = filterStateCopy.rarity.filter(
          (rarity) => rarity !== filters
        );
      }

      setFilterState(filterStateCopy);
    } else {
      setAppliedFilters((prevFilters) => [...prevFilters, filters]);
    }
  };

  // const toggleTypeFilter =(type) =>{
  //   const filterStateCopy = {...filterState}
  //   if (type === 'marketplace') { 
  //     filterStateCopy.type = 'marketplace';
  //   } else {
  //     filterStateCopy.type = 'marketplace'; // Select 'Auction'
  //   }
  
  //   setFilterState(filterStateCopy);
  //   setCurrentActivePage(1);
  // }
  
  const handleRangeChange = useCallback((priceObj) => {
    setFilterState((prevState) => ({
      ...prevState,
      price: {
        min: priceObj.min !== undefined ? priceObj.min : prevState.price.min,
        max: priceObj.max !== undefined ? priceObj.max : prevState.price.max,
      },
    }));
    setCurrentActivePage(1);
  }, []);

  const updateTotalPages = (totalPages) => {
    setTotalPages(totalPages);
  };

  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => (currentActivePage -= 1));
    }
  };

  const nextHandler = () => {
    if(currentActivePage<totalPages)
    setCurrentActivePage((currentActivePage) => (currentActivePage += 1));
  };

  const catTabHandler = (id,name) =>{
    navigate(`/category/${name}`)
    setCatSelectedTab({id,name})
    setCurrentActivePage(1)
  }

  const handleSortChange = (name,id) => {
    setFilterState({
      ...filterState,
      sort: {
        name: name,
        id: id
      }
    });
    setCurrentActivePage(1)
  };

  const handleRarityChange = (currentRarity) => {
    const filterStateCopy = { ...filterState };
     
    if (!filterStateCopy.rarity.includes(currentRarity)) {
      filterStateCopy.rarity = [...filterStateCopy.rarity, currentRarity];
    } else {
    filterStateCopy.rarity = filterStateCopy.rarity.filter((attribute) => attribute !== currentRarity);
    }
    setFilterState({ ...filterStateCopy });
    setCurrentActivePage(1);
    };

    const handleTypeChange = (type) => {
      setFilterState({
        ...filterState,
        type: type,
      });
      setCurrentActivePage(1);
    };

  const emptyProduct =(item)=>{
    if(item === true ){
      setNoProduct(true);
    } else {
      setNoProduct(false);
    }
  }

  const renderPagination = () => {
    if(noProduct) {
      return (
        <div className='paginations'>
          <Paginations
            currentActive={currentActivePage}
            totalPage={totalPages}
            PageHandler={PageHandler}
            previousHandler={previousHandler}
            nextHandler={nextHandler}
            disabledPrev={disabledPrev}
            disabledNext={disabledNext}
          />
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }
  
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage]);

  useEffect(() => {
   
    if (categoryId && catTabs.length > 0) {
      const matchingTab = catTabs.find((tab) => tab.name.toLowerCase() === categoryId.toLowerCase());

      if (matchingTab) {
        setCatSelectedTab({ id: matchingTab.categoryId, name: matchingTab.name });
      }
    }
  }, [categoryId, catTabs,currentActivePage, filterState]);

  useEffect(()=>{
      fetchCategory()
    },[])
 useEffect(() => {
   window.scrollTo({ top: 0, behavior: "smooth" });
     window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Category Page',
      });
  }, []);
  return (
    <>
    {/* <Navbar /> */}
    <Container fluid>
      <Row className="breadcrumbBg">
        <Col>
            <div className="container">
          <Breadcrumb
  className="Breadcrumb-container"
  aria-label="breadcrumb"
>
  <Breadcrumb.Item
    onClick={() => {
      navigate("/dashboard", { replace: true });
    }}
  >
    Home
  </Breadcrumb.Item>
 <span className="breadcrumb-arrow">{'>'}</span>
  <Breadcrumb.Item>{(catSelectedTab.name === "all-cards" ? "All Cards" : catSelectedTab.name)  || "All Cards"}</Breadcrumb.Item>
</Breadcrumb>

          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <CategoryTab tabs={catTabs} tabsClickHandler={catTabHandler} selectedTab={catSelectedTab}/>
        </Col>
      </Row>
      <Row className = "mainsection container">
        <Col className="filtersection" md={3}>
            <section><h1> {(catSelectedTab.name === "all-cards" ? "All Cards" : catSelectedTab.name) || 'All Cards'}</h1></section>
          <section className = "sidefilters">
            
            {/* price */}
            <div className = "priceslider">
              <h5 className='mb-4'>Price</h5>
              <DoubleRangePrice min={0} max={1000000} onChange={handleRangeChange} filterState={filterState} setFilterState={setFilterState} resetPriceFilters={resetPriceFilters} setResetPriceFilters={setResetPriceFilters}/>
            </div>
            {/* end of price */}

              {/* category */}
              <div className='filterMain'>
            <div className = "rarityfilter">
              <h5>Rarity</h5>
                {rarity.map((item, index) => (
                  <div key={index.toString()}>
                    <input value={item} type="checkbox" checked={filterState.rarity.includes(item)} onChange={() => {handleRarityChange(item); toggleFilter(item);}}/>
                    <span>{item.replace(/^./, item[0].toUpperCase())}</span>
                  </div>
                ))}
            </div>
            <div className='typeFilter'>
            <h5>Type</h5>
              <label>   
                <input type="radio" 
                        name="cardType" 
                        value="marketplace" 
                        checked={filterState.type === 'marketplace'}
                        onChange={() => handleTypeChange('marketplace')}
                        // defaultChecked 
                />{' '}<span>Marketplace</span>
              </label>
              <label>
                <input type="radio" 
                        name="cardType" 
                        value="auction" 
                        checked={filterState.type === 'auction'}
                        onChange={() => handleTypeChange('auction')}
                />{' '}<span>Auction</span>
              </label>
                </div>
                </div>
            <div>
              <ButtonDetail text='Clear All Filters' clickHandler={clearAll} className="btnStyle" />
            </div>
            {/* end of category */}
          </section>
        </Col>
        <Col className ="productlistsection" md={9}>
          <Row>
            <section className="filtersort">    
              
              <div className="appliedFilters">
                {appliedFilters.map((filters) => (
                    <div key={filters} className="appliedFilter">
                      <span>Rarity: <b>{filters.replace(/^./, filters[0].toUpperCase())}</b></span>
                      <span className="removeFilter"
                        onClick={() => {toggleFilter(filters);}}><img src={crossIcon} alt={`Remove rarity`} />
                      </span>
                    </div>
                  ))}
                  {filterState.type && 
                     <div className="appliedFilter typeFilter">
                     <span>Type: <b>{filterState.type.replace(/^./, filterState.type[0].toUpperCase())}</b></span>
                     {/* <span className="removeFilter"
                           onClick={() => {toggleTypeFilter(filterState.type);}}><img src={crossIcon} alt={`Remove sorting`} />
                       </span> */}
                     </div>
                  }
                  {filterState.sort.name !== '' &&
                  <div className="appliedFilter">
                  <span>Sorted by: <b>{filterState.sort.name}</b></span>
                  <span className="removeFilter"
                        onClick={() => {toggleSortFilter(filterState.sort.name, filterState.sort.id);}}><img src={crossIcon} alt={`Remove sorting`} />
                    </span>
                  </div>
                  }
                    {(filterState.price.min !== 0 || filterState.price.max!== 1000000) &&
                  <div className="appliedFilter">
                    <span>Price Range: <b>${filterState.price.min} - ${filterState.price.max}</b></span>
                  <span className="removeFilter"
                        onClick={() => {togglePriceFilter(filterState.price.min, filterState.price.max);}}><img src={crossIcon} alt={`Remove pricing`} />
                    </span>
                  </div>
                  }
                </div>

                <div className = "sort-grid">
                <span className='mx-2 sortBy'>Sort by</span>
                  <SortFilters fields ={fields} selected ={filterState.sort} onSortingChange={handleSortChange} /> 
                <span>
                  <BsListUl className={`${!isGrid ?"viewicon inactive" :"viewicon "}`} onClick={()=>setIsGrid(false)}/>
                </span>
                <span>
                  <BsGridFill className={`${isGrid ?"viewicon inactive" :"viewicon "}`} onClick={()=>setIsGrid(true)}/>
                </span>
                </div>
            </section> 
          </Row>
          <Row>
            <section>
              <ProductList isGrid ={isGrid} selectedFilters={filterState} setPages= {updateTotalPages} emptyProduct={emptyProduct} pages={currentActivePage} />
            </section>
            <section className = "paginationgrid">
                <div className="paginations">
                  {renderPagination()}
                </div>
                {/* <div className = pagedropdown}>
                  <DropdownButton
                    title="1"
                    id="dropdown-menu-align-right"
                    className=dropdownButton}
                  >
                  <Dropdown.Item eventKey="option-1">1</Dropdown.Item>
                  <Dropdown.Item eventKey="option-2">2</Dropdown.Item>
                  <Dropdown.Item eventKey="option-3">3</Dropdown.Item>
                  </DropdownButton>   
                </div> */}
            </section>
          </Row>
        </Col>
      </Row>
      </Container>
    {/* <Footer /> */}
    </>
  )
}
