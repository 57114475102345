import React, { useContext, useEffect, useState } from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import { BiSearch, BiHeart } from "react-icons/bi";
import { Row, Col,Form } from "react-bootstrap";
import styles from "./myorder.module.css";
import emptySold from "../../asset/emptySold.svg";
import Pagination from "../../components/Pagination/Paginations";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import ButtonDetail from "../../components/ButtonWithIcon/ButtonDetail";
import OrderHistory from "./OrderHistory";
import img4 from '../../asset/cart-page/img4.svg';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { commonFunction, usaPriceformat } from "../../utils/commonFunction";
import { RxCross2 } from "react-icons/rx";
import SearchNotFound from '../../asset/SearchNotFound.svg'

const SoldItems = () => {
  const navigate = useNavigate();

  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState(1);
  const [searchResult,setSearchResult] = useState(false)
  const [transactionData, setTransactionData] = useState([]);
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [searchCard, setSearchCard] = useState();
  const handleSearch = (e, condition) => {
  if (searchCard) {
    if (e?.key === "Enter" || e?.keyCode === 13 || condition) {
      setIsLoading(true); 
      setCurrentActivePage(1)
      setSearchCard(searchCard);
      fetchTransactionData(currentActivePage, sortCriteria, sortOrder, searchCard);
    } 
  }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchCard(value);
    if (value === '') {
      // setIsLoading(true); 
      setCurrentActivePage(1)
      fetchTransactionData(currentActivePage, sortCriteria, sortOrder, '');
    }
  }
  const removeText = () => {
     setSearchCard('')
     setIsLoading(true); 
     setCurrentActivePage(1)
     fetchTransactionData(currentActivePage, sortCriteria, sortOrder,'');
  }

  const dropLocation = () => {
      window.open("https://wwwtest.fedex.com/en-us/shipping/drop-off-package.html", "_blank");
  }

  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage(currentActivePage - 1);
    }
  };

  const nextHandler = () => {
    setCurrentActivePage(currentActivePage + 1);
  };

  const fetchTransactionData = (currentPage, sortCriteria, sortOrder, keyword = '') => {
    let url = `soldItems/?page=${currentPage}&sort=${sortCriteria}&order=${sortOrder}`;
    if (keyword) {
      url += `&keyword=${keyword}`;
    }
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTotalPages(response?.data?.data?.totalPages);
          setTransactionData(response?.data?.data?.transactions);
          setIsLoading(false);
          setSearchResult(false)
        }
      })
      .catch((error) => {
         setTransactionData([])
        setIsLoading(false);
        setSearchResult(true)
      });
  };

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  };

  const handleSort = (event) => {
    const selectedValue = event.target.value;
    const selectedOrder =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-order"
      );
    setSortCriteria(selectedValue);
    setSortOrder(parseInt(selectedOrder, 10));
  };

  useEffect(() => {
    fetchTransactionData(currentActivePage, sortCriteria, sortOrder, searchCard);
    setDisabledPrev(currentActivePage === 1);
    setDisabledNext(currentActivePage === totalPages);
  }, [currentActivePage, sortCriteria, sortOrder]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.dataLayer.push({
      event: "Pageview",
      pagePath: `${window.location.href}`,
      pageName: "Sold Items Page",
    });
  }, []);

  useEffect(() => {
    setDisabledNext(currentActivePage === totalPages);
  }, [currentActivePage, totalPages]);
  return (
    <ProfileContainer>
      <div className={styles.children_container}>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h3 className={styles.title}>Sold Items</h3>
          <div className="customSearch">
             <div
              className={`d-flex align-items-center searchInputGroup`}
            >
              <div className={`input-group`}>
                <Form.Control
                  className={`rounded-pill searchForm`}
                  type="search"
                  placeholder="Search for Card title"
                  maxLength={20}
                  value={searchCard ? searchCard: ''}
                  onChange={handleChange}
                  onKeyDown={handleSearch}
                />
                <div className="input-group-append">
                  <span
                    className={`input-group-text bg-transparent border-0 icon searchIcon`}
                  >
                       <BiSearch onClick={()=>handleSearch('',true)}/>
                  </span>

                </div>
                 {searchCard && <div className="input-group-append" onClick={()=>removeText()}>
                  <span
                    className={`input-group-text bg-transparent border-0 icon searchIcon CrossIcon`}
                  >
                 <RxCross2 />
                  </span>
                </div> }
              </div>
          </div>
          </div>
          <div className="d-flex justify-content-between align-items-center cursor-pointer dropSection">
            <span className="tooltiped">  
            <img src={img4} className="mx-2" alt="Tooltip Icon" title="Please Drop-off your parcel at your nearest FedEx Drop-off Center." />
            </span>
            <div onClick={()=>dropLocation()}>Dropoff Location</div>
             <img src="Images/droplocator.svg" className="mx-4" onClick={()=>dropLocation()} />
           </div>
          <div className="d-flex align-items-center sortingContainer">
            <span className="mx-2 sortBy">Sort by</span>
            <select
              className="sortingdropdown"
              onChange={(e) => handleSort(e)}
            >
              <option value="date" data-order="1">
                Newest to Oldest
              </option>
              <option value="date" data-order="-1">
                Oldest to Newest
              </option>
              <option value="price" data-order="1">
                Price - Low to High
              </option>
              <option value="price" data-order="-1">
                Price - High to Low
              </option>
            </select>
          </div>
        </div>
        <Row className={`${styles.headerRow} mt-4 respDev`}>
          <Col xs={4}>Order</Col>
          <Col xs={2}>Qty</Col>
          <Col xs={2}>Order ID</Col>
          <Col xs={2}>Date</Col>
          <Col xs={2}>Action</Col>
        </Row>

        {isLoading ? (
          <div className="mt-5">
            <Loader />
            </div>
        ) : transactionData?.length === 0 && !searchResult ? (
          <div className={styles.emptyVault}>
            <div className="d-flex align-items-center flex-column py-5 gap-3">
              <img src={emptySold} alt="Empty Sold" />
              <h2 className="emptyheading">No Selling History</h2>
              <p className="emptysubheading">
                Click the button to list your cards currently for sale!
              </p>
              <ButtonIcon
                text={`Marketplace`}
                className={styles.confirmBtn}
                clickHandler={() =>
                  navigate("/vault", { state: { currActive: 2 } })
                }
              />
            </div>
          </div>
        ) : searchResult ? (
                  <img src={SearchNotFound} alt='No Cards Available...' className='SearchNotFound SearchFailed' />
                ) :(
          transactionData?.length > 0 &&
          transactionData.map((val, idx) => {
            const {
              qty,
              createdAt,
              orderId,
              listingId,
              cardInfo,
              listingInfo,
              cardTransactionId,
              shipmentId
            } = val;
            return (
              <Row className={styles.card_Container} key={idx}>
                <Col lg={4} xs={6}>
                  <div className="d-flex align-items-center">
                    <div className="d-flex">
                      <img
                        src={
                          cardInfo?.image?.thumbnail || cardInfo?.image?.front
                        }
                        alt="card"
                        className={styles.card_img}
                        onClick={() => onClickHandler(listingId)}
                      />
                    </div>
                    <div>
                      <Col>
                        <b>{cardInfo?.title}</b>
                      </Col>
                      {listingInfo?.type === "marketplace" ? (
                        <Col style={{ color: "#9547cf", fontWeight: "bold" }}>
                          {listingInfo?.marketplace?.price
                            ? usaPriceformat(listingInfo?.marketplace?.price)
                            : "$0.00"}
                        </Col>
                      ) : (
                        <Col style={{ color: "#9547cf", fontWeight: "bold" }}>
                          
                          {listingInfo?.auction?.currentBid
                            ? usaPriceformat(listingInfo?.auction?.currentBid)
                            : "$0.00"}
                        </Col>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={2} xs={6}>
                  <Col className="hideDesk">Qty</Col>
                  {qty}
                </Col>
                <Col lg={2} xs={6}>
                  <Col className="hideDesk">Order ID</Col>
                  {orderId?.slice(orderId?.lastIndexOf("-") + 1).toUpperCase()}
                </Col>
                <Col lg={2} xs={6}>
                  <Col className="hideDesk">Date</Col>
                  {commonFunction(createdAt)}
                </Col>
              <Col lg={2} xs={6}>
              <Col className="hideDesk">Action</Col>
              {cardInfo?.type === "physical" && !shipmentId? (
                <ButtonIcon
                  iconPath=""
                  className={styles.seebtn}
                  text="Create Shipment"
                  clickHandler={() => {
                    navigate(`/shipment-details/${cardTransactionId}`);
                  }}
                />
              ) : cardInfo?.type === "physical" && shipmentId ? (
                <ButtonDetail
                  iconPath=""
                  className={`${styles.seebtn} ${styles.digitalBtn}`}
                  text="View Details"
                    clickHandler={() => {
                      navigate(`/shipment-details/${cardTransactionId}`, { state: { shipmentId: shipmentId } });
                  }}
                />
              ) :  (
                <ButtonDetail
                  iconPath=""
                  className={`${styles.seebtn} ${styles.digitalBtn}`}
                  text="View Details"
                  isButtonDisable= {cardInfo?.type === "digital"}
                />
              )}
            </Col>

              </Row>
            );
          })
        )}
        {orderDetail && (
          <OrderHistory show={show} toggle={toggle} orderDetails={orderDetail} />
        )}
        {transactionData?.length > 0 && (
          <div className="mt-4">
            <Pagination
              currentActive={currentActivePage}
              totalPage={totalPages}
              PageHandler={PageHandler}
              previousHandler={previousHandler}
              nextHandler={nextHandler}
              disabledPrev={disabledPrev}
              disabledNext={disabledNext}
            />
          </div>
        )}
      </div>
    </ProfileContainer>
  );
};

export default SoldItems;
