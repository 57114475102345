import React, { useContext, useEffect, useRef, useState } from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import styles from "./Profile.module.css";
import edit from "../../asset/edit.svg";
import trash from "../../asset/icons-trash.png";

import defaultLOGO from "../../asset/user-icon.png";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import axios from "axios";
import PasswordInput from "../../components/PasswordInput/Input";
import { ProfileContext } from "../../Context/ProfileContextProvider";
import { ConfirmBox } from "../../components/ConfirmBox/ConfirmBox";
import SellerModal from "../../components/SellerFlow/SellerModal";

const Profile = () => {
  const fileInputRef = useRef(null);
  const defaultUpdateProfileData = {
    firstName: "",
    lastName: "",
    image: "",
    email: "",
  };
  const { setUserImg, userImg, setUserName, profilInfo } =
    useContext(ProfileContext);
  const [isPasswordButtonLoading, setIsPasswordButtonLoading] = useState(true);
  const [isProfileUpdateButtonLoading, setIsProfileUpdateButtonLoading] =
    useState(true);
  const [confirmBox, setConfirmBox] = useState(false);
  const confirmToggle = () => setConfirmBox(!confirmBox);
  const [sellerBox, setSellerBox] = useState(false);
  const sellerToggle = () => {
    setSellerBox(!sellerBox);
  };
  const [imguRL, setImageUrl] = useState();
  const [imgPreview, setImgPreview] = useState(null);
  const [profileData, setProfileData] = useState({
    ...defaultUpdateProfileData,
  });
  const [password, setPassword] = useState({
    new_password: "",
    old_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const cancelHandler = () => {
    setProfileData({
      ...profileData,
      firstName: "",
      lastName: "",
      image: "",
    });
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const removeProfileImg = () => {
    if (!profileData?.image) {
      // toast.error("Please upload image first")
      return;
    }
    setConfirmBox(!confirmBox);
    // let Confirmtext = "Are you sure, you want to remove profile picture?";
    // if (window.confirm(Confirmtext) != true) {
    //   return;
    // }
    axios
      .delete(`/removeProfilePic`)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          toast.error("Failed to remove profile");
        }
      })
      .catch((error) => {
        toast.error("Failed to remove profile");
      });
  };
  const handleFileChange = (e) => {
    
    const selectedFile = e.target.files[0];
    const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"];
    if (!allowedFileTypes.includes(selectedFile?.type)) {
      toast.error("Invalid file format");
      return;
    }
    if (selectedFile) {
      setImgPreview(URL.createObjectURL(selectedFile));
      setImageUrl(selectedFile);
    }
  };

  useEffect(() => {
    const image = localStorage.getItem("image");
    setImgPreview(null);
    setUserImg(image);
  }, []);

  const updateProfileHandler = () => {
    setIsProfileUpdateButtonLoading(false);
    var bodyFormData = new FormData();

    bodyFormData.append("firstName", profileData["firstName"]);
    bodyFormData.append("lastName", profileData["lastName"]);
    imguRL && bodyFormData.append("image", imguRL);
    // for (const property in profileData) {
    // }

    const fetchName = () => {
      const url = "getProfile";
      let config = {
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            const firstName = response?.data.data.user.firstName;

            localStorage.setItem("userFirstName", firstName);
            //  localStorage.setItem("userImg",response?.data.data.user.image);
            setUserName(firstName);
            // setImgPreview(null)
            setImgPreview(response?.data.data.user.image);
            setUserImg(response?.data.data.user.image);
          }
        })
        .catch((error) => {});
    };

    let config = {
      method: "PATCH",
      url: `updateProfile`,
      data: bodyFormData,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success("profile Updated successfully");
          localStorage.setItem(
            "userFirstName",
            response?.data?.data?.user?.firstName
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
          fetchName();
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later"
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong try again later"
        );
      });

    setIsProfileUpdateButtonLoading(true);
  };

  const updateInputHandler = (event) => {
    const { name, value } = event.target;
    setProfileData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updatePasswordInputHandler = (event) => {
    const { name, value } = event.target;
    setPassword((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const updatePasswordHandler = () => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (password.new_password === password.old_password) {
      return toast.error(
        "New Password can not be same as Old Password Please used Different Password"
      );
    } else if (!re.test(password.new_password)) {
      return toast.error(
        "Please add min 8 letter, must be contain 1 uppercase, 1 lowercase 1 number and 1 special character."
      );
    }

    setIsPasswordButtonLoading(false);

    let config = {
      method: "post",
      url: `changePassword`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      data: JSON.stringify(password),
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success("Password successfully changed");
          setPassword({
            new_password: "",
            old_password: "",
          });
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later"
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong try again later"
        );
      });

    setIsPasswordButtonLoading(true);
  };

  useEffect(() => {
    let config = {
      method: "get",
      url: `getProfile`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200 && response?.data?.data?.user) {
          const data = response?.data?.data?.user;
          setProfileData({
            ...data,
            email: data?.email?.value,
          });
        }
      })
      .catch((error) => {});
  }, [sellerBox]);
  const removePreviewImage = () => {
    if (imgPreview) {
      setImgPreview(null);
      return;
    }
  };

  const sellerAccount = () => {
     setIsLoading(true);
    const url = "sellerAccount";
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          window.open(response?.data?.data?.link?.url, "_blank");
        }
      })
      .catch((error) => {})
       .finally(() => {
        // Reset loading state
        setIsLoading(false);
      });
  };
  // useEffect(() => {
  //   !profileData?.seller?.isOnboardingCompleted && sellerAccount();
  // }, []);

  return (
    <ProfileContainer>
      <div className={`${styles.children_container} formInput`}>
        <div className={styles.profileSection}>
          <h3 className={styles.title}>My Profile</h3>
          {/* <div className={styles.CurrentcrossBtn}>
            <ImCross />
        </div> */}
          <div className={styles.profile_picture}>
            {profileData?.image && !imgPreview ? (
              <img src={profileData?.image} />
            ) : (
              <img
                src={imgPreview || profileData?.image || userImg || defaultLOGO  }
              />
            )}

            <div className={styles.edit_container}>
              <input
                type="file"
                name="image"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {!imgPreview ? (
                <img
                  src={edit}
                  alt="edit"
                  onClick={handleImageClick}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img
                  src={trash}
                  alt="Remove"
                  onClick={() => removePreviewImage()}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
          </div>
          <ConfirmBox
            show={confirmBox}
            toggle={confirmToggle}
            handleClick={() => removeProfileImg()}
            cardContent={`Are you sure, you want to remove profile picture?`}
          />
          <div className={styles.updateProfileContainer}>
            <div
              className="d-flex align-items-center  flex-wrap mt-5"
              id="blocksection"
            >
              <div>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  maxLength={15}
                  name="firstName"
                  value={profileData.firstName}
                  onChange={(value) => updateInputHandler(value)}
                  className="shadow-none"
                />
              </div>
              <div className="mx-lg-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={profileData.lastName}
                  onChange={(value) => updateInputHandler(value)}
                  className="shadow-none"
                />
              </div>
              <div className="mx-lg-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="new-password"
                  placeholder="Enter Email Address"
                  name="email"
                  value={profileData.email}
                  onChange={(value) => updateInputHandler(value)}
                  className="shadow-none"
                  style={{
                    width: "350px",
                  }}
                  disabled
                />
              </div>
            </div>
            <div className="my-3 d-flex align-items-center flex-wrap">
              <ButtonIcon
                iconPath=""
                text="Update Profile"
                className="btnStyle"
                clickHandler={updateProfileHandler}
                isNotLoading={isProfileUpdateButtonLoading}
                isButtonDisable={!profileData.firstName}
              />

              <ButtonIcon
                iconPath=""
                text="Remove Profile Picture"
                clickHandler={() => {
                  setConfirmBox(!confirmBox);
                }}
                className={styles.button}
                isButtonDisable={!profileData?.image}
              />
            </div>
          </div>
        </div>
        <div className={styles.profileSectionborder}></div>
        <div className={styles.profileSection}>
          <h3 className={styles.title}>Change Password</h3>
          <div
            className="d-flex align-items-center flex-wrap mt-5"
            id="blocksection"
          >
            <div>
              <Form.Label>Old Password</Form.Label>
              <PasswordInput
                placeholder="Enter Old Password"
                name="old_password"
                value={password.old_password}
                autoComplete="new-password"
                onChange={(value) => updatePasswordInputHandler(value)}
                className="shadow-none"
                style={{
                  width: "250px",
                }}
              />
            </div>
            <div className="mx-lg-3">
              <Form.Label>New Password</Form.Label>
              <PasswordInput
                placeholder="Enter New Password"
                name="new_password"
                value={password.new_password}
                onChange={(value) => updatePasswordInputHandler(value)}
                className="shadow-none"
                style={{
                  width: "250px",
                }}
              />
            </div>
          </div>
          <div className="mt-3">
            <ButtonIcon
              iconPath=""
              text="Update"
              className="btnStyle"
              clickHandler={updatePasswordHandler}
              isNotLoading={isPasswordButtonLoading}
              isButtonDisable={
                !(password.new_password && password.old_password)
              }
            />
          </div>
        </div>
        <div className={styles.profileSectionborder}></div>
        <div className={styles.profileSection}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h3 className={styles.title}>Seller Account Details</h3>
            {profileData?.seller?.isOnboardingCompleted &&
              !profileData?.seller?.address && (
                <ButtonIcon
                  iconPath=""
                  text="Add Seller Address"
                  className="btnStyle"
                  clickHandler={() => {
                    setSellerBox(!sellerBox);
                  }}
                />
              )}
          </div>
          {!profileData?.seller?.isOnboardingCompleted && (
            <h6 className="sellerText">
              You need to create a seller account to list a product
            </h6>
          )}
          {profileData?.seller?.isOnboardingCompleted ? (
            <>
              <div className="sellerContainer">
                <Form.Label className="sellerText">
                  Your Account Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  readOnly
                  value={profileData?.seller?.sellerAccountId
                    ?.slice(
                      profileData?.seller?.sellerAccountId?.lastIndexOf("-") + 1
                    )
                    .toUpperCase()}
                  className="shadow-none"
                />
              </div>
              {profileData?.seller?.address &&<div className="addressConatiner sellerAddress mt-4">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h5>
                    {profileData?.seller?.address?.firstName}{" "}
                    {profileData?.seller?.address?.lastName}
                  </h5>
                  <div className="d-flex addressIcons">
                    <img
                      src="/Images/pencil-edit.svg"
                      alt="pencil-edit"
                      className=" cursor-pointer"
                      onClick={() => {
                        setSellerBox(!sellerBox);
                      }}
                    />
                  </div>
                </div>
                <div className="address">
                  <p>
                    {profileData?.seller?.address?.addressLine1}{" "}
                    {profileData?.seller?.address?.addressLine2}
                    {profileData?.seller?.address?.landmark
                      ? ", " + profileData?.seller?.address?.landmark
                      : ""}
                    , {profileData?.seller?.address?.postCode},{" "}
                    {profileData?.seller?.address?.townCity},{" "}
                    {profileData?.seller?.address?.state},{" "}
                    {profileData?.seller?.address?.country}{" "}
                  </p>
                  <p>
                    Phone Number : {profileData?.seller?.address?.phoneNumber}
                  </p>
                </div>
              </div>
          }
            </>
          ) : (
            <div className="mt-4">
              <ButtonIcon
                iconPath=""
                text={isLoading ? "Loading..." : "Create Seller Account"}
                className="btnStyle"
                clickHandler={() => {
                  sellerAccount()
                }}
              />
            </div>
          )}
          {sellerBox && (
            <SellerModal
              show={sellerBox}
              toggle={sellerToggle}
              addressAvailable={
                profileData?.seller?.address ? profileData?.seller?.address : ""
              }
            />
          )}
        </div>
      </div>
    </ProfileContainer>
  );
};

export default Profile;
