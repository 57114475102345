import React, { useEffect, useState } from "react";
import "./cardListing.css";
import CustomTabBar from "../../components/CustomTabBar/Index";
import Footer from "../Footer/Index";
import Navbar from "../Navigation/Navbar";
// import { useLocation, useNavigate } from "react-router-dom";

import BreadCrumbs from "../BreadCrumb/BreadCrumbs";
import { Container } from "react-bootstrap";

import MarketplaceCard from "../../components/ListPhysical/marketplaceCard";
import AuctionCard from "../../components/ListPhysical/auctionCard";
import axios from "axios";
import { createBrowserHistory as createHistory } from 'history';
import CreateSellerPage from "../../components/ListPhysical/CreateSellerPage";
import CreateSellerAddressPage from "../../components/ListPhysical/CreateSellerAddressPage";
import Loader from "../../components/Loader/Loader";

// import { toast } from "react-toastify";

export default function CardListing() {
  // const navigate = useNavigate();
  // const location = useLocation();
  const history = createHistory();

  const [selectedTab, setSelectedTab] = useState(1);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [images, setImages] = useState([]);
  const [listingFlag, setListingFlag] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const Tabs = [
    {
      name: "Marketplace",
      id: 1,
    },
    {
      name: "Public Auction",
      id: 2,
    },
  ];

  const tabsClickHandler = (v) => {
    if (unsavedChanges) {
      const confirmLeave = window.confirm("Changes you made may not be saved.");
      if (!confirmLeave) {
        return;
      }
    }
    setSelectedTab(v);
    setUnsavedChanges(false);
  };
  const listingCheck = () => {
    setIsLoading(true);
    axios({
      method: "get",
      url: `listingCheck`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setListingFlag(response?.data?.data)
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.dataLayer.push({
      event: "Pageview",
      pagePath: `${window.location.href}`,
      pageName: "List Card",
    });
    listingCheck()
  }, []);




  const handleInputChange = () => {
    setUnsavedChanges(true);
  };

  useEffect(() => {
    const handleReload = (event) => {
      const confirmationMessage = 'Changes you made may not be saved.';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    if (unsavedChanges) {
      window.addEventListener('beforeunload', handleReload);
    } else {
      window.removeEventListener('beforeunload', handleReload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleReload);
    };
  }, [unsavedChanges]);


  useEffect(() => {
    if (selectedTab && images?.length > 0) {
      axios({
        method: "post",
        url: `listing/cancel`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { images },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setImages([])
          }
        })
        .catch((error) => {
          // toast.error(error?.response?.data?.message);
        });
    }
  }, [selectedTab])

  useEffect(() => {

  }, [])
  return (
    <>
      {/* <Navbar /> */}
      <BreadCrumbs />
      {isLoading ? <Loader /> : <>
        {!listingFlag?.onboardingCompleted ?
          <CreateSellerPage />
        :
        !listingFlag?.addressCompleted &&
          <CreateSellerAddressPage />
        }
        {(listingFlag?.addressCompleted && listingFlag?.allowed && listingFlag?.onboardingCompleted) && <>
          <CustomTabBar
            tabs={Tabs}
            tabsClickHandler={tabsClickHandler}
            selectedTab={selectedTab}
          />

          <Container>
            {selectedTab === 1 && <MarketplaceCard handleInputChange={handleInputChange} unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} setImages={setImages} images={images} />}
            {selectedTab === 2 && <AuctionCard handleInputChange={handleInputChange} unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} setImages={setImages} images={images} />}
          </Container>
        </>}
      </>}
      {/* <Footer /> */}
    </>
  );
}
