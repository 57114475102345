import React, { useEffect } from 'react'
import BreadCrumbs from '../BreadCrumb/BreadCrumbs';
import Navbar from "../Navigation/Navbar";
import Footer from "../Footer/Index";


const data = [
    {
        title:'About Us',
        description:"Welcome to Fantasy Cards, your ultimate destination for card trading excitement! We're not just a web application; we're your ticket to a world of thrilling card exchanges, specializing in sports, Pokémon, and Fortnite cards. Whether you're 13, 35, or 72, if you share our passion for captivating cards, you've found your new home.",
        bullet_points:[]
    }, {
        title:'Why Choose Fantasy Cards?',
        description:"We understand that card collecting isn't just a hobby; it's a lifestyle. It's about the heart-pounding moment of opening a brand-new pack, the exhilaration of discovering a rare card, and the pure joy of trading with fellow enthusiasts. At Fantasy Cards, we're here to take your card collecting journey to the next level.",
        bullet_points:[
        ]
    },{
        title:'What Makes Us Exceptional?',
        description:'',
        bullet_points:[
            "A Treasure Trove of Choices: Whether you're delving into the world of sports, immersing yourself in the magical realm of Pokémon, or embracing the action-packed universe of Fortnite, we've got it all. Our extensive collection spans your favorite sports, beloved Pokémon characters, and iconic Fortnite gear.", 
            "Top-Tier Security: Your safety is our utmost priority. We've implemented stringent security measures to ensure a secure and enjoyable trading experience for everyone. With Fantasy Cards, you can trade with confidence.",
            "A Thriving Community: Join a vibrant community of kindred collectors who share your passion. Connect, chat, and trade with fellow enthusiasts from all corners of the globe.",
            "Innovative Features:  Our intuitive interface and innovative features make trading a breeze. Explore, trade, and proudly display your collection like never before.",
            "Thrilling Tournaments and Events: Prepare for heart-pounding tournaments and events where you can put your skills to the test, win exclusive cards, and climb the ranks on the Fantasy Cards leaderboard.",
        ]
    },{
        title:'Our Vision',
        description:"At Fantasy Cards, our vision is to fuel your card collecting and trading fervor. We aim to be your one-stop platform for all things related to cards, where you can nurture your collection, forge new friendships, and embark on unforgettable trading adventures.",
        bullet_points:[]
    },{
        title:'Join the Fantasy Cards Revolution',
        description:"So, are you ready to embark on an epic journey filled with cards, camaraderie, and countless unforgettable memories? Come join us at Fantasy Cards, where we're committed to turning your card collecting dreams into reality. Dive into the world of trading, connect with like-minded enthusiasts, and set forth on your journey to amass a noteworthy collection today!",
        bullet_points:[]
    }


]



export default function AboutUs() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
         window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'About Page',
      });
     },[])
       
    return (
        <>
        {/* <Navbar /> */}
        <BreadCrumbs/>
            <div >
                <section className='container mb-5 mt-4'>
                        {data.map((item, index)=>{
                            const {title, description, bullet_points} = item
                            return(
                                <div key={index}>
                                    <h5 className='abouttitle'>{title}</h5>
                                    <p className='aboutdesc'>{description}</p>
                                    {bullet_points.length > 0 && (
                                    <ol>
                                        {bullet_points.map((elem, elemIndex) => (
                                             <li className='aboutdesc' key={elemIndex}>
                                             {elem.split(':').map((text, subIndex) => (
                                             <span key={subIndex}>
                                                 {subIndex === 0 ? <strong>{text}</strong> : `: ${text}`}
                                             </span>
                                             ))}
                                         </li>
                                        ))}
                                    </ol>
                                )}
                                </div>
                            )
                        })}
                                                        <b>Unleash the collector in you at Fantasy Cards!</b>

                </section>
            </div>
        {/* <Footer /> */}
               </>
     )
}
