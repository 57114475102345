import React from "react";
import { Button } from "antd";

// type options: ["primary", link] there were many other options as well.
export function BButton(props) {
  return (
    <Button
      type={props?.type}
      style={props.style}
      className={props?.className}
      disabled={props?.disabled}
      block={"true"}
      onClick={props?.onClick}
      icon={props?.icon}
    >
      {props?.children}
    </Button>
  );
}
