import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import previous_icon from '../../../asset/icon/previous.svg';
import next_icon from '../../../asset/icon/next.svg';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import './RelatedCarousel.css';
import { FreeMode, Pagination, Navigation } from 'swiper/modules';
import heartFillBtn from "../../../asset/heartFillBtn.svg";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import GuestLogin from '../../Login/GuestLogin';
import { usaPriceformat } from '../../../utils/commonFunction';

export default function RelatedCarousel({ Cards }) {
  const relatedCards = Cards;
  const [swiperRef, setSwiperRef] = useState(null);
  const [heartBtns, setHeartBtns] = useState(Array(relatedCards?.length).fill(false));
  const [guestLogin, setGuestLogin] = useState()
  const [showGuest, setShowGuest] = useState(false);
  const toggleGuest = () => setShowGuest(!showGuest);
  const navigate = useNavigate();

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
     window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

    const addRemoveToWishList = (index,listingId) => {
      
      if (guestLogin == "true" || guestLogin==null) {
        setShowGuest(!showGuest);
      } else {
      // return;
       const updatedWishlistStatus = [...heartBtns];
      updatedWishlistStatus[index] = !updatedWishlistStatus[index];
      setHeartBtns(updatedWishlistStatus)
      let data = JSON.stringify({
        listingId: listingId,
      });
      let config = {
        method: "post",
        url: `addRemoveWishlist`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);         
          }
        })
        .catch((error) => {
        });
    }
  }

     useEffect(() => {
    const gLogin = localStorage.getItem('guestLogin');
       setGuestLogin(gLogin);
    if ( relatedCards?.length > 0) {
    const updatedHeartBtns = relatedCards.map(card => card.isFavourite);
    setHeartBtns(updatedHeartBtns);
  }
  }, [relatedCards]);

  return (
    <div className="slider-container related-container">
      {relatedCards?.length > 1 && window.innerWidth < 640 ? (
        <button onClick={prevHandler} className="swiper-button swiper-btn-prev">
          <img src={previous_icon} alt="next" />
        </button>
      ) : relatedCards?.length > 4 && window.innerWidth > 640 && (
          <button onClick={prevHandler} className="swiper-button swiper-btn-prev">
          <img src={previous_icon} alt="next" />
        </button> 
      )
    }
      <Swiper
        navigation={true}
        slidesPerView={4}
        spaceBetween={40}
        freeMode={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
           640: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
              1300: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
         }}
        modules={[FreeMode, Pagination, Navigation]}
        className="relatedCarousel"
        onSwiper={(swiper) => setSwiperRef(swiper)}
      >
        {relatedCards !== undefined &&
          relatedCards?.map((item, index) => {
            const { marketplace, cardInfo, listingId } = item;
            if (!cardInfo || !listingId) {
              return null; // Skip rendering this slide
            }
            return (
              <SwiperSlide key={index}>
                <div className="relatedCard" >
                  <img
                    src={cardInfo?.image?.thumbnail || cardInfo?.image?.front}
                    alt={cardInfo?.title}
                    className="slider-image cursor-pointer"
                    loading="lazy"
                    onClick={() => onClickHandler(listingId)}
                  />
                </div>
                    <div className="align-items-center d-flex justify-content-between">
                <div className="card-title">{cardInfo?.title}</div>
                  {item?.type === 'marketplace' &&  <div className="card-wishlist cursor-pointer" >
          <img className='whiteWishlist' src={heartBtns[index]  ? heartFillBtn : "/Images/wishlistBlank.svg"} onClick={()=>addRemoveToWishList(index,listingId)} />
                  </div> }
                  </div>
                {item?.type === 'auction' ? (
                  <>
                    {item?.auction?.currentBid > 0 ? (
                      <div className="card-price">{`Current Bid: ${usaPriceformat(item?.auction?.currentBid)}`}</div>
                    ) : (
                      item?.auction?.minBid && (
                        <div className="card-price">{`Min Bid: ${usaPriceformat(item?.auction?.minBid)}`}</div>
                      )
                    )}
                  </>
                ) : (
                  marketplace?.price>0 && <div className="card-price">{`Price: ${usaPriceformat(marketplace?.price)}`}</div>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>
      {relatedCards?.length > 1 && window.innerWidth < 640 ? (
        <button onClick={nextHandler} className="swiper-button swiper-btn-next">
          <img src={next_icon} alt="prev" />
        </button>
      ) : relatedCards?.length > 4 && window.innerWidth > 640 && (
            <button onClick={nextHandler} className="swiper-button swiper-btn-next">
          <img src={next_icon} alt="prev" />
        </button> 
      )}
      {(guestLogin=="true" || guestLogin==null) && <GuestLogin
                show={showGuest}
              toggle={toggleGuest} />
            }
    </div>
  );
}
