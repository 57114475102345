import React from 'react'
import styles from './ListView.module.css'
import ListCard from './ListCard'



export default function ListView({products}) {
  
  return (
    <div className={`container my-5 ${styles.container}`}> {/* Apply module style */}
    {products!== undefined && products.map((product, index) => {
      const { marketplace, cardInfo, listingId, auction, type,isFavourite } = product;
      const CardProps={
        index,
        marketplace,
        cardInfo,
        listingId,
        minBid: auction?.minBid,
        currentBid: auction?.currentBid,
        bidTime: auction?.endDate,
        type,
        isFavourite:isFavourite
      }
      return (
       <ListCard {...CardProps}/>
      );
    })}
  </div>
  )
}
