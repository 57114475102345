import React, { useEffect, useState } from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import { Row, Col } from "react-bootstrap";
import styles from "./Earnings.module.css";
import emptyWallet from "../../asset/emptyWallet.svg";
import Pagination from "../../components/Pagination/Paginations";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import ButtonDetail from "../../components/ButtonWithIcon/ButtonDetail";
import { useNavigate } from "react-router-dom";
import { commonFunction, usaPriceformat } from "../../utils/commonFunction";
import ChartComponent from "./ChartComponent";
import SearchNotFound from "../../asset/SearchNotFound.svg";

const Earnings = () => {
  const navigate = useNavigate();

  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState(1);
  const [transactionData, setTransactionData] = useState([]);
  const [searchResult, setSearchResult] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeBox, setActiveBox] = useState("total");
  const [walletSummary, setWalletSummary] = useState();
  const [clear, setClear] = useState(false);
  const [activeBoxChanged, setActiveBoxChanged] = useState(false);

  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => currentActivePage - 1);
    }
  };

  const nextHandler = () => {
    if (currentActivePage < totalPages) {
      setCurrentActivePage((currentActivePage) => currentActivePage + 1);
    }
  };

  useEffect(() => {
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, totalPages]);

  const fetchTransactionData = (
    currentPage,
    sortCriteria,
    sortOrder,
    startDate,
    endDate
  ) => {
    let url = `wallet/transactions/${activeBox}/?page=${currentPage}&sort=${sortCriteria}&order=${sortOrder}`;
    if (startDate && endDate) {
      url += `&startDate=${startDate}&endDate=${endDate}`;
    }

    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTotalPages(response?.data?.data?.totalPages);
          setTransactionData(response?.data?.data?.transactions);
          setIsLoading(false);
          setSearchResult(false);
        }
      })
      .catch((error) => {
        setTransactionData([]);
        setIsLoading(false);
        setSearchResult(true);
      });
  };

  const walletTotalSummary = () => {
    let url = `wallet/summary`;
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setWalletSummary(response?.data?.data);
        }
      })
      .catch((error) => {});
  };

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  };

  useEffect(() => {
    fetchTransactionData(
      currentActivePage,
      sortCriteria,
      sortOrder,
      startDate,
      endDate
    );

    // Handle pagination disable/enable
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }

    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, sortCriteria, sortOrder, startDate, endDate, activeBoxChanged]);

  useEffect(() => {
    window.dataLayer.push({
      event: "Pageview",
      pagePath: `${window.location.href}`,
      pageName: "Earnings",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
    walletTotalSummary();
  }, []);

  useEffect(() => {
    setCurrentActivePage(1);
    setActiveBoxChanged(prev => !prev); // Toggle state to trigger API call
  }, [activeBox,startDate,endDate]);

  return (
    <ProfileContainer>
      {isLoading ? (
        <div className="mt-5">
          <Loader />
        </div>
      ) : (
        <div className={styles.children_container}>
          <ChartComponent
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            activeBox={activeBox}
            setActiveBox={setActiveBox}
            transactionData={transactionData}
            walletSummary={walletSummary}
            clear={clear}
            setClear={setClear}
          />
          <div className="walletSold">
            {transactionData?.length > 0 && (
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h3 className={styles.title}>
                  {activeBox === "total"
                    ? "Total Earnings"
                    : activeBox === "lastMonth"
                    ? "Last Month Earnings"
                    : "Pending Earnings"}
                </h3>
              </div>
            )}
            {transactionData?.length > 0 && (
              <Row className={`${styles.headerRow} mt-4 respDev`}>
                <Col xs={4} lg={2}>
                  Order
                </Col>
                <Col xs={4} lg={2}>
                  Title
                </Col>
                <Col xs={4} lg={2}>
                  Amount
                </Col>
                <Col xs={4} lg={2}>
                  Card Type
                </Col>
                <Col xs={4} lg={2}>
                  Sold On
                </Col>
                <Col xs={4} lg={2}>
                  Payment Status
                </Col>
              </Row>
            )}
            {transactionData?.length === 0 && !searchResult ? (
              <div className={styles.emptyVault}>
                <div className="d-flex align-items-center flex-column py-5 gap-2">
                  <img src={emptyWallet} alt="No Order" />
                  <h2 className="emptyheading mt-3">
                    Your wallet's waiting for its first treasure!
                    <br />
                    Time to turn your cards into gold.
                  </h2>
                  <ButtonDetail
                    text={`List Card`}
                    className={`${styles.confirmBtn} mt-4`}
                    clickHandler={() => {
                      navigate("/list-card");
                    }}
                  />
                </div>
              </div>
            ) : searchResult ? (
              <img
                src={SearchNotFound}
                alt="No Cards Available..."
                className="SearchNotFound SearchFailed"
              />
            ) : (
              transactionData?.length > 0 &&
              transactionData?.map((val, idx) => {
                const { status, createdAt } = val;
                return (
                  <Row
                    className={`${styles.card_Container} ${styles.myOrder}`}
                    key={idx}
                  >
                    <Col lg={2} xs={4}>
                      <div className="d-flex align-items-center">
                        <div className="d-flex">
                          <img
                            src={
                              val?.cardInfo?.image?.thumbnail ||
                              val?.cardInfo?.image?.front
                            }
                            alt="card"
                            className={styles.card_img}
                            style={{cursor:"default"}}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={2} xs={4} style={{ textTransform: "capitalize" }}>
                      <Col className="hideDesk">Title</Col>
                      <div className={styles.card_content}>
                        <div className={styles.card_title}>
                          {val?.cardInfo?.title}
                        </div>
                        <div className={`${styles.card_dec} respDev`}>
                          {val?.cardInfo?.description?.length <= 50
                            ? val?.cardInfo?.description
                            : `${val?.cardInfo?.description.slice(0, 50)}...`}
                        </div>
                      </div>
                    </Col>
                    <Col lg={2} xs={4}>
                      <Col className="hideDesk">Amount</Col>
                      {val?.totalValue
                        ? usaPriceformat(val?.totalValue)
                        : "0.00"}
                    </Col>
                    <Col lg={2} xs={4} style={{ textTransform: "capitalize" }}>
                      <Col className="hideDesk">Card Type</Col>
                      {val?.cardType}
                    </Col>
                    <Col lg={2} xs={4}>
                      <Col className="hideDesk">Sold On</Col>
                      {commonFunction(createdAt)}
                    </Col>
                    <Col lg={2} xs={6} style={{ textTransform: "capitalize" }}>
                      <Col className="hideDesk">Payment Status</Col>
                      <div
                        className={
                          status === "under processing"
                            ? "prcessingStatus"
                            : "sucessStatus"
                        }
                      >
                        {status}
                      </div>
                    </Col>
                  </Row>
                );
              })
            )}
          </div>

          {transactionData?.length > 0 && (
            <div className="mt-md-4 walletSold">
              <Pagination
                currentActive={currentActivePage}
                totalPage={totalPages}
                PageHandler={PageHandler}
                previousHandler={previousHandler}
                nextHandler={nextHandler}
                disabledPrev={disabledPrev}
                disabledNext={disabledNext}
              />
            </div>
          )}
        </div>
      )}
    </ProfileContainer>
  );
};

export default Earnings;
