import React, { useEffect, useState, useContext } from 'react';
import GridView from '../../components/GridView/GridView';
import ListView from '../../components/ListView/ListView';
import axios from "../../utils/axiosConfig";
import Loader from '../../components/Loader/Loader';
import { CategoryContext } from '../../Context/CategoryContext';
import NoCardFound from '../../asset/NoCardFound.svg';

export default function ProductList({ isGrid, selectedFilters, setPages, emptyProduct, pages }) {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { catSelectedTab } = useContext(CategoryContext);

  const fetchProducts = (category, filters, pages) => {
    setIsLoading(true);
    let url = `listing?&page=${pages}&perPage=9`;
    if (category) {
      url += `&categoryId=${category}`;
    }
    if (filters.price.min !== undefined && filters.price.max !== undefined && filters.type !== 'auction') {
      url += `&price=${filters.price.min},${filters.price.max}`;
    }
    if (filters.rarity.length > 0) {
      url += `&rarity=${filters.rarity.join(',')}`;
    }
   
    if (filters.sort.name === '' && filters.sort.id === '') {
      url += `&sort=createdAt`;
    } else {
       if (filters.sort.name !== '' && filters.sort.id !== '') {
      url += `&sort=${filters.sort.id}`;
    }
    }
    if (filters.type === 'auction') {
      url += `&type=${filters.type}`
    }

    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const fetchedData = response?.data?.data?.listings;
          const allPages = response?.data?.data?.totalPages;

          setProducts(fetchedData);
          setPages(allPages);
          emptyProduct(true);
        } else {
          setProducts([]);
          emptyProduct(false);
        }
      })
      .catch((error) => {
        setProducts([]);
        emptyProduct(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Only fetch products if the category is available
    if (catSelectedTab?.name) {
      fetchProducts(catSelectedTab.id, selectedFilters, pages);
    }
  }, [catSelectedTab?.name, selectedFilters, pages, isGrid]);

  if (isLoading) {
    return <Loader />;
  }

  if (products.length < 1) {
    return (
      <img src={NoCardFound} style={{ width: "75%" }} alt='No Cards Available...' className='NoCardFound' />
    );
  }

  if (isGrid === false) {
    return <ListView products={products} />;
  }
  return <GridView products={products} />;
}
