import React, { createContext,useState } from 'react';
import axios from '../utils/axiosConfig';

export const OrderContext = createContext();
//  const isAuthenticated = localStorage.getItem("token") ? true : false;

const OrderContextProvider = ({ children }) => {
  const [orderDetail, setOrderDetail] = useState([]);
  const [isOrderDone, setOrderDone] = useState(true)



  const PostOrderDetails = (data, type, bidId, buynow, deliveryServiceType) => {
    return new Promise((resolve, reject) => {
      let config = {
        method: "post",
        url: `order`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          billingDetails: data,
          type: buynow?.listingId ? buynow?.cardTypeforPay: type,
          ...(type === "auction" && { bidId: bidId }),
          ...(buynow?.listingId && buynow?.cardTypeforPay == "buynow" && { listingId: buynow?.listingId, qty: buynow?.qty }),
          ...(deliveryServiceType && { deliveryServiceType: deliveryServiceType[0]?.code }),
        },
      };
  
      // Make the API request to register the user
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
             setOrderDone(true)
            resolve(response);
          } else {
                setOrderDone(false)
            reject(response);
          }
        })
        .catch((error) => {
              setOrderDone(false)
          reject(error); 
        });
    });
  };
  const contextValue = {
    orderDetail,
    setOrderDetail,
    PostOrderDetails,
    isOrderDone,
    setOrderDone

  };

  return (
    <OrderContext.Provider value={contextValue}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
