import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import sample from "../../asset/card-sample.jpg";
import "./BidModal.css";
import ButtonDetail from "../../components/ButtonWithIcon/ButtonDetail";
import axios from "axios";
import { toast } from "react-toastify";
import SkeletonImg from '../../components/SkeletonImage/SkeletonImg.js'

function BidModal({ show, toggle, cardDetail, listingId, type , details}) {
  const [num, setNum] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleChange = (e) => {
    const inputValue = e.target.value;
     if (inputValue > 1000000) {
      return;
    }
    if (!isNaN(inputValue)) {
      setNum(inputValue);
    }
  };
   useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = (cardDetail?.image?.thumbnail || cardDetail?.image?.front ) || (cardDetail?.cardInfo?.image?.thumbnail || cardDetail?.cardInfo?.image?.front);

  }, [cardDetail?.image?.thumbnail, cardDetail?.cardInfo?.image?.thumbnail ]);

  const placeBid = () => {
    if (num === "") return toast.error("The price field is mandatory");
    if (num <= 0) return toast.error("The price must be greater than 0");

    const bidData = {
      listingId: cardDetail?.listingId || listingId,
      price: num,
    };

    const bidConfig = {
      method: "post",
      url: "bid",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(bidData),
    };

    axios
      .request(bidConfig)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          details()
          toggle();
          setNum("");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const description =
    cardDetail?.cardInfo?.description || cardDetail?.description || "";
  return (
    <Modal show={show} onHide={toggle} dialogClassName="bid-modal">
      <Modal.Header closeButton>
        <Modal.Title>{type === "revise" ? "Revise the Bid" : "Place a Bid"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between BidMainCardSection">
           <SkeletonImg className={`skeleton-img ${imageLoaded ? 'hidden' : ''}`} isVisible={!imageLoaded}/>
          {imageLoaded && <img
            src={
              type === "revise" ? (cardDetail?.image?.thumbnail || cardDetail?.image?.front ||
                sample) : (
                cardDetail?.cardInfo?.image?.thumbnail || cardDetail?.cardInfo?.image?.front ||
                sample
              )
            }
            className="cardImg"
            alt={sample}
          />}
          <div className="mx-5 BidSection w-100">
            <div className="BidCardSection">
              <div className="d-flex justify-content-between">
                <h3>{cardDetail?.cardInfo?.title || cardDetail?.title}</h3>
              </div>
              <div>
                {description.length <= 200
                  ? description
                  : `${description.slice(0, 200)}...`}
              </div>
            </div>
            <div className="my-3 bidAmount">
              <input
                type="text"
                className="form-control"
                value={num}
                onChange={handleChange}
                placeholder="Enter the amount"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bidModal-footer">
        <button className="cancel_btn mx-2" onClick={() => {
          toggle();
          setNum("");
        }}>
          Cancel
        </button>
        <ButtonDetail
          text="Submit for Bid"
          clickHandler={() => {
            // if (type !== "revise") {
              placeBid();
            // }
          }}
          className="sumbitBid"
        />
      </Modal.Footer>
    </Modal>
  );
}

export default BidModal;
