import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
export function ConfirmBox({show,toggle, handleClick, cardContent}) {

  return (
    <>

      <Modal show={show}
        onHide={toggle}
         dialogClassName="cbox-modal"
      >
        <Modal.Header closeButton>
                  <Modal.Title>Remove</Modal.Title>
        </Modal.Header>
        <Modal.Body>{cardContent}</Modal.Body>
        <Modal.Footer>
          <button className="cancel_btn confirmCancel mx-2" onClick={toggle}>
            Cancel
          </button>
          <ButtonIcon
            text="Confirm"
           clickHandler={() => {
            handleClick();
            toggle();
          }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}