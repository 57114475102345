import React, { createContext,useEffect, useState } from 'react';
import axios from "../utils/axiosConfig";

export const ProfileContext = createContext();
 const isAuthenticated = localStorage.getItem("token") ? true : false;

const ProfileContextProvider = ({ children }) => {
  const [userName, setUserName] = useState(null);
  const [userImg, setUserImg] = useState();
  const [checkOutFormValue, setCheckOutFormValue] = useState();
  const [profilInfo, setProfileInfo] = useState();
   const fetchName =()=>{ 
      const url = 'getProfile'
      let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };  
       return axios.request(config);
    }

  useEffect(() => {
    isAuthenticated && fetchName().then((response) => {
         const firstName = response?.data?.data?.user?.firstName
      setUserName(firstName)
      setUserImg(response?.data?.data?.user?.image)
      // localStorage.setItem("userImg",response?.data.data.user.image);
      setProfileInfo(response?.data?.data?.user)
      });
  }, []);
  const value = { userName, setUserName, profilInfo, setProfileInfo, setCheckOutFormValue, checkOutFormValue, fetchName, setUserImg, userImg }
  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};
export default ProfileContextProvider;