import React, {useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
// import EditIcon from "../../asset/edit_icon.svg";
// import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import SkeletonImg from '../../components/SkeletonImage/SkeletonImg.js'
import "./BidModal.css";
import { usaPriceformat } from "../../utils/commonFunction";
function HistoryModal(props) {
   const [imageLoaded, setImageLoaded] = useState(false);
  const {cardId, title, desc, cardImg, qty} = props.cardData
  const [showMore, setShowMore] = useState(false)

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const truncatedDesc =  showMore || desc.length <= 150 ? desc : `${desc.slice(0, 150)}...`
useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = cardImg;

  }, [cardImg]);
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.toggle}
        dialogClassName="bid-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Purchase History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between BidMainCardSection">
             <SkeletonImg className={`skeleton-img ${imageLoaded ? 'hidden' : ''}`} isVisible={!imageLoaded}/>
            {imageLoaded && (<img src={cardImg} className="cardImg" />)}
            <div className="mx-5 BidSection w-75">
              <div className="BidCardSection">
                <div className="d-flex justify-content-between">
                  <h3>{title}</h3>
                </div>
                <div>
                {truncatedDesc}
                  {!showMore && desc.length > 150 && (
                  <span
                    className="show-more"
                    onClick={toggleShowMore}
                  >{`show more`}</span>
                  )}
                  {showMore && (
                  <span
                    className="show-less"
                    onClick={toggleShowMore}
                  >{`show less`} </span>
                )}
                </div>
              </div>
              {props.historyData.length> 0 && 
                <div className="BidHistorySection">
                  <h4>History</h4>
                    <table className="table">
                    <thead>
                    <tr>
                      <th style={{ width: "33%" }}>Price</th>
                      <th style={{ width: "33%" }}>Quantity</th>
                      <th style={{ width: "33%" }}>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.historyData.map((item, index)=>{
                      const{price, qty, createdAt, updatedAt} = item
                      const createdAtDate = new Date(createdAt);

                      function leadingZero(number) {
                        return number < 10 ? `0${number}` : `${number}`;
                      }

                      const day = leadingZero(createdAtDate.getDate());
                      const month = leadingZero(createdAtDate.getMonth() + 1);
                      const year = createdAtDate.getFullYear();

                      const formattedDate = `${day}-${month}-${year}`;

                      return(
                        <tr  key = {index}>
                          <td style={{ width: "33%" }}>{`${usaPriceformat(price)}`}</td>
                          <td style={{ width: "33%" }}>{qty}</td>
                          <td style={{ width: "33%" }}>{formattedDate}</td>
                        </tr>
                        )
                      })}  
                    </tbody> 
                    </table>
                </div>}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HistoryModal;
