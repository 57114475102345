import React from 'react'
import notfoundImg from "../../asset/notfound.svg"
import "./Notfound.css"
import Navbar from "../Navigation/Navbar";
import Footer from "../Footer/Index";

export default function Notfound() {
  return (
    <>
     {/* <Navbar /> */}
      <div className='notfound'>
          <img src={notfoundImg} />
          <h2>Sorry! No result found :(</h2>
          <p>We’re sorry what you  were looking for. Please 
          try another way</p>
      </div>
        {/* <Footer /> */}
      </>
  )
}
