import React, { useState, useEffect, useContext } from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import "./Address.css";
import axios from "../../utils/axiosConfig";
import { toast } from "react-toastify";
import heartFillBtn from "../../asset/heartFillBtn.svg";
import AddressModal from "./AddressModal";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from 'react-router-dom'
import { ConfirmBox } from "../../components/ConfirmBox/ConfirmBox";
const Address = () => {
  const [show, setShow] = useState(false);
  const [addressList, setAddressList] = useState();
  const [addressByID, setAddressById] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [emptyForm, setEmptyForm] = useState(false)
   const [confirmBoxes, setConfirmBoxes] = useState([]);
  const navigate = useNavigate()
  const toggle = () => {
    setShow(!show);
    getAddress();
  };
   const toggleConfirmBox = (idx) => {
    setConfirmBoxes((prevBoxes) => {
      const updatedBoxes = [...prevBoxes];
      updatedBoxes[idx] = !updatedBoxes[idx];
      return updatedBoxes;
    });
  };
  const getAddress = () => {
    const url = `address`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setAddressList(response?.data?.data?.addresses);
          setIsLoading(true);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const getAddressById = (addressID) => {
    const url = `address/${addressID}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setAddressById(response?.data?.data?.address);
          setShow(!show);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const setDefaultAddress = (addressID, isDefault) => {
    if (isDefault) {
      toast.error("Address is already set as default")
      return;
    }
    const url = `address/default/${addressID}`;
    let config = {
      method: "put",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success("Address has been set as default");
          getAddress();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };


  const removeAddress = (addressID, isDefault) => {

    
    if (isDefault) {
      toast.error("Default address can not be deleted")
      return;
    }
    const url = `address/${addressID}`;
    let config = {
      method: "delete",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          getAddress();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAddress();
  }, []);

  const handleMouseEnter = (idx) => {
    setIsHovered(idx);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  useEffect(() => {
    const addressContainers = document.querySelectorAll(".addressConatiner");
    let maxHeight = 0;
    addressContainers.forEach((container) => {
      maxHeight = Math.max(maxHeight, container.offsetHeight);
    });
    addressContainers.forEach((container) => {
      container.style.height = `${maxHeight}px`;
      container.style.display = `flex`;
      container.style.flexDirection = `column`;
      container.style.justifyContent = `space-between`;
    });
  }, [addressList]);

  return (
    <ProfileContainer>
      <div className="children_container">
        <div className="addresstitle">Your Addresses</div>
        <div className="d-flex flex-wrap mt-3 address-container">
          <div className="addressConatiner newAddress cursor-pointer" onClick={() => {
            setShow(!show);
            setEmptyForm(true);
          }}>
            <div className="addAddressBtn ">
              <h2>+</h2>
              <p>Add Address</p>
            </div>
          </div>
          {!isLoading ? (
            <Loader />
          ) : (
            addressList?.map((item, idx) => (
              <div className="addressConatiner" key={idx}>

          
                <div className="d-flex justify-content-between align-items-baseline">
                  <h5>{item.firstName} {item.lastName}</h5>
                  <div className="d-flex addressIcons">
                    <img
                      src={item.isDefault ? heartFillBtn : "/Images/heartBtn.svg"}
                      title={item.isDefault ? "Default Address" : "Set Default"}
                      onClick={() => setDefaultAddress(item.addressId,item.isDefault)}
                      className="cursor-pointer mx-2"
                    />
                    <img
                      src="/Images/removeBtn.svg"
                      alt="removeBtn"
                       onClick={() => toggleConfirmBox(idx)}
                      className="btn-img cursor-pointer"
                      onMouseEnter={() => handleMouseEnter(idx)}
                      onMouseLeave={handleMouseLeave}
                      style={{ display: isHovered === idx ? "none" : "block" }}
                    />
                    <img
                      src="/Images/removeRedBtn.svg"
                      alt="removeRedBtn"
                     onClick={() => toggleConfirmBox(idx)}
                      className="btn-img cursor-pointer"
                      onMouseEnter={() => handleMouseEnter(idx)}
                      onMouseLeave={handleMouseLeave}
                      style={{ display: isHovered === idx ? "block" : "none" }}
                    />
                  </div>
                </div>
                <div className="address">
                  <p>
                    {item?.addressLine1} {item?.addressLine2}{item?.landmark ? ", " + item?.landmark : ""}
                    , {item?.postCode}, {item.townCity}, {item.state},{" "}
                    {item.country}{" "}
                  </p>
                  <p>Phone Number : {item.phoneNumber}</p>
                </div>
                 
                <div className="addressbtn">
                  <ButtonIcon
                    text="Edit"
                    className="addressbtns "
                    clickHandler={() => {
                      getAddressById(item.addressId);
                      setEmptyForm(false);
                    }}
                  />

                 
                </div>
                      <ConfirmBox
                        show={confirmBoxes[idx]}
                        toggle={() => toggleConfirmBox(idx)}
                        handleClick={() => removeAddress(item.addressId, item.isDefault)}
                        cardContent={`Are you really want to delete this address?`}
                      />
              </div>
            ))
          )}
        </div>
        {show && (
          <AddressModal show={show} toggle={toggle} addressByID={emptyForm ? "" : addressByID} />
        )}
        
      </div>
    </ProfileContainer>
  );
};

export default Address;
