import React, { createContext, useState } from 'react';
// import axios from "../utils/axiosConfig";

export const SearchContext = createContext();




const SearchContextProvider = ({ children }) => {
  const [searchKey, setSearchKey] = useState('')
  const [searchResults, setSearchResults] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null)
  
  
    const contextValue = {
      searchKey, setSearchKey,
      searchResults, setSearchResults,
      searchKeyword, setSearchKeyword,
    }
    return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};
export default SearchContextProvider;