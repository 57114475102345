import React, {useState } from "react";
import {Container } from "react-bootstrap";
import "./SignupPage.css";
import fantasyCards from "../../asset/FantasyCards.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../asset/loginPageBg.png";
import { BPasswordInput } from "../../components/bPasswordInput";
import { MailOutlined } from "@ant-design/icons";
import { BInput } from "../../components/bInput";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SignupPage = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passErr, setPassErr] = useState("");
  const [passErrCon, setPassErrCon] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [fnameErr, setFnameErr] = useState("");
  const [lnameErr, setLnameErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFirstName = (value) => {
    setFirstName(value);
  };

  const handleLastName = (value) => {
    setLastName(value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value === password) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const isValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isPasswordValid = (email) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{7,}$/.test(email);
  };

  const validateForm = () => {
    let fnameErr = "";
    let lnameErr = "";
    let emailErr = "";
    let passErr = "";
    let passErrCon = "";
    if (firstName.length === 0) {
      fnameErr = "Enter first name";
      setFnameErr("Enter first name");
    } else {
      setFnameErr("");
    }

    if (lastName.length === 0) {
      lnameErr = "Enter last name";
      setLnameErr("Enter last name");
    } else {
      setLnameErr("");
    }

    if (isValid(email)) {
      setEmailErr("");
    } else {
      emailErr = "Invalid email id";
      setEmailErr("Invalid email id");
    }

    if (isPasswordValid(password)) {
      setPassErr("");
    } else if (password.length < 8) {
      let passErr = "Password must have 8 characters.";
      setPassErr("Password must have 8 characters.");
    } else {
      passErr =
        "Password must contain 1 uppercase, \n 1 lowercase 1 number and 1 special character.";
      setPassErr(
        "Password must contain 1 uppercase, \n 1 lowercase 1 number and 1 special character."
      );
    }
    if (password === confirmPassword) {
      setPassErrCon("");
    } else {
      passErrCon = "Passwords do not match";
      setPassErrCon("Passwords do not match");
    }
    if (
      fnameErr.length === 0 &&
      lnameErr.length === 0 &&
      emailErr.length === 0 &&
      passErr.length === 0 &&
      passErrCon.length === 0
    ) {
      signupUser();
    }
  };

  const signupUser = () => {
    setIsLoading(true);
    const userData = {
      firstName,
      lastName,
      email,
      password,
    };

    axios
      .post(`/signup`, userData)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          axios
            .post(`/sendOTP`, {
              email: userData.email,
            })
            .then((response) => {
              if (response?.data?.statusCode === 200) {
                toast.success("Please Check your Email for OTP");
                navigate("/otpVerify", {
                  state: { email: email },
                  replace: true,
                });
              } else {
                toast.error(
                  response?.data?.message ||
                    "Something went wrong try again later."
                );
              }
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.message ||
                  "Something went wrong try again later"
              );
            });
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ||
            "Something went wrong try again later"
        );
        setIsLoading(false);
      });
  };
     const guestLogin = () => {
    let config = {
      method: "GET",
      url: `/guestUser`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          localStorage.setItem("guestLogin", true);
          // navigate("/login");
    navigate('/dashboard')

        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
      });
  };

  return (
    <div className="container-fluid">
      <div className="background-image">
        <img
          src={backgroundImage}
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
      </div>
      <Container className="d-flex justify-content-center align-items-center vheight">
        <div
          className="bg-white rounded"
        
        >
          <h2 className="mb-4 text-center">
            <img src={fantasyCards} alt="logo" />
          </h2>
          <div className="signin-text">SIGN UP</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <BInput
                previousSibling={<div className="formLabel">First Name</div>}
                placeholder={"Enter first name"}
                autoFocus={true}
                value={firstName}
                onChange={(value) => handleFirstName(value)}
                isError={fnameErr.length > 0}
                errorMsg={fnameErr}
                className="formControl"
              />
            </div>
            &nbsp;&nbsp;
            <div>
              <BInput
                previousSibling={<div className="formLabel">Last Name</div>}
                placeholder={"Enter last name"}
                value={lastName}
                onChange={(value) => handleLastName(value)}
                isError={lnameErr.length > 0}
                errorMsg={lnameErr}
                className="formControl"
              />
            </div>
          </div>
          <div className="mt-3">
            <BInput
              previousSibling={<div className="formLabel">Email Id</div>}
              placeholder={"Enter details"}
              autoFocus={true}
              value={email}
              onChange={(value) => handleEmailChange(value)}
              suffix={isValid(email) && <MailOutlined />}
              isError={emailErr.length > 0}
              errorMsg={emailErr}
              className="formControl"
            />
          </div>
          <div>
            <BPasswordInput
              previousSibling={
                <div>
                  <span className="formLabel">Password</span>
                </div>
              }
              placeholder={"Password"}
              className="formControl"
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              errorMsg={passErr}
              isError={passErr.length > 0}
            />
          </div>
          <div>
            <BPasswordInput
              previousSibling={
                <div>
                  <span className="formLabel">Confirm Password</span>
                </div>
              }
              placeholder={"Confirm Password"}
              className="formControl"
              value={confirmPassword}
              onChange={(e) => handleConfirmPasswordChange(e)}
              errorMsg={passErrCon}
              isError={passErrCon.length > 0}
              passwordEqual={passwordsMatch}
              onPressEnter={() => validateForm}
            />
          </div>
<div className="guestLoginText" onClick={()=>{guestLogin()}}>Use as Guest User?</div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
              fontSize: "10px",
            }}
          ></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              variant="primary"
              type="submit"
              //disabled={!formValid}
              className="submit"
              onClick={validateForm}
            >
              {/* SIGN UP */}
              {isLoading ? "LOADING..." : "SIGN UP"}
            </button>
          </div>
          <div className="signup-text">
            <div>
              Already have an account?
              <Link to="/login" relative="path">
                {" "}
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SignupPage;
