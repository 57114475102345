import React, { useState, useEffect } from "react";
import { usaPriceformat } from "../../utils/commonFunction";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import "./earningGraph.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";

ChartJS.register(...registerables);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const ChartComponent = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  activeBox,
  setActiveBox,
  transactionData,
  walletSummary,
  clear,
  setClear,
}) => {
  const [graphData, setGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const graphwithfilter = (activeBox) => {
    
    let url = `wallet/graph/${activeBox}`;
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setGraphData(response?.data?.data);
        }
      })
      .catch((error) => {
      })
  };

  const handleBoxClick = (boxName) => {
    setActiveBox(boxName);
  };

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setClear(false);
  }, [activeBox, clear]);

  useEffect(() => {
    graphwithfilter(activeBox);
  }, [activeBox, startDate, endDate]);

  const handleStartDateChange = (date) => {
    setStartDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  const getChartData = () => {
    if (!graphData) {
      return {
        labels: [],
        datasets: [],
      };
    }

    // Unified date array
    const allDates = [
      ...new Set([
        ...graphData.digital.dates,
        ...graphData.physical.dates,
        ...graphData.totals.dates,
      ]),
    ].sort((a, b) => new Date(a) - new Date(b));

    // Function to get amounts mapped to the unified dates
    const getAmounts = (dates, amounts) => {
      const dateAmountMap = dates.reduce((acc, date, index) => {
        acc[date] = amounts[index];
        return acc;
      }, {});
      return allDates.map((date) => dateAmountMap[date] || 0);
    };

    const digitalData = getAmounts(graphData.digital.dates, graphData.digital.amounts);
    const physicalData = getAmounts(graphData.physical.dates, graphData.physical.amounts);
    let totalData = getAmounts(graphData.totals.dates, graphData.totals.amounts);

    let labels;
    if (activeBox === "total") {
      // Group total data by month
      const monthLabels = Array.from(new Set(allDates.map((date) => moment(date).format("MMM YYYY"))));
      const totalDataByMonth = monthLabels.map((monthLabel) => {
        const month = moment(monthLabel, "MMM YYYY").format("YYYY-MM");
        return allDates.reduce((sum, date, index) => {
          if (date.startsWith(month)) {
            return sum + totalData[index];
          }
          return sum;
        }, 0);
      });

      labels = monthLabels.map(label => moment(label, 'MMM YYYY').format('MMM YYYY'));
      totalData = totalDataByMonth;
    } else {
      labels = allDates.map((date) => moment(date).format("MMM D"));
    }

    return {
      labels: labels,
      datasets: [
        {
          type: "line",
          label: "Total",
          borderColor: "rgba(254, 174, 174, 0.7)",
          backgroundColor: "rgba(254, 174, 174, 0.7)",
          borderWidth: 3,
          fill: false,
          data: totalData,
          spanGaps: true,
        },
        {
          type: "bar",
          label: "Digital",
          backgroundColor: "rgba(169, 244, 208, 0.7)",
          borderColor: "rgba(169, 244, 208, 0.7)",
          borderWidth: 1,
          data: digitalData,
        },
        {
          type: "bar",
          label: "Physical",
          backgroundColor: "rgba(174, 201, 254, 0.7)",
          borderColor: "rgba(174, 201, 254, 0.7)",
          borderWidth: 1,
          data: physicalData,
        },
      ],
    };
  };

  const calculateYAxisMax = (datasets) => {
    const allData = datasets.reduce((acc, dataset) => {
      return acc.concat(dataset.data);
    }, []);
    const maxDataValue = Math.max(...allData);
    const buffer = maxDataValue * 0.2;
    return Math.ceil((maxDataValue + buffer) / 1000) * 1000;
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        max: calculateYAxisMax(getChartData().datasets),
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text:
          activeBox === "total"
            ? `${usaPriceformat(walletSummary?.total)?.replace(".00", "")}`
            : activeBox === "lastMonth"
            ? `${usaPriceformat(walletSummary?.lastMonth)?.replace(".00", "")}`
            : `${usaPriceformat(walletSummary?.pending)?.replace(".00", "")}`,
        align: "start",
        padding: {
          left: 0,
        },
        font: {
          size: 25,
          weight: "bold",
        },
        color: "#000",
      },
    },
  };

  return (
    <>
      <div className="walletdashboard">
        <div className="walletsidebar">
          <h3 className="mb-4">My Earnings</h3>
          {transactionData?.length > 0 && (
            <>
              <div
      className={`earnings ${activeBox === "total" ? "active" : ""} ${walletSummary?.total === 0 ? "boxdisabled" : ""}`}
      onClick={() => walletSummary?.total !== 0 && handleBoxClick("total")}
              >
                <h6>Total</h6>
      <p>
        {usaPriceformat(walletSummary?.total)?.replace(".00", "")}
      </p>
              </div>
              <div
      className={`last-month ${activeBox === "lastMonth" ? "active" : ""} ${walletSummary?.lastMonth === 0 ? "boxdisabled" : ""}`}
      onClick={() => walletSummary?.lastMonth !== 0 && handleBoxClick("lastMonth")}
              >
                <h6>Last Month</h6>
                <p>
                  {usaPriceformat(walletSummary?.lastMonth)?.replace(".00", "")}
                </p>
              </div>
              <div
      className={`pending ${activeBox === "pending" ? "active" : ""} ${walletSummary?.pending === 0 ? "boxdisabled" : ""}`}
      onClick={() => walletSummary?.pending !== 0 && handleBoxClick("pending")}
              >
                <h6>Pendings</h6>
      <p>
        {usaPriceformat(walletSummary?.pending)?.replace(".00", "")}
      </p>
              </div>
            </>
          )}

        </div>
        <div className={"main-chart"}>
          <div className="chart-controls">
           <>
  <div className="datepicker-container">
    <DatePicker
      className="sortingdropdown mx-md-3"
      selected={startDate}
      onChange={(date) => handleStartDateChange(date)}
      dateFormat="yyyy-MM-dd"
      maxDate={endDate || new Date()} // Max date is either endDate or today
      placeholderText="Start Date"
      withPortal
    />
    <img src="/Images/calcon.svg" className="calendar-icon" alt="calendar" />
  </div>
  <div className="datepicker-container">
    <DatePicker
      className="sortingdropdown"
      selected={endDate}
      onChange={(date) => handleEndDateChange(date)}
      dateFormat="yyyy-MM-dd"
      placeholderText="End Date"
      minDate={startDate} // Min date is startDate
      maxDate={new Date()} // Max date is today
      disabled={!startDate}
      withPortal
    />
    <img
      src="/Images/calcon.svg"
      className="calendar-icon calendar-endicon"
      alt="calendar"
    />
  </div>
</>

            <ButtonIcon
              text="Clear"
              className="filterClear"
              clickHandler={() => {
                setClear(true);
              }}
              isButtonDisable = {!startDate}
            />
          </div>
          {isLoading ? (
            <div className="mt-5">
            <Loader />
            </div>
          ) : (
            transactionData?.length > 0 && (
              <div className="barchart-container">
                <div className="barchart">
              <Bar data={getChartData()} options={options} />
            </div>
              </div>
            )
          )}
        </div>
      </div>
      {transactionData?.length > 0 && (
        <div className="paymentTxt">
          <b>
            <span className="error-message">*</span>Upcoming payment
          </b>
          { " "}will be reflected 15 days after the selling date.
        </div>
      )}
    </>
  );
};

export default ChartComponent;
