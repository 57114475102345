import React from "react";
import "./Index.css";

const Index = ({ tabs, tabsClickHandler, selectedTab }) => {
  return (
    <div className="tab-container mt-5">
      <div className="tab-outer">
        {tabs.map((value, index) => (
          <div
            key={index}
            className={value.id === selectedTab ? "activeTabs" : "unActiveTabs"}
            onClick={() => {
              tabsClickHandler(value.id);
            }}
          >
            {value.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
