import React, { useEffect, useState } from "react";
import Navbar from "../../Containers/Navigation/Navbar";
import Footer from "../../Containers/Footer/Index";
import "./Index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import profile from "../../asset/profile/profile.svg";
import wishlist from "../../asset/profile/wishlist.svg";
import transaction from "../../asset/profile/transaction.svg";
import soldItem from "../../asset/profile/sold-item.svg";
import vault from "../../asset/profile/vault.svg";
import Logout from "../../asset/profile/Logout.svg";
import wallet from "../../asset/profile/wallet.svg";
import { toast } from "react-toastify";
import axios from "axios";
import BreadCrumbs from "../../Containers/BreadCrumb/BreadCrumbs";

const Profile = ({ children }) => {
  const navigate = useNavigate();
  const [logged, setLogged] = useState(null);
  const [loggingOut, setLoggingOut] = useState(false);

  const location = useLocation();
    const sidebarData = [
    {
      name: "Personal Info",
      url: "/profile",
      icon: profile,
      },
     {
      name: "Address",
      url: "/address",
      icon: "/Images/address-location.svg",
    },
    {
      name: "Wishlist",
      url: "/wishlist",
      icon: wishlist,
    },
    {
      name: "My Orders",
      url: "/orders",
      icon: transaction,
    },
    {
      name: "Sold Items",
      url: "/sold-items",
      icon: soldItem,
    },
    {
      name: "My Vault",
      url: "/vault",
      icon: vault,
      },
    {
      name: "Earnings",
      url: "/earnings",
      icon: wallet,
    },
    {
      name: "Logout",
      icon: Logout,
    },
  ];

const logOutHandler = () => {
  // If already logging out, prevent further calls
  if (loggingOut) {
    return;
  }

  setLoggingOut(true); // Set loggingOut to true to indicate logout process started

  let config = {
    method: "GET",
    url: `/logout`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Logout successful!");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userLastName");
        localStorage.removeItem("userFirstName");
        localStorage.removeItem("image");
        localStorage.removeItem("guestLogin");

        setLogged(Math.random());
        navigate("/login");
      } else {
        toast.error(
          response?.data?.message || "Something went wrong try again later."
        );
      }
    })
    .catch((error) => {})
    .finally(() => {
      setLoggingOut(false); // Reset loggingOut state variable after logout process is complete
    });
};

  useEffect(() => {
    //used this because above function this will not working
    if (logged) {
      navigate("/login");
    }
  }, [logged]);
   useEffect(() => {
     window.scrollTo({ top: 0, behavior: "smooth" });
       window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Profile Page',
      });
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <BreadCrumbs />
  
      <div className="container mb-5 mt-4">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <div className="sidebar-container sortBy">
              {sidebarData.map((value, id) => (
                <div
                  key={id}
                  className={
                    location.pathname === value.url ? "Active" : "unActive"
                  }
                >
                  <Link key={id.toString()} to={value.url} relative="path">
                    <img src={value.icon} alt={value.name} />
                    {value.url ? (
                      <span>{value.name}</span>
                    ) : (
                      <span
                        onClick={() => {
                          logOutHandler();
                        }}
                      >
                        {value.name}
                      </span>
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-9 col-md-12">{children}</div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Profile;
