import React, { useEffect, useState } from "react";
import "./IncDecCounter.css";
function IncDecCounter(props) {
  let [num, setNum] = useState(props.itemCount);
  let incNum = () => {
    if (num < props.qty?.available) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };
  useEffect(() => {
    props.setItemCount(num) 
  }, [num])
  useEffect(() => {
    setNum(props.itemCount);
  }, [props.itemCount]);

  return (
    <>
      <div className="d-flex align-items-center IncDecCounter">
        <div className="d-flex inputGroup justify-content-center">
          <div className="input-group-prepend">
            <button
              className={`btn ${num==1 ? "disabled lightbtn" : ""}`}
              type="button"
              onClick={decNum}
            >
              -
            </button>
          </div>
          <input
            type="text"
            className="form-control"
            value={num}
            onChange={handleChange}
            style={{pointerEvents:"none"}}
          />
          <div className="input-group-prepend">
            <button
             className={`btn ${num==props.qty?.available ? "disabled lightbtn" : ""}`}
              type="button"
              onClick={incNum}
            >
              +
            </button>
          </div>
        </div>
              <div className="itemQuantity">
         <>
  {props.qty?.available >= 1 && (
    <>
      <span style={{ color: "#7E8492" }}>{props.qty?.available} available</span>
      {props.qty?.sold >= 1 && " / "}
    </>
  )}
  {props.qty?.sold >= 1 && (
    <span style={{ color: "#9547CF" }}>{props.qty?.sold} sold</span>
  )}
</>
   
        </div>
      </div>
    </>
  );
}

export default IncDecCounter;
