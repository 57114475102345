import React, { useState, useEffect } from "react";
import { Row, Col,Form } from "react-bootstrap";
import styles from "./vault.module.css";
import Pagination from "../../components/Pagination/Paginations";
import ProductCard from "../../components/ProductCard/Profile/Index";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import BidModal from "./BidModal";
import { toast } from "react-toastify";
import HistoryModal from './HistoryModal'
import { useNavigate } from 'react-router-dom'
import vaultImg from "../../asset/vault-page/vaultImg.svg"
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon'
import Nav from "react-bootstrap/Nav";
import { RxCross2 } from "react-icons/rx";
import SearchNotFound from '../../asset/SearchNotFound.svg'

const MyVault = () => {
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [vaultData, setVaultData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchResult,setSearchResult] = useState(false)
  const [sortCriteria, setSortCriteria] = useState('date');
  const [sortOrder, setSortOrder] = useState(1);
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const toggleHistory = () => setShowHistory(!showHistory);
  const toggle = () => setShow(!show);
  const [isType, setIsType]= useState('');
  const [totalPages, setTotalPages] = useState()
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const [historyData, setHistoryData] = useState([])
  const [searchCard, setSearchCard] = useState();
  const handleSearch = (e, condition) => {
  if (searchCard) {
    if (e?.key === "Enter" || e?.keyCode === 13 || condition) {
      setIsLoading(true); 
        setCurrentActivePage(1)
        setSearchCard(searchCard);
        fetchVaultData(currentActivePage, sortCriteria, sortOrder, searchCard);
    } 
  }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchCard(value);
    if (value === '') {
      setCurrentActivePage(1)
      fetchVaultData(currentActivePage, sortCriteria, sortOrder, '');
    }
  };
  const removeText = () => {
     setSearchCard('')
     setIsLoading(true); 
     setCurrentActivePage(1)
     fetchVaultData(currentActivePage, sortCriteria, sortOrder,'');
  }

  const navigate = useNavigate()


  
  const [cardData, setCardData] = useState({
    cardId: '',
    title: '',
    desc: '',
    qty: '',
    cardImg: '',
    listedQty:'',
  });
  

  const handleSort = (event) => {
  const selectedValue = event.target.value;
  const selectedOrder = event.target.options[event.target.selectedIndex].getAttribute('data-order');
    setSortCriteria(selectedValue);
    setSortOrder(parseInt(selectedOrder, 10));
  };


  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => (currentActivePage -= 1));
    }
  };

  const nextHandler = () => {
    setCurrentActivePage((currentActivePage) => (currentActivePage += 1));
  };

  const fetchVaultData = (currentPage, sortCriteria, sortOrder, keyword = '') => {
    let url = `vault/?page=${currentPage}&sort=${sortCriteria}&order=${sortOrder}`;
    if (keyword) {
      url += `&keyword=${keyword}`;
    }
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setVaultData(response?.data?.data?.vault);
          // setVaultData([])
          setTotalPages(response?.data?.data?.totalPages)
          setIsLoading(false);
          setSearchResult(false)
        } 
      })
      .catch((error) => {
        setVaultData([]);
        setSearchResult(true)
        setIsLoading(false);
      });
  };

  const openHistory = (cardId,title, desc, cardImg, qty) =>{
    const newCardData = {
      cardId,
      title,
      desc,
      cardImg,
      qty,
    };
    setCardData(newCardData)
    setShowHistory(!showHistory)
    const url = `purchaseHistory/${cardId}`;
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setHistoryData(response?.data?.data?.purchaseHistory);
          setIsLoading(false);
        } 
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message) 
        setIsLoading(false);
      });
  }

  const handleClick = (cardId,title, desc, cardImg, qty, listedQty)=>{
    const newCardData = {
      cardId,
      title,
      desc,
      cardImg,
      qty,
      listedQty
    };
    setCardData(newCardData);
    setIsType('marketplace')
    setShow(!show)
  }

  const handleClickAuction = (cardId,title, desc, cardImg, qty, listedQty)=>{
    const newCardData = {
      cardId,
      title,
      desc,
      cardImg,
      qty,
      listedQty
    };
    setCardData(newCardData);
    setIsType('auction')
    setShow(!show)
  }

  const handleSubmit = (cardId, data, setData, isType) =>{
    
    const price = parseFloat(data.price);
    const quantity = parseInt(data.quantity, 10);
    const reserve = parseFloat(data.reserve);
    
  if (isNaN(price) || isNaN(quantity || price < 0)) {
    toast.error("Invalid price or quantity. Please enter valid numbers.");
    return;
  }

   
  let postData;

  if (isType === 'marketplace') {
    postData = {
      cardId: cardId,
      type: 'marketplace',
      marketplace: {
        price: price,
      },
      qty: {
        total: quantity,
      },
    };
  } else {
    postData = {
      cardId: cardId,
      auction: {
        minBid: price,
        reserveLimit: reserve,
      },
      qty: {
        total: 1,
      },
    };
  }
    axios
      .post(isType === 'marketplace' ? '/listing' : '/auction', postData)
      .then((response) => {
        toast.success("Successfully submitted:", response?.data?.message);
        setData({price:'', quantity:''})
        fetchVaultData(currentActivePage, sortCriteria, sortOrder, searchCard)
        toggle();
      })
      .catch((error) => {
          toast.error(error?.response?.data?.message)
          setData({price:'', quantity:''})
          toggle();
      });
  }

  useEffect(()=>{
    fetchVaultData(currentActivePage, sortCriteria, sortOrder,searchCard)
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }
  
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  },[currentActivePage,sortCriteria,sortOrder])

  useEffect(() => {
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, totalPages]);
   useEffect(() => {
     window.scrollTo({ top: 0, behavior: "smooth" });
     window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'My Vault Page',
      });
   }, []);
  return (
    <>  
      <div className="commonsection">
      <div className="d-flex justify-content-between align-items-center flex-wrap my-2">
          <h3 className={styles.title}>My Vault</h3>
            <div className="customSearch newSearch">
             <div
              className={`d-flex align-items-center`}
            >
              <div className={`input-group`}>
                <Form.Control
                  className={`rounded-pill searchForm`}
                  type="search"
                  placeholder="Search for Card Title"
                  maxLength={20}
                  value={searchCard ? searchCard: ''}
                  onChange={handleChange}
                  onKeyDown={handleSearch}
                />
                <div className="input-group-append">
                  <span
                    className={`input-group-text bg-transparent border-0 icon searchIcon`}
                  >
                    <BiSearch onClick={()=>handleSearch('',true)}/>
                  </span>

                </div>
                 {searchCard && <div className="input-group-append" onClick={()=>removeText()}>
                  <span
                    className={`input-group-text bg-transparent border-0 icon searchIcon CrossIcon`}
                  >
                 <RxCross2 />
                  </span>
                </div> }
              </div>
          </div>
          </div>
        <div className="d-flex align-items-center sortingContainer">
          <span className="mx-2 sortBy">Sort by</span>
          <select
            className='sortingdropdown'
            onChange={(e) => handleSort(e)}
            // value={sortCriteria}
          >
          <option value="date" data-order="1">Newest to Oldest</option> 
          <option value="date" data-order="-1">Oldest to Newest</option> 
          <option value="title" data-order="1">Name - A-Z</option>
          <option value="title" data-order="-1">Name - Z-A</option>
          {/* <option value="price" data-order="1">Price - Low to High</option>
          <option value="price" data-order="-1">Price - High to Low</option> */}
          </select>
        </div>
      </div>

        {isLoading ?
          <div className="mt-5">
            <Loader />
            </div>
          : (
        <>
            <Row className={styles.card_Container}>
              
         {vaultData?.length === 0 && !searchResult ? (
          <div className={styles.emptyVault}>
            <div className='d-flex align-items-center flex-column py-5 gap-3'>
              <img src={vaultImg} />
              <h2 className="emptyheading">Your Vault's Hungry for Cards,<br/>Marketplace to the Rescue!</h2>
              <p className="emptysubheading">Click the button to see the card collection</p>
              <ButtonIcon text={`Homepage`} className={styles.confirmBtn} clickHandler={() => { navigate('/');}} /> 
            </div>
          </div>
               ) : searchResult ? (
                  <img src={SearchNotFound} alt='No Cards Available...' className='SearchNotFound SearchFailed' />
                ) : 
          vaultData?.length> 0 && vaultData?.map((val, idx) => {
          const {card, qty, listedQty} = val
          return(
              <Col lg={6} md={12} key={idx}>
             <ProductCard
               title={card.title}
              description={`${card?.description.length> 18 ? card?.description.split(' ').slice(0, 18).join(' ')+"...":card?.description }`}
                
               imgUrl={card?.image?.thumbnail || card?.image?.front}
               isMyVault={true}
               qty = {qty}
               marketQty ={listedQty?.marketplace}
               auctionQty = {listedQty?.auction}
               
               handleClick={() =>handleClick(card.cardId, card.title, card.description, card.image.front, qty, listedQty)}
               
               handleClickAuction={() =>handleClickAuction(card.cardId, card.title, card.description, card.image.front, qty, listedQty)}
               
               openHistory={() => openHistory(card.cardId,card.title, card.description, card.image.front, qty)}
             />
           </Col>
         )})
         }
       </Row>
       {cardData !== undefined && <BidModal 
              show={show} 
              toggle={toggle} 
              handleSubmit = {handleSubmit} 
              isType={isType} 
              cardData = {cardData}
        />}
        {cardData !== undefined && <HistoryModal 
          show={showHistory} 
          toggle={toggleHistory} 
          cardData = {cardData}
          historyData = {historyData}
        />}
       {vaultData?.length>0 && <div className="mt-4">
         <Pagination
           currentActive={currentActivePage}
           totalPage={totalPages}
           PageHandler={PageHandler}
           previousHandler={previousHandler}
           nextHandler={nextHandler}
           disabledPrev={disabledPrev}
           disabledNext={disabledNext}
         />
       </div>}
      </>
      )}
     </div>
    </>
  );
};

export default MyVault;
