import React from "react";
import styles from "./profile.module.css";

const ProfileTabs = ({ tabs = [], activeTab, tabClickHandler }) => {
  return (
    <>
      <div className={styles.tabContainer}>
        {tabs?.map((val, idx) => (
          <div
            key={idx}
            className={val.id === activeTab ? styles.active : styles.unActive}
            onClick={() => {
              tabClickHandler(val.id);
            }}
          >
            {val.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProfileTabs;
