import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./Index.css";
import eye_open from "../../asset/eye_open.png";
import eye_close from "../../asset/eye_close.png";

const Input = ({ ...attribute }) => {
  const [isEyeClose, setIsEyeClose] = useState(true);
  return (
    <div className="password_input_container">
      <Form.Control
        type={isEyeClose ? "password" : "text"}
        placeholder="Enter Old Password"
        {...attribute}
        style={{paddingRight:"2.5rem"}}
      />
      {isEyeClose ? (
        <img
          src={eye_close}
          alt="eye_close"
          onClick={() => {
            setIsEyeClose((v) => !v);
          }}
        />
      ) : (
        <img
          src={eye_open}
          alt="eye_open"
          onClick={() => {
            setIsEyeClose((v) => !v);
          }}
        />
      )}
    </div>
  );
};

export default Input;
