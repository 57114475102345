import React from "react";
import styles from "./Button.module.css";

const buttonIcon = ({
  iconPath,
  text,
  clickHandler,
  isNotLoading = true,
  isButtonDisable = false,
  className,
  type,
}) => {
  return (
    <button
      type={type}
      disabled={isButtonDisable}
      onClick={(e) => {
        isNotLoading && clickHandler(e);
      }}
      className={`${styles.customButton} ${className}`}
    >
      {iconPath && (
        <img src={iconPath} alt={text} className={styles.custom_button_img} />
      )}
      {isNotLoading ? text : "LOADING..."}
    </button>
  );
};

export default buttonIcon;
