import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "../../utils/axiosConfig";
import { usaPriceformat } from '../../utils/commonFunction';
export default function ServiceList({setServiceType, shippingCost}) {
    const [serviceList, setServiceList] = useState([]);
    const fetchService = () => {
    const url = "delivery/services";
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setServiceList(response?.data?.data?.services);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
    };
    useEffect(() => {
        fetchService()
    }, [])
  const handleServiceChange = (e) => {
   setServiceType && setServiceType(e.target.value);
  };
  return (
           <Form.Group className="serviceListMenu">
                 
                  <Form.Select
                    className="form-control pinInput"
                    aria-label="service"
                    name="service"
                    defaultValue=""
                    // value={serviceType}
                    onChange={handleServiceChange}
                  >
                    <option value="" disabled>
                      Choose a Shipping Method
                    </option>
                    {serviceList?.length > 0 &&
                      serviceList?.map((item, idx) => (
                        <option key={idx} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                {shippingCost && <span className="md-mx-4">
                  <span className="shipEstimate">Shipping estimate</span>
                  <span className="md-mx-4" style={{ fontWeight: "bold" }}>{usaPriceformat(shippingCost)}</span>
                </span>}
                
                </Form.Group>

  )
}
