import React,{useState, useEffect, useContext} from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import {Row, Col } from "react-bootstrap";
import cartIcon from "../../asset/cart-white.svg";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import styles from "./Wishlist.module.css";
import emptyWishlist from "../../asset/emptyWishlist.svg";
import axios from "../../utils/axiosConfig";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { CartContext } from "../../Context/CartItemContextProvider";
import Loader from "../../components/Loader/Loader";
import Paginations from "../../components/Pagination/Paginations";
import { usaPriceformat } from "../../utils/commonFunction";


const Wishlist = () => {
  const navigate = useNavigate()
  // const [isWishlist, setIsWishList] = useState([]);
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState('date');
  const [sortOrder, setSortOrder] = useState(1);
  const [totalPages, setTotalPages] = useState()
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
   const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => (currentActivePage -= 1));
    }
  };

  const nextHandler = () => {
    setCurrentActivePage((currentActivePage) => (currentActivePage += 1));
  };
  const handleSort = (event) => {
  const selectedValue = event.target.value;
  const selectedOrder = event.target.options[event.target.selectedIndex].getAttribute('data-order');
    setSortCriteria(selectedValue);
    setSortOrder(parseInt(selectedOrder, 10));
  };


  const [sortData, setSortData] = useState();
  const { setItemCounter, getCartItemDetail } = useContext(CartContext);
  const [isItemUpdate, setIsItemUpdate] = useState(false)
  const [selectAll, setSelectAll] = useState(false); // State for the outer checkbox
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allItemIds = sortData.map((item) => item.listingInfo.listingId);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };
   const toggleItem = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
     } 
  };
  // const handleSort = (event) => {
  //    wishListItem(event.target.value);
  // };
  const onClickHandler = (listingId) =>{
      navigate(`/card-details/${listingId}`);
  }
 


 const addRemoveToWishList = (listingId) => {
     let data = JSON.stringify({
      listingId: listingId,
    });
    let config = {
      method: "post",
      url: `addRemoveWishlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          wishListItem(currentActivePage, sortCriteria, sortOrder);
        }
      })
      .catch((error) => {
      });
  }
  const removeBulkWishlist = () => {
    if (selectedItems.length <= 0) {
      toast.error("Please select a checkbox to continue.");
      return;
    }
     let data = JSON.stringify({
      listingIds: selectedItems,
    });
    let config = {
      method: "post",
      url: `removeBulkWishlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
            wishListItem();
        }
      })
      .catch((error) => {
           toast.error(error?.response?.data?.message)
      });
  }
  
  const addBulkCartItem = () => {
      setIsItemUpdate(false)
    if (selectedItems.length <= 0) {
      toast.error("Please select a checkbox to continue.");
      return;
    }
     let data = JSON.stringify({
      listingIds: selectedItems,
    });
    let config = {
      method: "post",
      url: `addBulkCartItem`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          setIsItemUpdate(true)
            wishListItem();
        } else {
           toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
           toast.error(error?.response?.data?.message)
      });
}
  const wishListItem = (currentPage, sortCriteria, sortOrder) => { 
    
    const url = `getWishlist/?page=${currentPage}&sort=${sortCriteria}&order=${sortOrder}`;
    let config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };  
    axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        const wishListData = response?.data.data.wishlist
        // setIsWishList(wishListData)
        setSortData(wishListData)
         setTotalPages(response?.data?.data?.totalPages)
        setSelectAll(false)
          setIsLoading(true);
        setSelectedItems([]);
      }
    })
    .catch((error) => {
    });
  };
  const addToCart = (listingId) => {
    setIsItemUpdate(false)
     let data = JSON.stringify({
      listingId: listingId,
      qty: 1,
    });
    let config = {
      method: "post",
      url: `cartItem`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          setIsItemUpdate(true)
            wishListItem(currentActivePage, sortCriteria, sortOrder)
        } else {
          toast.error(response?.data?.message)
        }
      })
      .catch((error) => {
              toast.error(error?.response?.data?.message)

      });
  }
    useEffect(()=>{
    wishListItem(currentActivePage, sortCriteria, sortOrder)
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }
  
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  },[currentActivePage,sortCriteria,sortOrder])

  useEffect(() => {
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, totalPages]);
  
   useEffect(() => {
      getCartItemDetail().then((res) => {
          setItemCounter(res?.data?.data)
      });
  },[isItemUpdate])
  return (
    <ProfileContainer>
      <div className={styles.children_container}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className={styles.title}>Wishlist</h3>
          <div className="d-flex align-items-center">
            <span className="mx-2 sortBy">Sort by</span>
        <select
      className="sortingdropdown"
      onChange={(e) => handleSort(e)}
      // value={sortCriteria}
    >
      <option value="date" data-order="1">Newest to Oldest</option> 
      <option value="date" data-order="-1">Oldest to Newest</option> 
      <option value="title" data-order="1">Name - A-Z</option>
      <option value="title" data-order="-1">Name - Z-A</option>
      <option value="price" data-order="1">Price - Low to High</option>
      <option value="price" data-order="-1">Price - High to Low</option>
    </select>
          </div>
        </div>
        {sortData?.length > 0 ? <>
          <Row className="mt-4">
            <Col lg={8} md={12}>
              <div className="d-flex align-items-center">
                <input type="checkbox" checked={selectAll}
                  onChange={toggleSelectAll}></input>
                <div className="mx-4">All Cards</div>
              </div>
            </Col>
            <Col className={styles.actionTxt}>Action</Col>
          </Row>
          {sortData?.map((item, idx) => (
            <Row className={styles.card_Container} key={idx}>
              <Col lg={7} md={12}>
                <div className={`${styles.wishDiv} d-flex align-items-center` }>
                  <input type="checkbox" className={styles.checkBoxV} checked={selectedItems.includes(item.listingInfo.listingId)}
                    onChange={() => toggleItem(item.listingInfo.listingId)}></input>
                  <div className={`${styles.wishDiv} mx-4 d-flex align-items-center`}>
                    <img src={item?.cardInfo?.image?.thumbnail || item?.cardInfo?.image?.front} alt="card" onClick={() => onClickHandler(item?.listingInfo?.listingId)} className={styles.card_img} />
                    <div className={styles.card_content}>
                      <div className={styles.card_title}>{item?.cardInfo?.title}</div>
                      <div className={styles.card_dec}>
  {item?.cardInfo?.description?.length<=120 ? item?.cardInfo?.description : `${item?.cardInfo?.description.slice(0, 120)}...`}

                      </div>
                      <div className={`${styles.wishDiv} d-flex align-items-center`}>
                        <div className={styles.card_price}>{`${usaPriceformat(item?.listingInfo?.marketplace?.price)}`}</div>
                       {item?.listingInfo?.status === "active" && <div className="mx-4 statusbtn instockbtn">In Stock</div>}
                    {item?.listingInfo?.status === "sold" && <div className="mx-4 statusbtn insoldbtn">Sold</div>}
                    {item?.listingInfo?.status === "delisted" && <div className="mx-4 statusbtn Delistedbtn">Delisted</div>}
                        </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5} md={12} className="d-flex flex-wrap justify-content-end">
                <ButtonIcon
                  iconPath={cartIcon}
                  className={styles.cartBtn}
                  text="Add to Cart"
                  clickHandler={() => addToCart(item?.listingInfo?.listingId)}
                  isButtonDisable={item?.listingInfo?.status !== "active" || item?.isInCart}
                />
                <ButtonIcon
                  iconPath={""}
                  text="Remove"
                  className={styles.button}
                  clickHandler={() => addRemoveToWishList(item?.listingInfo?.listingId)}
                />
              </Col>
            </Row>
          ))}
           {sortData?.length > 0 && <div className="mt-4">
         <Paginations
           currentActive={currentActivePage}
           totalPage={totalPages}
           PageHandler={PageHandler}
           previousHandler={previousHandler}
           nextHandler={nextHandler}
           disabledPrev={disabledPrev}
           disabledNext={disabledNext}
         />
       </div>}
          <div className="mt-3 d-flex align-items-center flex-wrap">
            <ButtonIcon
              iconPath=""
              text="Add Selected to Cart"
              clickHandler={addBulkCartItem}
               className={styles.cartBtn}
            />
            <ButtonIcon
              iconPath=""
              text="Remove Selected from Wishlist"
              clickHandler={removeBulkWishlist}
              className={styles.button}
            />
          </div>
        </> : <h6 className="d-block text-center">{!isLoading ? <Loader /> : <>
            <img src={emptyWishlist} className="emptyImg my-4" alt='empty-wishlist' />
            <p>Uh-oh! It looks like your wishlist is feeling a little empty. 😕</p>
            </>
        }</h6>}
      </div>
      
    </ProfileContainer>
  );
};

export default Wishlist;
