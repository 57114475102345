import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import styles from "./OTPVerify.module.css";
import backgroundImage from "../../asset/loginPageBg.png";
import fantasyCards from "../../asset/FantasyCards.png";
import OTPResendTimer from "../OTPResend/OTPResendTimer";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import otpSuccess from "../../asset/otpSuccess.png";
import { toast } from "react-toastify";

const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state && location.state.email;
  const emailyVerIfy = location?.state?.emailyVerIfy;
  const forgotPassword = location.state && location.state.forgotPassword;
  const [otp, setOTP] = useState("");
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [error, setError] = useState(false);

  // const handleOTPChange = (event) => {
  //   const inputOTP = event.target.value;
  //   setOTP(inputOTP);

  //   if (inputOTP.length === 6 && /^\d+$/.test(inputOTP)) {
  //     setIsOTPVerified(true);
  //   } else {
  //     setIsOTPVerified(false);
  //   }
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const verifyEmail = () => {
    // Perform API call here to sign up the user
    let data = JSON.stringify({
      email: email,
      otp: otp,
    });

    let config = {
      method: "post",
      url: `verifyOTP`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Make the API request to register the user
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setIsOTPVerified(true);
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data.message || "Something went wrong try again later"
        );
      });
  };

  const resendOtp = () => {
    const userData = {
      email,
    };
    axios
      .post(`/sendOTP`, userData)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success("Please Check your Email for OTP");
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    emailyVerIfy==true && resendOtp()
  },[emailyVerIfy])

  const handleInputChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 6);
    setOTP(truncatedValue);
  };

  return (
    <div className="container-fluid">
      <div className="background-image">
        <img
          src={backgroundImage}
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
      </div>
      <Container className="d-flex justify-content-center align-items-center vheight">
        <div
          className="bg-white rounded"
        
        >
          <h2 className="mb-4 text-center">
            <img src={fantasyCards} alt="logo" />
          </h2>
          {isOTPVerified ? (
            <div>
              <div>
                {" "}
                <h2 className="mb-4 text-center">
                  <img src={otpSuccess} alt="success" />
                </h2>
              </div>
              <div className="mb-5">
                You have been registered successfully. <br />
                Now you can manage your account by signing in.
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  variant="primary"
                  type="submit"
                  className={styles.submit}
                  disabled={btnDisabled}
                  onClick={() => navigate("/login", { replace: true })}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.signinText}>OTP VERIFICATION</div>
              <div style={{ padding: "15px 0", fontSize: "12px" }}>
                Please enter your one time password to verify your email address
              </div>
              <Form.Group controlId="text" style={{ marginTop: "10px" }}>
                <Form.Label>Enter 6 digits code</Form.Label>
                <Form.Control
                  type="text"
                  value={otp}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <div className={styles.otpText}>
                We’ve sent a six digit code to verify your email id. Please
                check your inbox or spam (sometimes).
              </div>
              {error && (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  Incorrect verification code
                </div>
              )}
              <OTPResendTimer resendOtp={() => resendOtp()} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  variant="primary"
                  type="submit"
                  className={styles.submit}
                  onClick={verifyEmail}
                  disabled={otp.length != 6}
                >
                  CONTINUE
                </button>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default OtpVerification;
