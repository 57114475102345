import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { BiSearch } from 'react-icons/bi';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import { SearchContext } from '../../Context/SearchContextProvider';
import { CategoryContext } from "../../Context/CategoryContext";
import GuestLogin from "../Login/GuestLogin";
import profile from "../../asset/profile/profile.svg";
import wishlist from "../../asset/profile/wishlist.svg";
import transaction from "../../asset/profile/transaction.svg";
import soldItem from "../../asset/profile/sold-item.svg";
import vault from "../../asset/profile/vault.svg";
import Logout from "../../asset/profile/Logout.svg";
import wallet from "../../asset/profile/wallet.svg";
import shopingCart from "../../asset/profile/shopingCart.svg";
import styles from './Navbar.module.css';

function NavBarModal({ show, toggle }) {
    const [logged, setLogged] = useState(null);
    const navigate = useNavigate();
    const [showGuest, setShowGuest] = useState(false);
    const toggleGuest = () => setShowGuest(!showGuest);
    const [guestLogin, setGuestLogin] = useState();
    const [loggingOut, setLoggingOut] = useState(false);
    const { setCatSelectedTab } = useContext(CategoryContext);
    const { searchKey, setSearchKey, searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const location = useLocation();

    const sidebarData = [
        { name: "Personal Info", url: "/profile", icon: profile },
        { name: "Address", url: "/address", icon: "/Images/address-location.svg" },
        { name: "Wishlist", url: "/wishlist", icon: wishlist },
        { name: "Cart", url: "/cart", icon: shopingCart },
        { name: "List Card", url: "/list-card", icon: "/Images/list-check-black.svg" },
        { name: "My Orders", url: "/orders", icon: transaction },
        { name: "Sold Items", url: "/sold-items", icon: soldItem },
        { name: "My Vault", url: "/vault", icon: vault },
        { name: "Earnings", url: "/earnings", icon: wallet },
        { name: "Logout", icon: Logout },
    ];

    const logOutHandler = () => {
        if (loggingOut) return;
        setLoggingOut(true);
        axios.get('/logout', { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                if (response?.data?.statusCode === 200) {
                    toast.success("Logout successful!");
                    localStorage.clear();
                    setLogged(Math.random());
                    navigate("/login");
                } else {
                    toast.error(response?.data?.message || "Something went wrong try again later.");
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoggingOut(false));
    };

    const handleSearch = (e, condition) => {
        if (searchKey && (e?.key === "Enter" || e?.keyCode === 13 || condition)) {
            setSearchKeyword(searchKey);
            navigate("/search");
        }
    };

    const resetSearchOnPageChange = () => {
        setSearchKey('');
        setCatSelectedTab({ name: '', id: '' });
    };

    useEffect(() => {
        resetSearchOnPageChange();
    }, [location]);

    useEffect(() => {
        if (logged) navigate("/login");
    }, [logged, navigate]);

    useEffect(() => {
        const gLogin = localStorage.getItem('guestLogin');
        setGuestLogin(gLogin);
    }, []);

    return (
        <Modal show={show} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`d-flex align-items-center offset-md-4 sortBy ${styles.searchInputGroup}`}>
                    <div className={`input-group`}>
                        <Form.Control
                            className={`rounded-pill ${styles.searchForm}`}
                            type="search"
                            placeholder="Search for cards, categories..."
                            value={searchKey}
                            maxLength={30}
                            onChange={(e) => setSearchKey(e.target.value)}
                            onKeyDown={handleSearch}
                        />
                        <div className="input-group-append">
                            <span className={`input-group-text bg-transparent border-0 ${styles.icon} ${styles.searchIcon}`}>
                                <BiSearch onClick={() => handleSearch('', true)} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="container mb-5 mt-4">
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <div className="sidebar-container">
                                {sidebarData.map((value, id) => (
                                    <div key={id} className={location.pathname === value.url ? "Active" : "unActive"}>
                                        {(guestLogin === "true" || guestLogin == null) ? (
                                            <span onClick={() => setShowGuest(!showGuest)}>
                                                <img src={value.icon} alt={value.name} />
                                                {value.name}
                                            </span>
                                        ) : (
                                            <Link key={id.toString()} to={value.url} relative="path" onClick={toggle}>
                                                <img src={value.icon} alt={value.name} />
                                                {value.url ? (
                                                    <span>{value.name}</span>
                                                ) : (
                                                    <span onClick={logOutHandler}>
                                                        {value.name}
                                                    </span>
                                                )}
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {(guestLogin == "true" || guestLogin == null) && (
                    <GuestLogin show={showGuest} toggle={toggleGuest} />
                )}
            </Modal.Body>
        </Modal>
    );
}

export default NavBarModal;
