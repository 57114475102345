import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import {BiStopwatch} from 'react-icons/bi'
import "./BidModal.jsx"
import {TbRulerMeasure} from 'react-icons/tb'
import SkeletonImg from '../../components/SkeletonImage/SkeletonImg.js'
import "./BidModal.css";

function BidModal({ show, toggle, handleSubmit, isType, cardData}) {
   const [imageLoaded, setImageLoaded] = useState(false);
  let [data, setData] = useState({price: '' , quantity: isType === 'marketplace' ? 1 : '', reserve:'' });
  const {cardId, title, desc, cardImg, qty, listedQty} = cardData
  const [errors, setErrors] = useState({ price: "", quantity: "", reserve: "" });
  const [showMore, setShowMore] = useState(false)


  const netQty = Math.abs(qty-(listedQty?.marketplace + listedQty?.auction))

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const truncatedDesc =  showMore || desc.length <= 150 ? desc : `${desc.slice(0, 150)}...`

  const handlePriceKeyDown = (e) => {
    if (e.key === '-' || e.key === 'e' || e.key === '+' || (e.key === '0' && e.target.value === '0')) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    var value = e.target.value;
    if (value > 1000000) {
      return;
    }
    if (name === 'price' || name === "reserve") {
    value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    const regex = /^\d*\.?\d{0,2}$/;
    value = regex.test(value) ? value : value.slice(0, -1);
  }

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateInput = () => {
    const newErrors = {};

    const price = parseFloat(data.price);
    const quantity = parseInt(data.quantity, 10);
    const reserve = parseFloat(data.reserve);

    if (isNaN(price) || price < 1) {
      newErrors.price = '*Price should be greater than 1.';
    }
  
    if (isType === 'marketplace') {
      if (isNaN(quantity) || quantity < 1 || quantity > netQty) {
        newErrors.quantity = '*Enter a valid quantity within the available range.';
      }
    } else if (isType === 'auction') {
      if (isNaN(reserve) || reserve < price) {
        newErrors.reserve = '*The reserve limit must be greater than or equal to the minimum bid.';
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmission = () => {
    if (validateInput()) {
      handleSubmit(cardId, data, setData, isType);
      toggle();
    }
  };

  useEffect(() => {
    if (!show) {
      setData({ price: '', quantity: isType === 'marketplace' ? 1 : '', reserve: '' });
      setErrors({ price: "", quantity: "", reserve: "" });
      if (isType === 'auction') {
        setData((prevData) => ({
          ...prevData,
          quantity: 1,
        }));
      }
    }
  }, [show, isType]);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = cardImg;

  }, [cardImg]);
  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        dialogClassName="bid-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isType === 'marketplace'?'Add to Marketplace':'Add to Auction'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between BidMainCardSection">
          <div className="img-container">
        <SkeletonImg className={`skeleton-img ${imageLoaded ? 'hidden' : ''}`} isVisible={!imageLoaded}/>
              {imageLoaded && (<img
                src={cardImg}
                alt={title}
                className={'cardImg card-img cat-img'}
              />)}
          </div>
            {/* <img src={cardImg} className="cardImg"  alt = {title}/> */}
           <div className="mx-5 BidSection w-100">
              <div className="BidCardSection">
                <div className="d-flex justify-content-between">
                  <h3>{title}</h3>
                  {/* <img src={EditIcon}></img> */}
                </div>
                <div>
                {truncatedDesc}
                  {!showMore && desc.length > 150 && (
                  <span
                    className="show-more"
                    onClick={toggleShowMore}
                  >{`show more`}</span>
                  )}
                  {showMore && (
                  <span
                    className="show-less"
                    onClick={toggleShowMore}
                  >{`show less`} </span>
                )}
                </div>
                <br />
                <div>
                  Available Quantity : {netQty}
                </div>
              </div>
              <div className="my-3 bidAmount">
                <input
                  type='number'
                  className={`form-control ${isType ==='marketplace'? 'w-80': 'w-50'}`}
                  value={data.price}
                  onChange={handleChange}
                  onKeyDown={handlePriceKeyDown}
                  name='price'
                  placeholder={isType ==='marketplace'?"$ Enter the amount": "$ Enter the Min Bid"}
                  min =  {1}
                />
                {isType === 'marketplace' && <>
                &nbsp;
                <input
                  type='number'
                  className={`form-control w-25`}
                  value={data.quantity}
                  onChange={handleChange}
                  onKeyDown={handlePriceKeyDown}
                  name='quantity'
                  placeholder="Quantity"
                  min={1}
                />
                </>}
                 {isType ==='auction' && <span className={`form-control w-25 bid-time`}>
                    <BiStopwatch color ='#9547cf'/> 15 Days
                  </span>}
              </div>
              {isType ==='auction' && 
              <div className="my-3 bidAmount">
               <input
                  type='number'
                  className={`form-control w-50`}
                  value={data.reserve}
                  onChange={handleChange}
                  onKeyDown={handlePriceKeyDown}
                  name='reserve'
                  placeholder="$ Reserve Limit"
                  min = {1}
                />
                {isType === 'auction' && (
                    <span className={`form-control w-25 bid-time`}>
                    <TbRulerMeasure color ='#9547cf'/> 1 Quantity
                  </span>
                )}
            </div>
            }
            {errors && <div className="error-message">{errors.price}</div>}
            {errors && <div className="error-message">{errors.quantity}</div>}
            {errors && <div className="error-message">{errors.reserve}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bidModal-footer">
          <button className="cancel_btn mx-2" onClick={toggle}>
            Cancel
          </button>
          <ButtonIcon
            text={isType === 'marketplace'?"Publish on Marketplace":"Publish for Auction"}
            clickHandler={handleSubmission}
            className="submitBid"
            // isButtonDisable={Object.keys(errors).length > 0}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BidModal;
