import React, { useContext, useEffect, useRef, useState } from "react";
import { Breadcrumb, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ".././Categories/category.css";
import SideTitle from "../../components/CommonTitle/SideTitle/Index";
import CommonTitle from "../../components/CommonTitle/Index";
import axios from "../../utils/axiosConfig";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import "./CardDetails.css";
import imgUrl from "../../asset/card-details-images/1.svg";
import shareIcon from "../../asset/shareIcon.svg"
import timer from "../../asset/timer.svg";
import tooltip from "../../asset/tooltip-logo.svg";
import heartBtn from "../../asset/heartBtn.svg";
import heartFillBtn from "../../asset/heartFillBtn.svg";
import ButtonDetail from "../../components/ButtonWithIcon/ButtonDetail";
import InfoTabs from "./CardDetailsTabs";
import IncDecCounter from "./IncDecCounter";
import CardCarousel from "./CardCarousel";
import BidModal from "./BidModal";
import { useParams } from "react-router-dom";
import RelatedCarousel from "../../Containers/Carousel/RelatedCarousel/RelatedCarousel.js";
import { toast } from "react-toastify";
import { CartContext } from "../../Context/CartItemContextProvider";
import Loader from '../../components/Loader/Loader'
import { getTimeRemaining, usaPriceformat } from "../../utils/commonFunction";
import { ProfileContext } from "../../Context/ProfileContextProvider";
import GuestLogin from "../Login/GuestLogin";
import ServiceList from "../../components/ServiceList/ServiceList.js";
export default function CardDetails() {
  const { listingId } = useParams(); 
  const { setItemCounter, getCartItemDetail } = useContext(CartContext);
  const { profilInfo} = useContext(ProfileContext);
  const [isItemUpdate, setIsItemUpdate] = useState(false)
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [cardDetail, setCardDetail] = useState([]);
  const [images, setImages] = useState([])
  const [cardtype, setCardType] = useState()
  const [relatedCards, setRelatedCards] = useState([]);
  const [relatedTitle, setRelatedTitle] = useState('')
  const [itemCount, setItemCount] = useState(1);
  const navigate = useNavigate();
  const scrollToRef = useRef();
  const [bidTimer, setBidTimer] = useState(null);
   const [guestLogin, setGuestLogin] = useState()
  const [showGuest, setShowGuest] = useState(false);
  const toggleGuest = () => setShowGuest(!showGuest);
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(bidTimer));
  const [remountCarousel, setRemountCarousel] = useState(false);
  const [userValid, setUserValid] = useState(false);
  const [heartBtns, setHeartBtns] = useState();
  const [pincode, setPincode] = useState(false);
  const [serviceType, setServiceType] = useState(false);
  const [shippingCost, setShippingCost] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
   const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };
  const getCardDetails = (listingId) => {
    const url = `listing/${listingId}`;
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const cardfetched = response?.data?.data?.listing;
          if(cardfetched!==undefined){
  
            setCardDetail(cardfetched)
            setHeartBtns(cardfetched?.isInWishlist)
            setCardType(cardfetched?.type)
          setImages(cardfetched.cardInfo.image)
          getRelatedCards(cardfetched.type, cardfetched.cardInfo.categoryId)
          setRelatedTitle(cardfetched.categoryInfo.name)
          setBidTimer(cardfetched?.auction?.endDate)
        }}
      })
      .catch((error) => {
      });
  };
  const getShipmentCharges = (pincode) => {
    if (pincode?.length >= 5) {
      let data = JSON.stringify({
        listingId: listingId,
        recipientPostalCode: pincode,
        serviceType: serviceType?.toUpperCase()
      });
      setIsLoading(true);
      const url = `shipment/cost`;
      let config = {
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            setShippingCost(response?.data?.data?.shipmentCost)
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const addToCart = () => {
    if (guestLogin == "true" || guestLogin==null) {
      setShowGuest(!showGuest);
    } else {
      let data = JSON.stringify({
        listingId: listingId,
        qty: itemCount,
      });
      let config = {
        method: "post",
        url: `cartItem`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
            setIsItemUpdate(true)
            getCardDetails(listingId)
            // navigate("/cart");
          } else {
            toast.error(response?.data?.message)
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message)
        });
    }
  }
  const addRemoveToWishList = () => {
    if (guestLogin == "true" || guestLogin==null) {
      setShowGuest(!showGuest);
    } else {
      setHeartBtns(value => !value);
      let data = JSON.stringify({
        listingId: listingId,
      });
      let config = {
        method: "post",
        url: `addRemoveWishlist`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
            getCardDetails(listingId)
         
          }
        })
        .catch((error) => {
        });
    }
}
    const getRelatedCards = () => {
      const url = `relatedListing/${listingId}`;
      let config = {
        method: "get",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            const relatedCard = response?.data.data.relatedListings
            setRelatedCards(relatedCard);
          }
        })
        .catch((error) => {
        });
  };
  
  const copyUrl = async () => {
    await navigator.clipboard.writeText(window.location.href);
   toast.success("URL Copied")
  }
  useEffect(() => { 
     window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Card Detail Page',
      });
  }, []);
    
    useEffect(() => { 
      getCardDetails(listingId)
      setRemountCarousel(prevValue => !prevValue);
      setItemCount(1) 
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [listingId]);
  useEffect(() => {
        guestLogin=="false" && getCartItemDetail().then((res) => {
          setItemCounter(res?.data?.data)
      });
  }, [isItemUpdate])


  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(bidTimer));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [bidTimer]);
useEffect(() => {
    if (cardDetail?.ownerId === profilInfo?.userId) {
      setUserValid(true)
    } else {
      setUserValid(false)
    }
}, [cardDetail, profilInfo, listingId])

  useEffect(() => {
    const gLogin = localStorage.getItem('guestLogin');
    setGuestLogin(gLogin);
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      {Object.keys(cardDetail).length > 0 ? <div>
        <Container fluid>
          <Row className="breadcrumbBg">
            <Col>
              <div className="container">
                <Breadcrumb className="Breadcrumb-container" aria-label="breadcrumb">
                  <Breadcrumb.Item
                    onClick={() => {
                      navigate("/dashboard", { replace: true });
                    }}
                  >
                    Home
                  </Breadcrumb.Item>
                  {cardDetail.cardInfo && (
                    <>
                      <span className="breadcrumb-arrow">{'>'}</span>
                      <Breadcrumb.Item
                        onClick={() => {
                          navigate(`/category/${relatedTitle}`, { replace: true });
                        }}
                      >
                        {relatedTitle}
                      </Breadcrumb.Item>
                      <span className="breadcrumb-arrow">{'>'}</span>
                      <Breadcrumb.Item>{cardDetail.cardInfo.title}</Breadcrumb.Item>
                    </>
                  )}
                </Breadcrumb>

              </div>
            </Col>
          </Row>
        </Container>
        <div className="container">
          {cardDetail.cardInfo && (
            <div className="m-auto">
              <SideTitle title={cardDetail.categoryInfo.name} subTitle={cardDetail.cardInfo.set} />
              <section className="d-flex my-5 mainCardSection">
                <div className="imagesSlider">
                  <CardCarousel key={remountCarousel ? 'remount' : 'normal'} images={images} />
                </div>

                <div className="align-items-center w-75 cardContent">
                  {cardtype==="auction" && (
                    <>
                      <div className="d-flex align-items-center my-2">
                        <img src={timer} alt='timer-icon'/>

                        {timeRemaining !="0:00:00:00" ?
                          <div className="mx-2">{`${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`}</div> :
                          <div className="mx-2">{timeRemaining}</div>
                        }
                      </div>
                    </>
                  )}
                  <div className="d-flex align-items-center flex-wrap statusBlock">
                    <h2 className="cardetail-title">{cardDetail.cardInfo.title}</h2>
                    {cardDetail?.status === "active" && <div className="mx-4 statusbtn instockbtn">In Stock</div>}
                    {cardDetail?.status === "sold" && <div className="mx-4 statusbtn insoldbtn">Sold</div>}
                    {cardDetail?.status === "delisted" && <div className="mx-4 statusbtn Delistedbtn">Delisted</div>}
                  </div>
                  <div className="d-flex align-items-center">
                    {cardDetail?.rank && <><div className="d-flex trendingCardSection">
                      <img src={imgUrl} alt="card" />
                      <p className="trendingCardTxt px-3">
                        This card is Trending on #{cardDetail?.rank}
                      </p>
                    </div></>}
                  </div>
                  <p>
                    {cardDetail.cardInfo.description.split(' ').slice(0, 40).join(' ')}
                    {cardDetail?.cardInfo?.description?.length > 40 && <>
                      ... <Link to="" onClick={() => scrollToRef.current.scrollIntoView()}>See more</Link>
                    </>}

                  </p>
                  {cardtype !== "auction" ? (
                    <>
                      <p className="cardetailprice my-3">{usaPriceformat(cardDetail?.marketplace?.price)}</p>
                      
                      </>
                  ) : (
                    <div className="aunctionCard">
                      {cardDetail?.auction?.reserveLimit && <p>Reserve limit for the bid is {usaPriceformat(cardDetail?.auction?.reserveLimit)}</p>}
                      {cardDetail?.auction?.minBid>0 && < div className="my-2">
                      <span className="Bidtxt">Min Bid </span>
                      {cardDetail?.auction?.minBid && <span className="minBidPrice">{usaPriceformat(cardDetail?.auction?.minBid)}</span>}
                    </div> }
                        {cardDetail?.auction?.currentBid>0 && <div className="my-2">
                          <span className="Bidtxt">Current Bid </span>
                          <span className="curreBidPrice">{usaPriceformat(cardDetail?.auction?.currentBid)}</span>
                        </div>}
                    </div>
                  )
                  }
                  {cardDetail?.cardInfo?.type && <div className="my-2">
                          <span className="Bidtxt">Type : </span>
                          <span className="curreBidPrice">{cardDetail?.cardInfo?.type}</span>
                  </div>}
                   <span className="Bidtxt">Seller : </span>
                  <span className="curreBidPrice">{cardDetail?.ownerName}</span>
                  {(cardDetail.qty?.available >= 1 && cardtype !== "auction") && <IncDecCounter qty={cardDetail.qty} setItemCount={setItemCount} itemCount={itemCount} />}
                 {(cardDetail?.cardInfo?.type==="physical" && cardDetail?.status === "active") && <div className="align-items-baseline d-flex flex-wrap cardPage">
                      {!shippingCost && <p className="tooltip-icon" >
                     <img src={tooltip} className="shippmentEye"   onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip} alt='tooltip-icon'/>
                    </p>}
                    <ServiceList setServiceType={setServiceType} shippingCost={shippingCost} />
                    <div className="align-items-center d-flex flex-wrap">
                       <Form.Group className="shipBtn">
                        <Form.Control
                          type="text"
                          className="pinInput pincheckBtn"
                          maxLength={5}
                          minLength={5}
                          placeholder="Zip Code"
                          onInput={(e) => {
                            const numericInput = e.target.value.replace(/\D/g, ''); 
                            e.target.value = numericInput; 
                            setPincode(numericInput); 
                          }}
                          
                        />
                      </Form.Group>
                      <button disabled={!pincode || pincode==="00000" || pincode?.length<=4 || !serviceType || userValid==true} className="pinCheck" onClick={()=>
                        getShipmentCharges(pincode)
                      }>
                       {isLoading ? "Checking Shipping Cost..." : "Check Shipping Cost"} 
                      </button>
                    </div>
                  
                    {/* <div className="businessTxt">3-5 business days</div> */}
                  </div> }
                    {isTooltipVisible && (
                  <>
                    <div className="tooltipCard shipcost">
                     To estimate shipping costs, please select both the shipment method and enter your valid zip code.
                    </div>
                  </>
                )}
                 
                  {cardtype !== "auction" ? (
                    <div className="buttonCenter">
                      <div className="d-flex mb-2 mt-4 flex-wrap align-items-center">
                        {/* <img src={heartBtn} className="heartBtn" /> */}
                       <ButtonDetail
                           iconPath={heartBtns ? heartFillBtn : heartBtn}
                          text=""
                          className="heartIcon"
                          isButtonDisable={cardDetail.qty?.available < 1 || userValid==true}
                          clickHandler={() => addRemoveToWishList()}
                        />
                        <ButtonIcon
                          text="Buy Now"
                          className="buynow"
                          isButtonDisable={cardDetail.qty?.available < 1 || userValid==true}
                          clickHandler={() => {
                            if (guestLogin=="false") {
                              navigate('/checkout', {
                                state: {
                                  qty: itemCount, listingId: listingId,
                                  cardTypeforPay: 'buynow',
                                 cardDetailType: cardDetail?.cardInfo?.type
                                     
                                }
                              })
                            } else {
                          setShowGuest(!showGuest);
                        }
                          }
                          }
                        />
                        <ButtonDetail
                          className="mx-4 addcardBtn"
                          text="Add to Cart"
                          clickHandler={() => addToCart()}
                          isButtonDisable={cardDetail.qty?.available < 1 || userValid==true || cardDetail?.isInCart}
                          />
                         
                        <div className="shareBtn" onClick={copyUrl}>
                          <img src={shareIcon} alt='sharing-icon' />
                          </div>
                      </div>
                    </div>
                  ) : (
                   (timeRemaining !="0:00:00:00"  ) && <>
                      <div className="buttonCenter">
                        <div className="d-flex my-3 flex-wrap">
                          <ButtonIcon
                            className="buynow"
                              text="Place a bid"
                              isButtonDisable={userValid==true}
                              clickHandler={() => {
                               if (guestLogin=="false") {
                                setShow(!show);
                            } else {
                          setShowGuest(!showGuest);
                          
                        }
                            }}
                          />
                        </div>
                      </div>
                        <BidModal
                          show={show}
                          toggle={toggle}
                          cardDetail={cardDetail}
                          details={() => getCardDetails(listingId)}
                        />
                    </>
                  )}
                  <div className="subTextSection d-flex">
                    <p className="tooltip-icon">
                      <img src={tooltip} className="mx-2" alt='tooltip-icon'/>
                    </p>
                    <p className="subText">
                      Seller does not accept returns. If the item you received doesn't
                      match the listing description, your purchase may be eligible for
                      Money Back Guarantee if the return request is made within 3 days
                      from delivery. <Link to="" onClick={() => scrollToRef.current.scrollIntoView()}>See details</Link>
                    </p>
                  </div>
                </div>
              </section>

              <InfoTabs currentRef={scrollToRef} cardInfo={cardDetail?.cardInfo} packageInfo={cardDetail?.package} />
            </div>)
          }
        </div>
        {relatedCards?.length>0 && < div className='container relatedCardSection'>
        <div className="my-5">
          {relatedCards !== undefined && <CommonTitle
            title={`${relatedTitle}`}
            subTitle={`Related Cards of ${relatedTitle}`}
            textClr="#000"
          />}
        </div>
        <div className="row my-3">
          {relatedCards !== undefined && <RelatedCarousel Cards={relatedCards} />}
        </div>
     
        </div>}
         {(guestLogin=="true" || guestLogin==null) && <GuestLogin
                show={showGuest}
              toggle={toggleGuest} />
            }
      </div> :<Loader/> }
      {/* <Footer /> */}
    </>
  );
}
