import React, { useState, useEffect} from "react";
import {Row, Col } from "react-bootstrap";
import styles from "./vault.module.css";
import Pagination from "../../components/Pagination/Paginations";
import ProductCard from "../../components/ProductCard/Profile/Index";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
// import { useNavigate } from 'react-router-dom'
import marketplaceImg from "../../asset/vault-page/marketplaceImg.svg"
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon'



const ListedForSale = ({setActiveTab}) => {
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(true)
  const [marketData, setMarketData] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const [sortCriteria, setSortCriteria] = useState('date');
  const [sortOrder, setSortOrder] = useState(1);
  // const navigate = useNavigate()

  

  const handleSort = (event) => {
    const selectedValue = event.target.value;
    const selectedOrder = event.target.options[event.target.selectedIndex].getAttribute('data-order');
      setSortCriteria(selectedValue);
      setSortOrder(parseInt(selectedOrder, 10));
    };
  

  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => (currentActivePage -= 1));
    }
  };

  const nextHandler = () => {
    setCurrentActivePage((currentActivePage) => (currentActivePage += 1));
  };

  const fetchMarketplaceData = (currentPage, sortCriteria, sortOrder) => {
    const url = `listed?page=${currentPage}&type=marketplace&sort=${sortCriteria}&order=${sortOrder}`;
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setMarketData(response?.data?.data?.listedSale);
          setTotalPages(response?.data?.data?.totalPages)
          setIsLoading(false);
        } 
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
        setIsLoading(false);
      });
  };

  const handleClick = (listingId) => {
  // let Confirmtext = "Are you really want to remove this card from marketplace?";
  //   if (window.confirm(Confirmtext) != true) {
  //     return;
  //   }
    axios
    .delete(`/listing/${listingId}`)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        fetchMarketplaceData(currentActivePage,sortCriteria, sortOrder)
      } else {
        toast.error("Failed to remove card");
      }
    })
    .catch((error) => {
      toast.error("Error removing card:", error);
    });
  }

  useEffect(()=>{
    fetchMarketplaceData(currentActivePage,sortCriteria, sortOrder)
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }
  
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  },[currentActivePage,sortCriteria,sortOrder])

  useEffect(() => {
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, totalPages]);
  
  return (
    <>
      <div className="commonsection">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className={styles.title}>Marketplace</h3>
        <div className="d-flex align-items-center flex-wrap">
          <span className="mx-2 sortBy">Sort by</span>
          <select
      className="sortingdropdown"
      onChange={(e) => handleSort(e)}
      // value={sortCriteria}
    >
      <option value="date" data-order="1">Newest to Oldest</option> 
      <option value="date" data-order="-1">Oldest to Newest</option> 
      <option value="title" data-order="1">Name - A-Z</option>
      <option value="title" data-order="-1">Name - Z-A</option>
      <option value="price" data-order="1">Price - Low to High</option>
      <option value="price" data-order="-1">Price - High to Low</option>
    </select>
        </div>
      </div>
      {isLoading ? <Loader /> : (
        <>
      <Row className={styles.card_Container}>
      { marketData.length === 0 ? 
          <div className={styles.emptyVault}>
            <div className='d-flex align-items-center flex-column py-5 gap-3'>
              <img src={marketplaceImg} />
              <h2 className="emptyheading">Turn Your Collection into Currency,<br/>Bring Your Cards to the Marketplace!</h2>
              <p className="emptysubheading">Click the button to list your cards.</p>
              <ButtonIcon text={`My Vault`} className={styles.confirmBtn} clickHandler={() =>setActiveTab(1)} /> 
            </div>
          </div>
          : 
          marketData.length>0 && marketData.map((val, idx) => {
          const {marketplace, cardInfo, listingId} = val
          return(
          <Col lg={6} md={12} key={idx}>
            <ProductCard
              title={cardInfo?.title}
              description={`${cardInfo?.description.length> 18 ? cardInfo?.description.split(' ').slice(0, 18).join(' ')+"...":cardInfo?.description }`}
              imgUrl={cardInfo?.image?.thumbnail || cardInfo?.image?.front}
              price={marketplace?.price}
              marketPlacebtn={true}
              handleClick={()=>handleClick(listingId)}
              listingId={listingId}
              approvalStatus = {val?.approvalStatus}
            />
          </Col>
        )}
        )}
      </Row>
      {marketData.length> 0 && <div className="mt-4">
        <Pagination
          currentActive={currentActivePage}
          totalPage={totalPages}
          PageHandler={PageHandler}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          disabledPrev={disabledPrev}
          disabledNext={disabledNext}
        />
      </div>}
      </>
      )}
      </div>
      </>

  );

};

export default ListedForSale;
