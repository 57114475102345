import React from "react";
import { Input } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
export const BPasswordInput = ({
  visible,
  passwordEqual,
  isError,
  errorMsg,
  placeholder,
  onChange,
  value,
  className,
  ...props
}) => {
  return (
    <>
      {" "}
      <div style={{ margin: "5px" }}>
        {props?.previousSibling ? props?.previousSibling : <></>}{" "}
      </div>{" "}
      <Input.Password
        placeholder={placeholder}
        iconRender={(visible) =>
          passwordEqual ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : visible ? (
            <EyeTwoTone />
          ) : (
            <EyeInvisibleOutlined />
          )
        }
        onChange={(e) => onChange(e)}
        value={value}
        status={isError ? "error" : ""}
        className={className}
        onPressEnter={props.onPressEnter}
      />
      {isError ? (
        <div
          className="danger"
          style={{ color: "red", fontSize: "10px", padding: "0 5px" }}
        >
          {errorMsg}
        </div>
      ) : (
        <></>
      )}{" "}
      {props?.nextSibling ? props?.nextSibling : <></>}{" "}
    </>
  );
};
