import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Contact.css";
import { Button, Form } from "react-bootstrap";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export default function ContactForm() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let config = {
      method: "post",
      url: `contact`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // Make the API request to register the user
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
            reset()
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data.message || "Something went wrong try again later"
        );
      });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Contact Us Page',
      });
  },[])
  return (
    <>
      <h3 className="my-3 contactHead">Personal Information</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row g-3 formfields">
          <Form.Group className="col-md-6">
            <label>First Name</label>
            <Form.Control
              type="text"
              name="firstName"
              className="my-2"
              {...register("firstName", {
                required: "Please enter your first name.",
              })}
              placeholder="Maxwell"
            />
            <p className="error-message">{errors.firstName?.message}</p>
          </Form.Group>

          <Form.Group className="col-md-6">
            <label>Last Name</label>
            <Form.Control
              type="text"
              name="lastName"
              className="my-2"
              {...register("lastName", {
                required: "Please enter your last name.",
              })}
              placeholder="Cardsworth"
            />
            <p className="error-message">{errors.lastName?.message}</p>
          </Form.Group>

          <Form.Group className="col-md-6">
            <label>Email</label>
            <Form.Control
              type="text"
              name="email"
              className="my-2"
              {...register("email", {
                required: "Please enter your email.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please Enter A Valid Email!",
                },
              })}
              placeholder="Maxwell.Cardsworth@CollectThemAll.com"
            />
            <p className="error-message">{errors.email?.message}</p>
          </Form.Group>
          <Form.Group className="col-md-6 ">
            <label>Phone</label>
            <Form.Control
              type="tel"
              name="contactNumber"
              maxLength={12}
              minLength={6}
              className="my-2"
              {...register("contactNumber", {
                required: `Please enter your phone number.`,
                pattern: /^[0-9+-]+$/,
                minLength: 6,
                maxLength: 12,
              })}
              placeholder="+1  123-456-7890"
            />
            <p className="error-message">{errors.contactNumber?.message}</p>
          </Form.Group>
          <Form.Group className="col-md-12">
            <label>Comment </label>
            <Form.Control
              as="textarea"
              rows={3}
              name="comment"
              className="my-2"
              {...register("comment", {
                required: "Please enter your comment.",
              })}
              placeholder="Write Comment"
            />
            <p className="error-message">{errors.comment?.message}</p>
          </Form.Group>
        </div>
        <ButtonIcon
          text="Submit"
          className="contactSubmit"
          clickHandler={() => {
            navigate('/contact-us')
          }}
        />
      </Form>
    </>
  );
}
