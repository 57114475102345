import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import previous_icon from '../../../asset/icon/previous.svg' 
import next_icon from '../../../asset/icon/next.svg'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import './trendingcarousel.css';
import { FreeMode, Pagination,Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import heartFillBtn from "../../../asset/heartFillBtn.svg";
import GuestLogin from '../../Login/GuestLogin';
import { toast } from "react-toastify";
import { usaPriceformat } from '../../../utils/commonFunction';
export default function TrendingCarousel({ Cards }) {
    const trendingCards = Cards;
    const navigate = useNavigate()
    const [swiperRef, setSwiperRef] = useState(null);
    const [heartBtns, setHeartBtns] = useState(Array(trendingCards?.length).fill(false));
    const [guestLogin, setGuestLogin] = useState()
    const [showGuest, setShowGuest] = useState(false);
    const toggleGuest = () => setShowGuest(!showGuest);
    const onClickHandler = (listingId) =>{
      navigate(`/card-details/${listingId}`);
    }

    const prevHandler = () => {
      swiperRef.slidePrev();
    };
  
    const nextHandler = () => {
      swiperRef.slideNext();
  };
 const addRemoveToWishList = (index, listingId) => {
    if (guestLogin === "true" || guestLogin == null) {
      setShowGuest(!showGuest);
    } else {
       const updatedWishlistStatus = [...heartBtns];
      updatedWishlistStatus[index] = !updatedWishlistStatus[index];
      setHeartBtns(updatedWishlistStatus)

      let data = JSON.stringify({
        listingId: listingId,
      });
      let config = {
        method: "post",
        url: `addRemoveWishlist`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
          }
        })
        .catch((error) => {});
    }
  };
  
     useEffect(() => {
    const gLogin = localStorage.getItem('guestLogin');
       setGuestLogin(gLogin);
         if ( trendingCards?.length > 0) {
    const updatedHeartBtns = trendingCards.map(card => card.isFavourite);
    setHeartBtns(updatedHeartBtns);
  }
       
  }, []);
    return (
      <div className='slider-container trending-slider'> 
        {Cards?.length>1 && window.innerWidth < 640  ? (<button onClick={prevHandler} className="swiper-button swiper-btn-prev">
          <img src={previous_icon}  alt="next" />
        </button>) : Cards?.length>4 && window.innerWidth > 640 && (
            <button onClick={prevHandler} className="swiper-button swiper-btn-prev">
          <img src={previous_icon}  alt="next" />
        </button>
        )}
        <Swiper
        navigation={true}
        slidesPerView={4}
        spaceBetween={40}
        freeMode={true}
        // pagination={{
        //   clickable: true,
          // }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
             640: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
              1300: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
        }}
        modules={[FreeMode, Pagination, Navigation]}
        className="trendingCarousel"   
        onSwiper={(swiper) => setSwiperRef(swiper)}   
        >
          {Cards!== undefined && Cards.map((item, index) => {
             const {marketplace, cardInfo, listingId} = item
             if (!cardInfo || !marketplace || !listingId || !cardInfo.image || !cardInfo.image.front) {
              return null;
            }
            return(
            <SwiperSlide key={listingId}>
              <div className= "trendingCard" >
                <img src={cardInfo?.image?.thumbnail || cardInfo?.image?.front} alt={`Trending Card ${index}`} className="slider-image cursor-pointer" loading="lazy" onClick = {()=>onClickHandler(listingId)} />
                </div>
                <div className="align-items-center d-flex justify-content-between">
                <div className="card-title">{cardInfo.title}</div>
                  <div className="card-wishlist cursor-pointer" >
          <img className='whiteWishlist' src={heartBtns[index] ? heartFillBtn : "/Images/whiteWishlist.svg"}  onClick={()=>addRemoveToWishList(index,listingId)}/>
                  </div>
                  </div>
              <div className="card-price">{`Price: ${usaPriceformat(marketplace?.price)}`}</div>
            </SwiperSlide>
          )})}
        </Swiper>
        {Cards?.length>1 && window.innerWidth < 640  ? (<button onClick={nextHandler} className="swiper-button swiper-btn-next">
          <img src={next_icon} alt="prev" />
        </button>) : Cards?.length>4 && window.innerWidth > 640 && (
            <button onClick={nextHandler} className="swiper-button swiper-btn-next">
          <img src={next_icon} alt="prev" />
        </button>
        )
        }
         {(guestLogin=="true" || guestLogin==null) && <GuestLogin
                show={showGuest}
              toggle={toggleGuest} />
            }
      </ div>
      );
    }