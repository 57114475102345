import React, { useState } from "react";
import styles from "./CreateSellerPage.module.css";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import axios from "axios";
export default function CreateSellerPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
      const sellerAccount = () => {
     setIsLoading(true);
    const url = "sellerAccount";
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          window.open(response?.data?.data?.link?.url, "_blank");
        }
      })
      .catch((error) => {})
       .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <div className={styles.sellerContainer}>
        <div className="text-center">
          <img src="Images/sellerLogin.svg" />
          <h2 className={styles.sellerHeading}>Create Your Seller Account</h2>
          <p className={styles.sellerMsg}>
            You need to create your seller account to proceed with selling
            cards. Click button below to create your seller account.
          </p>
         <ButtonIcon
            iconPath=""
            text={isLoading ? "Loading..." : "Create Seller Account"}
            className={styles.sellerBtn}
            clickHandler={() => {
              sellerAccount()
            }}
          />
        </div>
      </div>
    </div>
  );
}
