import React from "react";
import './Paginations.css'

const Paginations = ({
  currentActive,
  totalPage,
  PageHandler,
  nextHandler,
  previousHandler,
  disabledPrev,
  disabledNext
}) => {
  return (
    <nav aria-label="Page navigation">
      <ul className={`pagination`}>
        <button
          className={`page-item page-link ${disabledPrev ? 'disabled' : ""}`}
          onClick={previousHandler}
          style={{
            color: "#304061",
          }}
        >
          Previous
        </button>
        {[...Array(totalPage).keys()].map((value, index) => {
          const pageIndex = index + 1;

          // Logic to show ellipsis if totalPage > 5
          if (totalPage > 5) {
            if (
              (index === 1 && currentActive > 3) ||
              (index === totalPage - 2 && currentActive < totalPage - 2)
            ) {
              return (
                <li key={index} className="page-item page-link" disabled>
                  ...
                </li>
              );
            } else if (
              index > 0 &&
              index < totalPage - 1 &&
              (index < currentActive - 2 || index > currentActive + 1)
            ) {
              return null; // Hide the page number
            }
          }

          return (
            <li
              className={`${
                currentActive === pageIndex ? "active active-page" : ""
              } page-item page-link`}
              style={{
                backgroundColor: currentActive === pageIndex ? "#9547cf" : "",
                borderColor: currentActive === pageIndex ? "#9547cf" : "",
                color: currentActive === pageIndex ? "#FFF" : "#304061",
              }}
              key={index}
              onClick={() => PageHandler(pageIndex)}
            >
              {pageIndex}
            </li>
          );
        })}
        <button
          className={`page-item page-link ${disabledNext ? 'disabled' : ""}`}
          onClick={nextHandler}
          style={{
            color: "#304061",
          }}
        >
          Next
        </button>
      </ul>
    </nav>
  );
};

export default Paginations;
