import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useEffect, useState } from "react";
import "./CardDetailsTabs.css";
function InfoTabs({ currentRef, cardInfo, packageInfo }) {
  const [key, setKey] = useState("description");

  return (
    <div className="customTabs" ref={currentRef}>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="tabStyle">
        <Tab eventKey="description" title="Description">
          {cardInfo?.description}
          {cardInfo?.type === "physical" && <div className="my-2" style={{ textTransform: "capitalize" }}>
            {cardInfo?.condition && < div> <b>Condition:</b> {cardInfo?.condition}</div>}
            {cardInfo?.originality && < div> <b>Print:</b> {cardInfo?.originality}</div>}
            {cardInfo?.manufacturedLocation && <div> <b>Country:</b> { cardInfo?.manufacturedLocation}</div>}
            { cardInfo?.year && <div><b>Year:</b> {cardInfo?.year}</div> }
          </div>}
        </Tab>
       {((cardInfo?.type === "digital" && cardInfo?.additionalInfo) || cardInfo?.type === "physical" ) && <Tab eventKey="moreinformation" title="Additional information">
          {cardInfo?.additionalInfo}
          {cardInfo?.type === "physical" && <div className="my-2" style={{ textTransform: "capitalize" }}>
           {packageInfo?.weight && <h6>Package Weight</h6> }
            {packageInfo?.weight?.ounces && <div><b>Ounce:</b> {packageInfo?.weight?.ounces}</div> }
            {packageInfo?.dimensions?.length && < div> <b>Length:</b> {packageInfo?.dimensions?.length}</div>}
            {packageInfo?.dimensions?.width && < div> <b>Width:</b> {packageInfo?.dimensions?.width}</div>}
            {packageInfo?.dimensions?.height && < div> <b>Depth:</b> { packageInfo?.dimensions?.height}</div> }
          </div>}
        </Tab> }
      </Tabs>
    </div>
  );
}

export default InfoTabs;
