import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useState } from "react";
import { convertDateIntoTimer, usaPriceformat } from "../../utils/commonFunction";
import SkeletonImg from '../../components/SkeletonImage/SkeletonImg.js'

function AllBidModal({ show, toggle, title, imgUrl, description, listingId }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showBidData, setShowBidData] = useState();
    const showAllBids = () => { 
    
       const url = `bids/${listingId}`;
    let config = {
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  };  
    axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        let temp = response?.data?.data?.bids.map(obj => {
          obj.convetedTime = convertDateIntoTimer(obj.createdAt)
          return obj
        })
        setShowBidData(temp)

      }
    })
      .catch((error) => {
    });
  };
   useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = imgUrl;

  }, [imgUrl]);
  useEffect(() => {
    showAllBids()
  }, [show])
  return (
    <Modal show={show} onHide={toggle} dialogClassName="bid-modal">
      <Modal.Header closeButton>
        <Modal.Title>Show all Bids</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between BidMainCardSection">
          <div className="BidSection my-3  w-100">
            <div className="BidCardSection d-flex ">
               <SkeletonImg className={`skeleton-img AuncardImg ${imageLoaded ? 'hidden' : ''}`} isVisible={!imageLoaded}/>
              {imageLoaded && <img src={imgUrl} alt="Card Image" className="AuncardImg" />}
              <div className="my-4 mx-4">
                <h3>{title}</h3>
                <div>
                 {description}
                </div>
              </div>
            </div>
            <div className="my-3">
              <h4>Most Recent Bids</h4>
              <table className="table BidCardSection auctionTable">
               
                <tbody>
                  {showBidData?.map((val, idx) => (
                    <tr key={idx}>
                      <td className="allbidsTable">{usaPriceformat(val?.price)}</td>
                      <td>{val?.convetedTime}</td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AllBidModal;
