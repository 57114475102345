import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonIcon from "../ButtonWithIcon/buttonIcon";

export function ListCardSuccessPopUp({ show, toggle, cardContent }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setImageLoaded(true);
    };
    image.src = "Images/check.svg";
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        dialogClassName="cbox-modal successPoPUP"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {imageLoaded && (
            <>
              <img
                src="Images/check.svg"
                className="my-4"
                alt="Check"
                title="Check"
              />
              <div>{cardContent}</div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
