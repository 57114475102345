import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import previous_icon from '../../../asset/icon/left-arrow.svg' 
import next_icon from '../../../asset/icon/right-arrow.svg'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './carousel.css';
import ButtonIcon from '../../../components/ButtonWithIcon/buttonIcon';

// import required modules
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
import {useNavigate} from 'react-router-dom';


export default function CarouselFC() {

  // const [heroData, setHeroData] = useState([]);
  const [swiperRef, setSwiperRef] = useState(null);
  const navigate =useNavigate()
const heroData = [
        {
          "title": "Explore Exclusive Sports Trading Cards",
          "buttonUrl": "/category/all-cards",
          "imageUrl": "/Images/homeBanner/slider1.svg"
        },
        {
          "title": "Explore Exclusive Pokemon Trading Cards",
          "buttonUrl": "/category/pokemon",
          "imageUrl": "/Images/homeBanner/slider2.svg"
        },
        {
          "title": "Explore Exclusive Baseball Trading Cards",
          "buttonUrl": "/category/baseball",
          "imageUrl": "/Images/homeBanner/slider3.svg"
        },
        {
          "title": "Explore Exclusive Basketball Trading Cards",
          "buttonUrl": "/category/basketball",
          "imageUrl": "/Images/homeBanner/slider4.svg"
        }
      ]

  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

//   const fetchData =()=>{ 
//     const url = 'banner'
//     let config = {
//     method: "get",
//     url: url,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };  
//     axios
//     .request(config)
//     .then((response) => {
//       if (response?.data?.statusCode === 200) {
//         const heroData = response?.data?.data?.banners
//         setHeroData(heroData)
//       }
//     })
//     .catch((error) => {
//     });
// };

// useEffect(() => {
//   fetchData();
// }, []);

return (
  <>
  <div className='slider-container home-slider' style={{ width: '100%'}}> 
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
         speed={1200}
        pagination={{
          clickable: true,
        }}
        navigation={true}
       touchStartPreventDefault={false}

        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper dashboard-carousel"
        onSwiper={(swiper) => setSwiperRef(swiper)} 
      >
        

        {heroData!== undefined && heroData?.map((item, index)=>{
          const {title, imageUrl, buttonUrl} = item

          return(
            <SwiperSlide key ={index}>
            <div className="swiper-zoom-container">
              <img src={imageUrl} alt = {title} className='slider-image' loading="lazy"/>
            </div>
            <div className = "hero-info explore">
              <h1 className="title">{title}</h1>
              {/* <p className="text">{item.description}</p> */}
              {/* item.buttonUrl */}
              <ButtonIcon text = 'Explore Now' isNotLoading='true' className="explore-btn" clickHandler={()=>navigate(buttonUrl)}/>
            </div>
          </SwiperSlide>
  
          )
        })}
    
      </Swiper>
  </div>
  <div className = 'slider-nav-icons'>
      <button onClick={prevHandler} className="swiper-btn swiper-btn-next">
        <img src={next_icon} alt="prev" />
      </button>
      <button onClick={nextHandler} className="swiper-btn swiper-btn-prev">
        <img src={previous_icon}  alt="next" />
      </button>
  </div>
  </>

  );
}
