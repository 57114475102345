import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./AddNewCard.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PaymentCardContext } from "../../Context/PaymentCardContext";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export function AddNewCard(props) {
  const stripe = useStripe();
  const elements = useElements();
  const { addCardAPI } = useContext(PaymentCardContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSubmit, setValue, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    if (!stripe || !elements || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const card = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(card);

    if (error) {
      toast.error('Error while adding the card: ' + error.message);
      setIsSubmitting(false);
    } else {
      try {
        await addCardAPI(token);
        props.toggle();
      } catch (apiError) {
        toast.error('Error while adding the card: ' + apiError.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.toggle}
        dialogClassName="newCard-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row g-3 cardForm">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <Modal.Footer className="border-0 addNewCardBtn">
              <button className="cancel_btn mx-2" onClick={props.toggle}>
                Cancel
              </button>
              <button 
                onClick={handleSubmit(onSubmit)} 
                className="updateCard" 
                disabled={!stripe || isSubmitting}
              >
                {isSubmitting ? 'Adding...' : 'Add Card'}
              </button>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
