import React, { useState, useEffect } from "react";
import styles from "./OTPResendTimer.module.css";
import { BButton } from "../../components/bButton";
const OTPResendTimer = ({resendOtp}) => {
  const [resendTimer, setResendTimer] = useState(120);

  useEffect(() => {
    let intervalId;

    if (resendTimer > 0) {
      intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [resendTimer]);

  const handleResendOTP = () => {
    // Logic to resend OTP
    setResendTimer(120);
    resendOtp(); // Set the initial timer value, e.g., 5 seconds
  };

  return (
    <div style={{ padding: "10px", textAlign: "center" }}>
      {resendTimer === 0 ? (
        <BButton
          style={{ width: "auto", color: "#9547cf", alignItems:"center" }}
          type="link"
          onClick={handleResendOTP}
        >
          Resend Verification Code
        </BButton>
      ) : (
        <div
          style={{ fontSize: "12px", color: "#595656", textAlign: "center" }}
        >
          Didn’t receive OTP? Resend in{" "}
          <span style={{ color: "#3A3EBA" }}>{resendTimer}</span> seconds
        </div>
      )}
    </div>
  );
};

export default OTPResendTimer;
