import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from '../../utils/axiosConfig';
import { SummaryContext } from '../../Context/OrderSummaryContext';

export default function PaymentServiceList({ setServiceType, paymentType }) {
  const [serviceList, setServiceList] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const { setSummaryData, getSummaryData } = useContext(SummaryContext);
  useEffect(() => {
    const storedServiceType = JSON.parse(sessionStorage.getItem('serviceType'));

    if (storedServiceType) {
      setSelectedServiceType(storedServiceType[0].code);
    }

    fetchService();
  }, []);

  useEffect(() => {
    // Clear session storage when the page is refreshed
    window.addEventListener('beforeunload', clearSessionStorage);

    // Clear session storage when the router changes
    return () => {
      window.removeEventListener('beforeunload', clearSessionStorage);
    };
  }, []);
  const clearSessionStorage = () => {
    sessionStorage.removeItem('serviceType');
  };

  const fetchService = () => {
    const url = 'delivery/services';
    let config = {
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setServiceList(response?.data?.data?.services);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

 

  const handleServiceChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedServiceType(selectedValue);
    setServiceType(selectedValue);
    sessionStorage.setItem(
      'serviceType',
      JSON.stringify([{ name: e.target.name, code: selectedValue }])
    );
  };

  useEffect(() => {
    if (paymentType?.type === "marketplace") {
       (selectedServiceType) && getSummaryData(paymentType?.type,'','').then((res) => {
          setSummaryData(res?.data?.data?.orderSummary);
    })
    } else if(paymentType?.buynow?.cardTypeforPay) {
      (selectedServiceType) && getSummaryData(paymentType?.buynow?.cardTypeforPay,
        paymentType?.buynow?.bidId ? paymentType?.buynow?.bidId : paymentType?.buynow?.listingId,
        paymentType?.buynow?.qty).then((res) => {
          setSummaryData(res?.data?.data?.orderSummary);
    })
    
    } 
      
   
    
  },[selectedServiceType])

  return (
    <Form.Group className="serviceListMenu">
      <Form.Select
        className="form-control pinInput"
        aria-label="service"
        name="service"
        value={selectedServiceType}
        onChange={handleServiceChange}
      >
        <option value="" disabled>
          Choose a Shipping Method
        </option>
        {serviceList?.length > 0 &&
          serviceList.map((item, idx) => (
            <option key={idx} value={item.code}>
              {item.name}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  );
}
