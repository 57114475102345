// IncDecCounter.js
import React, { useState } from "react";
import "./IncDecCounter.css";

function IncDecCounter({ currentQty, totalQty, uddateCartItems, cartItemId }) {
  const [num, setNum] = useState(currentQty);

  const incNum = () => {
    if (num < totalQty) {
      const newQuantity = num + 1;
      setNum(newQuantity);
      uddateCartItems(cartItemId,newQuantity)
    }
  };

  const decNum = () => {
    if (num > 1) {
      const newQuantity = num - 1;
      setNum(newQuantity);
      uddateCartItems(cartItemId,newQuantity)
    }
  };

  const handleChange = (e) => {
    const newQuantity = parseInt(e.target.value);

    if (!isNaN(newQuantity) && newQuantity >= 1 && newQuantity <= totalQty) {
      setNum(newQuantity);
      uddateCartItems(cartItemId, newQuantity)
    }
  };

  return (
    <div className="d-flex align-items-center IncDecCounter">
      <div className="d-flex inputGroup cartInputGroup justify-content-center">
        <div className="input-group-prepend">
          <button className={`btn ${num===1 ? "disabled lightbtn" : ""}`} type="button" onClick={decNum}>
            -
          </button>
        </div>
        <input
          type="text"
          className="form-control"
          value={num}
          onChange={handleChange}
          style={{pointerEvents:"none"}}
        />
        <div className="input-group-prepend">
          <button className={`btn ${num===totalQty ? "disabled lightbtn" : ""}`} type="button" onClick={incNum}>
            +
          </button>
        </div>
      </div>
    </div>
  );
}

export default IncDecCounter;
