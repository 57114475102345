import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SkeletonImg from '../../components/SkeletonImage/SkeletonImg';
import 'swiper/css';
import 'swiper/css/effect-cards';
import "./CardCarousel.css";
import { EffectCards, Pagination } from 'swiper/modules';

export default function CardCarousel({ images }) {
  const [imageLoaded, setImageLoaded] = useState({ front: false, back: false });
  const [grabCard, setGrabCard] = useState(false);
  const [swiperRef, setSwiperRef] = useState(null);

  useEffect(() => {
    const imgFront = new Image();
    imgFront.onload = () => {
      setImageLoaded(prevState => ({ ...prevState, front: true }));
    };
    imgFront.src = images?.front || '';

    const imgBack = new Image();
    imgBack.onload = () => {
      setImageLoaded(prevState => ({ ...prevState, back: true }));
    };
    imgBack.src = images?.back || '';
  }, [images]);

  const prevHandler = () => {
    swiperRef.slidePrev();
  };

  const nextHandler = () => {
    swiperRef.slideNext();
  };

  return (
    <>
      <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards, Pagination]}
        className="mySwiper card-detail-slider"
        onSwiper={(swiper) => setSwiperRef(swiper)}
      >
        <SwiperSlide>
          <div className="card-container cardDetails-container">
            <SkeletonImg className={`skeleton-img ${imageLoaded.front ? 'hidden' : ''}`} isVisible={!imageLoaded.front} />
            {imageLoaded.front && (
              <img
                src={images.front}
                onDragEnter={() => setGrabCard(!grabCard)}
                className={`${grabCard ? 'card-front grab-card' : 'card-front'}`}
                alt="Card Front"
              />
            )}
            <div className='overlay'>
              <h2 className="front-text">Front</h2>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card-container cardDetails-container">
            <SkeletonImg className={`skeleton-img ${imageLoaded.back ? 'hidden' : ''}`} isVisible={!imageLoaded.back} />
            {imageLoaded.back && (
              <img
                src={images.back}
                className="card-front"
                alt="Card Back"
              />
            )}
            <div className='overlay'>
              <h2 className="front-text">Back</h2>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className='card-detail-thumb'>
        <button onClick={prevHandler} className="swiper-button swiper-btn-prev">
          {!imageLoaded.front ? (
            <SkeletonImg className="thumb-skeleton" isVisible={!imageLoaded.front} />
          ) : (
            <>
              <img src={images.front} alt="Front" />
              <div>Front</div>
            </>
          )}
        </button>
        <button onClick={nextHandler} className="swiper-button swiper-btn-next">
          {!imageLoaded.back ? (
            <SkeletonImg className="thumb-skeleton" isVisible={!imageLoaded.back} />
          ) : (
            <>
              <img src={images.back} alt="Back" />
              <div>Back</div>
            </>
          )}
        </button>
      </div>
    </>
  );
}
