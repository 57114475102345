import React, { useEffect, useState, useContext } from "react";
import axios from "../../utils/axiosConfig";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { CategoryContext } from "../../Context/CategoryContext";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { ConfirmBox } from "../ConfirmBox/ConfirmBox";
import {ListCardSuccessPopUp}  from "./ListCardSuccess"
export default function AuctionCard({handleInputChange,unsavedChanges, setUnsavedChanges, setImages, images}) {
  const { fetchCategory, setCatTabs, catTabs } = useContext(CategoryContext);

  const [frontLoader, setFrontLoader] = useState(false);
  const [backLoader, setBackLoader] = useState(false);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [frontPhoto, setFrontPhoto] = useState(false);
  const [backPhoto, setBackPhoto] = useState(false);
  const [thumbnailPhoto, setThumbnailPhoto] = useState(false);

  const [frontPhotoImageKey, setFrontPhotoImageKey] = useState(false);
  const [backPhotoImageKey, setBackPhotoImageKey] = useState(false);
  const [thumbnailPhotoImageKey, setThumbnailPhotoImageKey] = useState(false);
  const [frontConfirmBox, setFrontConfirmBox] = useState(false);
  const confirmFrontToggle = () => setFrontConfirmBox(!frontConfirmBox);
  const [backConfirmBox, setBackConfirmBox] = useState(false);
  const confirmBackToggle = () => setBackConfirmBox(!backConfirmBox);
  const [thumbnailConfirmBox, setThubnailConfirmBox] = useState(false);
  const thumbnailConfirmToggle = () => setThubnailConfirmBox(!thumbnailConfirmBox);

  const [cancelConfirmBox, setCancelConfirmBox] = useState(false);
  const confirmCancelToggle = () => setCancelConfirmBox(!cancelConfirmBox);
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [countryList, setCountryList] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      // duration: "15 Days",
      total:1
    },
  });
  const onSubmit = (obj) => {
    const parsedObj = {
      ...obj,
      minBid: parseFloat(obj.minBid),
      reserveLimit: parseFloat(obj.reserveLimit),
      ounces: parseFloat(obj.ounces),
      length: parseFloat(obj.length),
      width: parseFloat(obj.width),
      height: parseFloat(obj.height),
      condition: obj?.condition?.toLowerCase(),
      originality: obj?.originality?.toLowerCase(),
      rarity: obj?.rarity?.toLowerCase(),
      year: parseFloat(obj.year),
    };

    const data = {
      ...parsedObj,
      front: frontPhotoImageKey,
      back: backPhotoImageKey,
      thumbnail: thumbnailPhotoImageKey,
      type: "auction",
      unit: "in",
    };
    
    let config = {
      method: "post",
      url: `listing/physical`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setShow(!show);    
          setFrontPhoto(false);
          setBackPhoto(false);
          setThumbnailPhoto(false);
          setFrontPhotoImageKey(false)
          setBackPhotoImageKey(false)
          setThumbnailPhotoImageKey(false)
          reset();
          setUnsavedChanges(false)
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
   const showTooltip = () => {
   setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };
   const handlePriceKeyDown = (e) => {
    const { name, value } = e.target;
    if (name === "total" && e.key === ".") {
        e.preventDefault();
    }
    if (
        (e.key === "-" && name !== "year") ||
        e.key === "e" ||
        e.key === "+" ||
        (e.key === "0" && value === "") // Prevent '0' if input is empty
    ) {
        e.preventDefault();
    }
};
  const handleInput = (e) => {
    handleInputChange(); 
  };
   
   const canceledCard = () => {
    reset();
    const images = [];
    if (frontPhoto !== false) {
      images.push(frontPhoto);
    }

    if (backPhoto !== false) {
      images.push(backPhoto);
    }

    if (thumbnailPhoto !== false) {
      images.push(thumbnailPhoto);
    }
    if (images?.length > 0) {
      axios({
        method: "post",
        url: `listing/cancel`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { images },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);
            setFrontPhoto(false);
            setBackPhoto(false);
            setThumbnailPhoto(false);
            setFrontPhotoImageKey(false)
            setBackPhotoImageKey(false)
            setThumbnailPhotoImageKey(false)
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };
 useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
    useEffect(() => {
    if (Object.keys(errors).length > 0) {
     window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errors]);
  const handlePhotoUpload = (file, type) => {
  const allowedFileTypes = ["image/png", "image/jpg", "image/jpeg"];
  if (!allowedFileTypes.includes(file?.type)) {
    toast.error("Invalid file format");
    setValue(type, "");
    return;
  }

  const fsize = file.size;
  const fileSize = Math.round(fsize / 1024);
  if (fileSize >= 2 * 1024) {
    toast.error(`File size should not exceed 2MB`);
    setValue(type, "");
    return;
  } 

  let img = new Image();
  img.src = window.URL.createObjectURL(file);
  img.onload = () => {
    console.log('first1', img.width)
    console.log('first2', img.height)

if (img.width < 290 || img.width > 450 || img.height < 400 || img.height > 650) {
  let errorMessage = "Please upload an image";
  if (img.width < 290) {
    errorMessage += " with a minimum width of 290 pixels";
  }
  if (img.width > 450) {
    errorMessage += " with a maximum width of 450 pixels";
  }
  if (img.height < 400) {
    errorMessage += " and a minimum height of 400 pixels";
  }
  if (img.height > 650) {
    errorMessage += " and a maximum height of 650 pixels";
  }
  errorMessage += ".";
  toast.error(errorMessage);
  
  setValue(type, "");
  return;
}



    const formData = new FormData();
    formData.append("image", file);
    if (type === "front") {
      setFrontLoader(true);
    } else if (type === "back") {
      setBackLoader(true);
    } else {
      setThumbnailLoader(true);
    }

    axios({
      method: "post",
      url: "listingImage",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setImages([...images, response?.data?.data?.imageurl]);
          handleInputChange();
          if (type === "front") {
            setFrontPhoto(response?.data?.data?.imageurl);
            setFrontPhotoImageKey(response?.data?.data?.imageKey)
            setFrontLoader(false);
          } else if (type === "back") {
            setBackPhoto(response?.data?.data?.imageurl);
            setBackPhotoImageKey(response?.data?.data?.imageKey)
            setBackLoader(false);
          } else {
            setThumbnailPhoto(response?.data?.data?.imageurl);
            setThumbnailPhotoImageKey(response?.data?.data?.imageKey)
            setThumbnailLoader(false);
          }
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        if (type === "front") {
          setFrontLoader(false);
        } else if (type === "back") {
          setBackLoader(false);
        } else {
          setThumbnailLoader(false);
        }
        setValue(type, "");

      });
  };
};

  const removePhotoUpload = (fileUrl, type) => {
    let data = JSON.stringify({
      url: fileUrl,
    });
    axios({
      method: "put",
      url: `listingImage`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
           if (type == "front") {
             setFrontPhoto(false);
              setFrontPhotoImageKey(false)
          } else if (type == "back") {
             setBackPhoto(false);
               setBackPhotoImageKey(false)
          } else {
             setThumbnailPhoto(false);
             setThumbnailPhotoImageKey(false)
          }
           toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    fetchCategory()?.then((response) => {
      if (response?.data?.statusCode === 200) {
        setCatTabs([response?.data.data.categoryList]);
      }
    });
    fetchCountries()
  }, []);
   const fetchCountries = () => {
    const url = "country";
    let config = {
      method: "get",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setCountryList(response?.data?.data?.countries)
          
        }
      })
      .catch((error) => {})
  };
  return (
    <Row className="col-12">
      <Col md={12} className="my-2">
        <Col className="listcard_mainContainer">
          <h3 className="cardHeading px-md-5 px-3">Create Your Listing</h3>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <Col className="card_contentSection px-md-5 px-3">
              <div className="cardUploadImgContainer">
                <div
                  className="ImgPreviewConatiner"
                  style={{
                    display: frontPhoto || frontLoader ? "block" : "none",
                  }}
                >
                  {frontLoader ? (
                    <>
                      <div className="uploadTxt">
                        <Loader />
                      </div>
                    </>
                  ) : (
                    frontPhoto && (
                      <>
                        <img src={frontPhoto} alt="Front Photo" />
                        <img
                          src="/Images/cardCross.svg"
                          className="cardCross"
                          alt="cardCross"
                          onClick={() => setFrontConfirmBox(!frontConfirmBox)}
                        ></img>
                        <ConfirmBox
                          show={frontConfirmBox}
                          toggle={confirmFrontToggle}
                          handleClick={() =>
                            removePhotoUpload(frontPhoto, "front")
                          }
                          cardContent={`Are you sure you want to remove front image?`}
                        />
                      </>
                    )
                  )}
                </div>

                {!frontPhoto && (
                  <div
                    className="cardImgConatiner"
                    style={{
                      display: frontPhoto || frontLoader ? "none" : "block",
                    }}
                  >
                    <label htmlFor="frontPhotoUpload" className="uploadTxt">
                      Upload Front Photo
                    </label>
                    <Form.Control
                      type="file"
                      id="frontPhotoUpload"
                      accept="image/png, image/jpeg, image/jpg"
                      {...register("front", { required: true })}
                      isInvalid={errors.front}
                      onChange={(e) => handlePhotoUpload(e.target.files[0], "front")}
                      style={{ display: "none" }}
                    />
                    {errors.front && (
                      <Form.Control.Feedback type="invalid">
                        Front photo is required
                      </Form.Control.Feedback>
                    )}
                  </div>
                )}

                <div
                  className="ImgPreviewConatiner"
                  style={{
                    display: backPhoto || backLoader ? "block" : "none",
                  }}
                >
                  {backLoader ? (
                    <>
                      <div className="uploadTxt">
                        <Loader />
                      </div>
                    </>
                  ) : (
                    backPhoto && (
                      <>
                        <img src={backPhoto} alt="Back Photo" />
                        <img
                          src="/Images/cardCross.svg"
                          className="cardCross"
                          alt="cardCross"
                          onClick={() => setBackConfirmBox(!backConfirmBox)}
                        ></img>
                        <ConfirmBox
                          show={backConfirmBox}
                          toggle={confirmBackToggle}
                          handleClick={() =>
                            removePhotoUpload(backPhoto, "back")
                          }
                          cardContent={`Are you sure you want to remove back image?`}
                        />
                      </>
                    )
                  )}
                </div>

                {!backPhoto && (
                  <div
                    className="cardImgConatiner"
                    style={{
                      display: backPhoto || backLoader ? "none" : "block",
                    }}
                  >
                    <label htmlFor="backPhotoUpload" className="uploadTxt">
                      Upload Back Photo
                    </label>
                   <Form.Control
                      type="file"
                      id="backPhotoUpload"
                      accept="image/png, image/jpeg, image/jpg"
                      {...register("back", { required: true })}
                      isInvalid={errors.back}
                      onChange={(e) =>
                        handlePhotoUpload(e.target.files[0], "back")
                      }
                      style={{ display: "none" }}
                    />
                    {errors.back && (
                      <Form.Control.Feedback type="invalid">
                        Back photo is required
                      </Form.Control.Feedback>
                    )}
                  </div>
                )}

                <div
                  className="ImgPreviewConatiner"
                  style={{
                    display:
                      thumbnailPhoto || thumbnailLoader ? "block" : "none",
                  }}
                >
                  {thumbnailLoader ? (
                    <>
                      <div className="uploadTxt">
                        <Loader />
                      </div>
                    </>
                  ) : (
                    thumbnailPhoto && (
                      <>
                        <img src={thumbnailPhoto} alt="Thumbnail Photo" />
                        <img
                          src="/Images/cardCross.svg"
                          className="cardCross"
                          alt="cardCross"
                          onClick={() =>
                            setThubnailConfirmBox(!thumbnailConfirmBox)
                          }
                        ></img>
                        <ConfirmBox
                          show={thumbnailConfirmBox}
                          toggle={thumbnailConfirmToggle}
                          handleClick={() =>
                            removePhotoUpload(thumbnailPhoto, "thumbnail")
                          }
                          cardContent={`Are you sure you want to remove thumbnail image?`}
                        />
                      </>
                    )
                  )}
                </div>

                {!thumbnailPhoto && (
                  <div
                    className="cardImgConatiner"
                    style={{
                      display:
                        thumbnailPhoto || thumbnailLoader ? "none" : "block",
                    }}
                  >
                    <label htmlFor="thumbPhotoUpload" className="uploadTxt">
                      Upload Thumbnail
                    </label>
                    <Form.Control
                      type="file"
                      id="thumbPhotoUpload"
                      accept="image/png, image/jpeg, image/jpg"
                       {...register("thumbnail", { required: true })}
                      isInvalid={errors.thumbnail}
                      onChange={(e) =>
                        handlePhotoUpload(e.target.files[0], "thumbnail")
                      }
                      style={{ display: "none" }}
                    />
                    {errors.thumbnail && (
                      <Form.Control.Feedback type="invalid">
                        Thumbnail photo is required
                      </Form.Control.Feedback>
                    )}
                  </div>
                )}
              </div>
              <p className="userInstruction">
                Please note that images must be in either JPG or PNG format and
                should not exceed 2 MB in size.
              </p>
               <p className="userInstruction">
                {/* Additionally, Image dimensions: width 290-450px, height 400-650px. */}
                Additionally, Ensure the image size fits within the range: Width 290-450px, Height 400-650px.
              </p>
            </Col>
           
             <div className={`row g-3 px-md-5 px-3`}>
                <Form.Group className="col-md-6">
                  <Form.Label>
                    Title <span className="error-message">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={30}
                    {...register("title", {
                      required: true
                    })}
                     onChange={handleInput}
                    isInvalid={errors.title}
                    placeholder="Listing Title for Card"
                  />
                  {errors.title && (
                    <Form.Control.Feedback type="invalid">
                      Title can’t be empty
                    </Form.Control.Feedback>
                  )}
                  <span className="userInstruction">
                  Please keep your text within 30 characters.
                  </span>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>
                    Category Name <span className="error-message">*</span>
                  </Form.Label>
                  <Form.Select
                    className="form-control"
                  aria-label="categoryId"
                  defaultValue=""
                    name="categoryId"
                    {...register("categoryId", {
                      required: "Please Choose a categoryId",
                    })}
                     onChange={handleInput}
                    isInvalid={errors.categoryId}
                  >
                    <option value="" disabled >
                      Choose a Category
                    </option>
                    {catTabs?.slice(1)?.map((item, idx) => (
                      <option key={idx} value={item.categoryId}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.categoryId && (
                    <Form.Control.Feedback type="invalid">
                      Please Choose a category
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label>
                    Set Name <span className="error-message">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={20}
                    {...register("set", {
                      required: true,
                    })}
                     onChange={handleInput}
                    isInvalid={errors.set}
                    placeholder="Card Set/Series Name"
                  />
                  {errors.set && (
                    <Form.Control.Feedback type="invalid">
                      Set Name can’t be empty
                    </Form.Control.Feedback>
                  )}
                  <span className="userInstruction">
                  Please keep your text within 20 characters.
                  </span>
              </Form.Group>
                <Form.Group className="col-md-6">
                <Form.Label>
                  Rarity <span className="error-message">*</span>
                </Form.Label>
                <Form.Select
                  className="form-control"
                  aria-label="Rarity"
                  name="rarity"
                  defaultValue=""
                  {...register("rarity", {
                    required: "Select rarity of card",
                  })}
                  onChange={handleInput}
                  isInvalid={errors.rarity}
                >
                  <option value="" disabled>
                    Select Rarity of card
                  </option>
                  <option>Basic</option>
                  <option>Rare</option>
                  <option>Signature</option>
                   <option>Other</option>
                </Form.Select>
                {errors.rarity && (
                  <Form.Control.Feedback type="invalid">
                    Select rarity of card
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              </div>
              <div className="borderBottom"></div> 
            <div className={`row g-3 px-md-5 px-3`}>
                <h5>Item Details</h5>
                 <Form.Group className="col-md-6">
                  <Form.Label>
                    Condition <span className="error-message">*</span>
                    {" "} <img src="/Images/conditionToolTip.svg"  onMouseEnter={showTooltip}
                      onMouseLeave={hideTooltip} className="cursor-pointer"
                    />
                  </Form.Label>
                   {isTooltipVisible && <>
                  <div className="tooltipCard">
                <div>
                  <b>Mint :</b> The trading card is in excellent condition with minimal or no visible signs of wear, suitable for collectors seeking pristine cards.<br></br>
                  <b>Excellent :</b> The card is in great condition with minor wear that does not significantly affect its appearance or value, appealing to collectors looking for high-quality cards.<br></br>
                  <b>Good :</b> The card shows some signs of wear such as light scratches or slight bends, still considered acceptable for collectors interested in cards with moderate wear.<br></br>
                  <b>Poor :</b> The card has noticeable damage or significant wear, making it less desirable for collectors and typically priced lower due to its condition.<br></br>
                  </div>
                 </div>
              </>}
                  <Form.Select
                    className="form-control"
                    aria-label="condition"
                  name="condition"
                defaultValue=""
                    {...register("condition", {
                      required: "Select Card's Condition",
                    })}
                     onChange={handleInput}
                    isInvalid={errors.condition}
                  >
                    <option value="" disabled >
                      Select Card's Condition
                    </option>
                    <option>Mint</option>
                    <option>Excellent</option>
                    <option>Good</option>
                    <option>Poor</option>
                  </Form.Select>
                  {errors.condition && (
                    <Form.Control.Feedback type="invalid">
                      Select Card's Condition
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              
                <Form.Group className="col-md-6">
                  <Form.Label>
                    Original/Reprint <span className="error-message">*</span>
                  </Form.Label>
                  <Form.Select
                    className="form-control"
                    aria-label="Original/Reprint"
                  name="originality"
                defaultValue=""
                    {...register("originality", {
                      required: "Select Card's Edition",
                    })}
                     onChange={handleInput}
                    isInvalid={errors.originality}
                  >
                    <option value="" disabled>
                      Select Card's Edition
                    </option>
                    <option>Original</option>
                    <option>Reprint</option>
                  </Form.Select>
                  {errors.originality && (
                    <Form.Control.Feedback type="invalid">
                      Select Card's Edition
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                
                <Form.Group className="col-md-6">
              <Form.Label>
                Country/Region of Production <span className="error-message">*</span>
              </Form.Label>
              <Form.Select
                className="form-control"
                aria-label="manufacturedLocation"
                defaultValue=""
                name="manufacturedLocation"
                {...register("manufacturedLocation", {
                  required: "Please choose a manufactured location",
                })}
                onChange={handleInput}
                isInvalid={!!errors.manufacturedLocation}
              >
                <option value="" disabled>
                  Choose a manufactured location
                </option>
                {countryList?.map((country, idx) => (
                  <option key={idx} value={country}>
                    {country}
                  </option>
                ))}
              </Form.Select>
              {errors.manufacturedLocation && (
                <Form.Control.Feedback type="invalid">
                  {errors.manufacturedLocation.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
                <Form.Group className="col-md-6">
                <Form.Label>
                  Year <span className="error-message">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={9}
                  minLength={4}
                  {...register("year", {
                    required: true,
                    pattern: /^[0-9+-]+$/,
                     validate: {
                       notZero: value => parseFloat(value) >= 1
                    }
                  })}
                  onChange={handleInput}
                   onKeyDown={handlePriceKeyDown}
                  isInvalid={errors.year}
                  placeholder="Year"
                />
                {errors?.year?.type=== "required" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid Year
                  </Form.Control.Feedback>
                )}
                 {errors?.year && errors?.year?.type === "notZero" && (
                <Form.Control.Feedback type="invalid">
                  Value can’t be less than 1
                </Form.Control.Feedback>
              )}
              </Form.Group>
              </div> 
              <div className="borderBottom"></div>
             <div className={`row g-3 px-md-5 px-3`}>
                <Form.Group className="col-md-12">
                  <h5>
                    Description <span className="error-message">*</span>
                 </h5>
                 
                  <Form.Control
                    as="textarea"
                    maxLength={255}
                    name="description"
                    rows={5}
                    {...register("description", { required: true })}
                     onChange={handleInput}
                    isInvalid={errors.description}
                    placeholder=""
                  />
                  {errors.description && (
                    <Form.Control.Feedback type="invalid">
                      Description field is required
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                 <p className="userText">
                   Write a detailed description of your card
                    <span className="userInstruction">
                 (Please keep your text within 255 characters).
                    </span>
                  </p>
                <Form.Group className="col-md-12">
                 <h5>Additional Description</h5>
                
                  <Form.Control
                    as="textarea"
                    maxLength={255}
                    name="additionalInfo"
                    rows={5}
                    {...register("additionalInfo")}
                     onChange={handleInput}
                    placeholder=""
                  />
                </Form.Group>
                  <p className="userText">
                  Write a detailed description of your terms and conditions for this card 
                    <span className="userInstruction">
                      (Please keep your text within 255 characters).
                    </span>
                  </p>
              </div>
              <div className="borderBottom"></div>
              <div className={`row g-3 px-md-5 px-3`}>
                <h5>Pricing</h5>
              
                <Form.Group className="col-md-6">
                  <Form.Label>
                  Minimum Bid <span className="error-message">*</span>
                   <span className="userInstruction">
                    {" "}
                    (limit up to $750,000)
                  </span>
                  </Form.Label>
                  <Form.Control
                  type="text"
                  min={1}
                  max={750000}
                  {...register("minBid", {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    validate: {
                       notZero: value => parseFloat(value) >= 1,
                      maxLimit: (value) => parseFloat(value) <= 750000,
                      isValidPrice: (value) => {
                        const decimalCount = (value.split(".")[1] || "").length;
                        return decimalCount <= 2;
                      },
                    },
                  })}
                  onChange={handleInput}
                  onKeyDown={handlePriceKeyDown}
                    isInvalid={errors.minBid}
                    placeholder="Minimum Bid"
                  />
                {errors?.minBid?.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid minimum bid
                  </Form.Control.Feedback>
                )}
                {errors?.minBid?.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid minimum bid with up to two decimal places
                  </Form.Control.Feedback>
                )}
                {errors?.minBid && errors?.minBid?.type === "maxLimit" && (
                  <Form.Control.Feedback type="invalid">
                    Minimum bid should not exceed $750,000
                  </Form.Control.Feedback>
                )}
                 {errors?.minBid && errors?.minBid?.type === "notZero" && (
                <Form.Control.Feedback type="invalid">
                  Price can’t be less than 1$
                </Form.Control.Feedback>
              )}
                </Form.Group>
               <Form.Group className="col-md-6">
    <Form.Label>
        Reserved Limit <span className="error-message">*</span>
    </Form.Label>
    <Form.Control
        type="text"
        min={1}
        max={750000}
        {...register("reserveLimit", {
            required: true,
            pattern: /^\d+(\.\d{1,2})?$/,
            validate: {
                notZero: value => parseFloat(value) >= 1,
                maxLimit: (value) => parseFloat(value) <= 750000,
                isValidPrice: (value) => {
                    const decimalCount = (value.split(".")[1] || "").length;
                    return decimalCount <= 2;
                },
                greaterThanMinBid: (value, { minBid }) => parseFloat(value) >= parseFloat(minBid)
            },
        })}
        onChange={handleInput}
        onKeyDown={handlePriceKeyDown}
        isInvalid={errors.reserveLimit}
        placeholder="Reserve Limit"
    />
    {errors?.reserveLimit?.type === "required" && (
        <Form.Control.Feedback type="invalid">
            Please enter a valid reserve limit
        </Form.Control.Feedback>
    )}
    {errors?.reserveLimit?.type === "pattern" && (
        <Form.Control.Feedback type="invalid">
            Please enter a valid reserve limit with up to two decimal places
        </Form.Control.Feedback>
    )}
    {errors?.reserveLimit && errors?.reserveLimit?.type === "maxLimit" && (
        <Form.Control.Feedback type="invalid">
            Reserve limit should not exceed $750,000
        </Form.Control.Feedback>
    )}
    {errors?.reserveLimit && errors?.reserveLimit?.type === "notZero" && (
        <Form.Control.Feedback type="invalid">
            Reserve limit can’t be less than 1$
        </Form.Control.Feedback>
    )}
    {errors?.reserveLimit && errors?.reserveLimit?.type === "greaterThanMinBid" && (
        <Form.Control.Feedback type="invalid">
            Reserve limit should not be less than the minimum bid
        </Form.Control.Feedback>
    )}
</Form.Group>
                  <Form.Group className="col-md-6">
                  <Form.Label>Number of Cards</Form.Label>
                  <Form.Control
                    type="number" min={1}
                     max={10}
                    {...register("total", {
                      pattern: /^[0-9+-]+$/,
                    })}
                  onChange={handleInput}
                  onKeyDown={handlePriceKeyDown}
                    // value="1"
                    disabled
                    placeholder="Quantity"
                  />
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={1000000}
                    // {...register("duration", { required: true })}
                    value="15 Days"
                     onChange={handleInput}
                    placeholder="Duration"
                    disabled
                  />
                </Form.Group>
              </div>
              <div className="borderBottom"></div>
                 <div className={`row g-3 px-md-5 px-3 align-items-baseline`}>
              <h5>Delivery</h5>

              <p className="userText" style={{ fontSize: "14px" }}>
               Provide the weight and dimensions for your card's packaging to get a calculated shipping cost.
              </p>
              <div className="packageSize">
                <Form.Label>Package Weight </Form.Label>
                {window.innerWidth > 991 && <Form.Label>Size </Form.Label>}
              </div>
              <Form.Group className="col-md-2">
                <Form.Control
                  type="text"
                  min={1}
                  max={1000}
                  placeholder="Ounce"
                  {...register("ounces", {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    validate: {
                      notZero: value => parseFloat(value) >= 1,
                      maxLimit: (value) => parseFloat(value) <= 1000,
                      isValidPrice: (value) => {
                        const decimalCount = (value.split(".")[1] || "").length;
                        return decimalCount <= 2;
                      },
                    },
                  })}
                  onChange={handleInput}
                  isInvalid={errors.ounces}
                  onKeyDown={handlePriceKeyDown}
                />
                  <span className="userInstruction">oz.</span>
                {errors?.ounces?.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                )}
                {errors?.ounces?.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid value with up to two decimal places
                  </Form.Control.Feedback>
                )}
                {errors?.ounces && errors?.ounces?.type === "maxLimit" && (
                  <Form.Control.Feedback type="invalid">
                    Value should not exceed 1000
                  </Form.Control.Feedback>
                )}
                {errors?.ounces && errors?.ounces?.type === "notZero" && (
                <Form.Control.Feedback type="invalid">
                  Value can’t be less than 1
                </Form.Control.Feedback>
              )}
              </Form.Group>

              <Form.Group className="col-md-2 col-4">
                <Form.Control
                  type="text"
                  min={1}
                  max={100}
                  placeholder="L"
                  {...register("length", {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    validate: {
                       notZero: value => parseFloat(value) >= 1,
                      maxLimit: (value) => parseFloat(value) <= 100,
                      isValidPrice: (value) => {
                        const decimalCount = (value.split(".")[1] || "").length;
                        return decimalCount <= 2;
                      },
                    },
                  })}
                  onChange={handleInput}
                  onKeyDown={handlePriceKeyDown}
                  isInvalid={errors.length}
                />
                <span className="userInstruction">In.</span>

                {errors?.length?.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                )}
                {errors?.length?.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid value with up to two decimal places
                  </Form.Control.Feedback>
                )}
                {errors?.length && errors?.length?.type === "maxLimit" && (
                  <Form.Control.Feedback type="invalid">
                    Value should not exceed 100
                  </Form.Control.Feedback>
                )}
                {errors?.length && errors?.length?.type === "notZero" && (
                <Form.Control.Feedback type="invalid">
                  Value can’t be less than 1
                </Form.Control.Feedback>
              )}
              </Form.Group>
              {window.innerWidth > 991 ? "x" : ""}
              <Form.Group className="col-md-2 col-4">
                <Form.Control
                  type="text"
                  min={1}
                  max={100}
                  placeholder="W"
                  {...register("width", {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    validate: {
                       notZero: value => parseFloat(value) >= 1,
                      maxLimit: (value) => parseFloat(value) <= 100,
                      isValidPrice: (value) => {
                        const decimalCount = (value.split(".")[1] || "").length;
                        return decimalCount <= 2;
                      },
                    },
                  })}
                  onChange={handleInput}
                  onKeyDown={handlePriceKeyDown}
                  isInvalid={errors.width}
                />
                <span className="userInstruction">In.</span>
                {errors?.width?.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                )}
                {errors?.width?.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid value with up to two decimal places
                  </Form.Control.Feedback>
                )}
                {errors?.width && errors?.width?.type === "maxLimit" && (
                  <Form.Control.Feedback type="invalid">
                    Value should not exceed 100
                  </Form.Control.Feedback>
                )}
                {errors?.width && errors?.width?.type === "notZero" && (
                <Form.Control.Feedback type="invalid">
                  Value can’t be less than 1
                </Form.Control.Feedback>
              )}
              </Form.Group>
              {window.innerWidth > 991 ? "x" : ""}
              <Form.Group className="col-md-2 col-4">
                <Form.Control
                  type="text"
                  min={1}
                  max={100}
                  placeholder="D"
                  {...register("height", {
                    required: true,
                    pattern: /^\d+(\.\d{1,2})?$/,
                    validate: {
                       notZero: value => parseFloat(value) >= 1,
                      maxLimit: (value) => parseFloat(value) <= 100,
                      isValidPrice: (value) => {
                        const decimalCount = (value.split(".")[1] || "").length;
                        return decimalCount <= 2;
                      },
                    },
                  })}
                  onChange={handleInput}
                  onKeyDown={handlePriceKeyDown}
                  isInvalid={errors.height}
                />
                <span className="userInstruction">In.</span>
                {errors?.height?.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                )}
                {errors?.height?.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid value with up to two decimal places
                  </Form.Control.Feedback>
                )}
                {errors?.height && errors?.height?.type === "maxLimit" && (
                  <Form.Control.Feedback type="invalid">
                    Value should not exceed 100
                  </Form.Control.Feedback>
                )}
                {errors?.height && errors?.height?.type === "notZero" && (
                <Form.Control.Feedback type="invalid">
                 Value can’t be less than 1
                </Form.Control.Feedback>
              )}
              </Form.Group>
            </div> 
              <div className="borderBottom"></div>
              <Col lg={5} md={12} className="d-flex flex-wrap mt-4 mb-5 px-md-5 px-3">
                <ButtonIcon
                iconPath={""}
                className={""}
                text="Add"
                clickHandler={() => {
                  console.log("");
                }}
              />
              <ButtonIcon
                iconPath={""}
                text="Reset"
                clickHandler={(e) => {
                
                  if (unsavedChanges) {
                  confirmCancelToggle(!cancelConfirmBox)
                  }
                  e.preventDefault();
                }}
                className={"cancelbutton"}
              />
              </Col>
            </Form> 
        </Col>
      </Col>
      <ListCardSuccessPopUp
        show={show}
        toggle={toggle }
        cardContent={`Your card is being reviewed and will be live once approved by admin.`}
      /> 
         {cancelConfirmBox && <ConfirmBox
        show={cancelConfirmBox}
        toggle={confirmCancelToggle}
        handleClick={() =>
          canceledCard()
        }
        cardContent={`Are you sure you want to clear filled data?`}
      /> }
    </Row>
  );
}
