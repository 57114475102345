import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import { useNavigate } from "react-router-dom";

export function ShipmentSuccessPopUp({ show, toggle, cardContent, shipmentModalData }) {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setImageLoaded(true);
    };
    image.src = "Images/check.svg";
  }, []);

  const handleDownloadLabel = () => {
      window.open(shipmentModalData?.label, "_blank");
    // // return;
    // if (shipmentModalData?.label) {
    //   const link = document.createElement('a');
    //   link.href = shipmentModalData.label;
    //   link.download = 'shipping_label.pdf'; 
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }
  };

  const handleClose = () => {
    toggle(); // Close the modal
    navigate("/sold-items", { replace: true });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="cbox-modal cbox-Sucess successPoPUP"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {imageLoaded && (
          <>
            <img
              src="Images/check.svg"
              className="my-3"
              alt="Check"
              title="Check"
            />
            <div>
              <b>{cardContent}</b>
            </div>
            <div className="shipmentTrackingId">
              Tracking ID: {shipmentModalData?.trackingNumber}
            </div>
            <div className="d-flex justify-content-center">
              <ButtonIcon
                iconPath=""
                text="Download Label"
                className="addressSubmitBtn mx-2"
                clickHandler={handleDownloadLabel}
              />
              <ButtonIcon
                iconPath=""
                text="Close"
                className="addressSubmitBtn closeShip mx-2"
                clickHandler={handleClose}
              />
            </div>
            <em className="dropOffContent">
              Please Drop-off your parcel at your nearest FedEx Drop-off Center.
            </em>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
