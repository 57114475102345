import React, { useContext, useState } from 'react';
import { Col } from 'react-bootstrap';
import styles from './OrderSummary.module.css';
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon';
import img4 from '../../asset/cart-page/img4.svg';
import { SummaryContext } from '../../Context/OrderSummaryContext';

import { toast } from 'react-toastify';
import { PaymentCardContext } from '../../Context/PaymentCardContext';
import { usaPriceformat } from '../../utils/commonFunction';

export default function OrderSummary({
  activeStep,
  setActiveStep,
  onSubmit,
  isValid,
  currentStatus,
  isOrderDone,
  type,
  serviceType
}) {
  
  const { summaryData } = useContext(SummaryContext);
const [isTooltipVisible, setTooltipVisible] = useState(false);
const {createPayment} = useContext
  (PaymentCardContext);

  const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };
const btnLabel = () => {
  if (type !== "auction") {
    switch (activeStep) {
      case 0:
        return 'Proceed to Checkout';
      case 1:
        return 'Proceed to Payment';
      case 2:
        return 'Place Your Order and Pay';
      case 3:
        return '';
      default:
        return null;
    }
  } else {
    switch (activeStep) {
      case 0:
        return 'Proceed to Payment';
      case 1:
        return 'Place Your Order and Pay';
      case 2:
        return '';
      default:
        return null;
    }
  }
};


  const handleButtonClick = (e) => {
    e.preventDefault();

    if (onSubmit) {
       onSubmit();
      
      if (isOrderDone && isValid && activeStep < 2) {
        if (!serviceType) {
          toast.error("Please select the shipment method that suits best for you");
          return;
        }
        else {
          setActiveStep(activeStep + 1);
         
        }
      }
      if (isValid && activeStep>=2) {
        setActiveStep(activeStep + 1);
      }
      else {
        isValid && setActiveStep(activeStep + 1);
      }
    } else {
      if (!summaryData?.total) {
        toast.error("Please add atleast one item in cart");
      } else if (currentStatus) {
          toast.error("Please remove the cards that have already been sold or delisted");
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  return (
    <Col className={`${styles.card_summaryContainer}`}>
      <h3 className={styles.orderHeading}>Order Summary</h3>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryText}>Subtotal</div>
        <div className={styles.summaryPrice}>
          {summaryData?.subtotal ? usaPriceformat(summaryData?.subtotal) : '$0.00'}
        </div>
      </div>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryText}>Shipping estimate</div>
        <div className={styles.summaryPrice}>{summaryData?.shipping ? usaPriceformat(summaryData?.shipping) : '$0.00'}</div>
      </div>
      <div className={styles.summaryContainer}>
        <div className={styles.summaryText}>Tax estimate</div>
        <div className={styles.summaryPrice}>{(summaryData?.tax) ? usaPriceformat(summaryData?.tax) : "$0.00"}</div>
      </div>
      <div className={`${styles.summaryContainer} border-0`}>
        <div className={styles.summaryText}>
          <b>Order total</b>
        </div>
        <div className={styles.summaryPrice}>
          {Number(summaryData?.total) ? (usaPriceformat(summaryData?.total)) : '$0.00'}

        </div>
      </div>
      <div className={`${styles.summaryContainer} ${styles.btnSection}`}>
        {activeStep > 0 && (
          <ButtonIcon
            text="Back"
            className={styles.cartbackbtn}
            clickHandler={() => {
              setActiveStep(activeStep - 1);
            }}
          />
        )}
        {btnLabel() && (
          <ButtonIcon
            text={btnLabel()}
            clickHandler={handleButtonClick}
            className="btnStyle"
          />
        )}
      </div>
       <div
      className={styles.learnMoreSection}
    
    >
      <span className="tooltip-icon"   onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}>
        <img src={img4} className="mx-2" style={{cursor:"pointer"}} alt="Tooltip Icon" />
      </span>{' '}
      <span>
        Information about <b>Taxes</b>
      </span>
      {isTooltipVisible && (
        <div className={styles.tooltip}>
            {/* Tooltip content */}
            <p className={styles.tooltiptext}>
              Tax calculation is based on buyer’s zipcode
              </p>
        </div>
      )}
    </div>
    </Col>
  );
}
