import React from "react";
import styles from "./Button.module.css";

const ButtonDetail = ({
  iconPath,
  text,
  className,
    isButtonDisable = false,
  clickHandler,
}) => {
  return (
    <button
      disabled={isButtonDisable}
      onClick={() => { clickHandler();
      }}
      className={`${styles.customDetailButton} ${className}`}
    >
       {iconPath && (
        <img src={iconPath} alt={text} className={styles.custom_button_img} />
      )}
      {text}
    </button>
  );
};

export default ButtonDetail;
