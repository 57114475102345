import React, { createContext, useContext, useState } from 'react';
import axios from '../utils/axiosConfig';
import { toast } from "react-toastify";
import { CartContext } from './CartItemContextProvider';
import { SummaryContext } from './OrderSummaryContext';

export const PaymentCardContext = createContext();

const PaymentCardContextProvider = ({ children }) => {
    const [cardData, setCardData] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [paymentStatus, setPaymentStatus] = useState('')
    const { setItemCounter, getCartItemDetail } = useContext(CartContext);
    const { setSummaryData } = useContext(SummaryContext);
    const addCardAPI = (token) => {
      const data = {
        stripeToken: token.id,
      };
      axios
      .post(`/paymentCard`, data)
      .then((response)=>{
        if(response?.data?.statusCode === 200){
          toast.success("Card added successfully");
          fetchCardData()
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
       toast.error(error?.response?.data?.message)
      });
    }

    const fetchCardData = () => {
        const url = "paymentCard";
        axios
          .get(url)
          .then((response) => {
            if (response?.data?.statusCode === 200) {
              setCardData(response?.data?.data);
              setIsLoading(false);
            } 
          })
          .catch((error) => {
           toast.error(error?.response?.data?.message)
          });
    };

    const createPayment= async(orderId, paymentId) =>{
        let config = {
          method: "post",
          url: `createPayment`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            orderId: orderId,
            paymentCardId: paymentId,
          },
        };
    
        try {
          const response = await axios.request(config);
          if (response?.data?.statusCode === 200) {
            toast.success('Payment successful');
            setPaymentStatus(1)
              getCartItemDetail().then((res) => {
          setItemCounter(res?.data?.data)
              });
            setSummaryData()
            setIsLoading(false);
          } else {
            toast.error('Payment failed Status:', response?.data?.statusCode);
            setPaymentStatus(0)
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
          setPaymentStatus(-1)
        }
      }
      

  const contextValue = {
    cardData, setCardData,
    fetchCardData, selectedCard,
    setSelectedCard, createPayment,
    isLoading, setIsLoading,
    addCardAPI, paymentStatus,
  };

  return (
    <PaymentCardContext.Provider value={contextValue}>
      {children}
    </PaymentCardContext.Provider>
  );
};

export default PaymentCardContextProvider;
