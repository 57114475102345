import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Navbar from "../Navigation/Navbar";
import Footer from "../Footer/Index";
import {
  LiaFacebookF,
  LiaTwitter,
  LiaInstagram,
  LiaLinkedinIn,
} from "react-icons/lia";
import { FaPhoneAlt, FaYoutube } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import contactimgUrl from "../../asset/contactUs.svg";
import "./Contact.css";
import ContactForm from "./ContactForm";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../Containers/BreadCrumb/BreadCrumbs";
export default function ContactUs() {
  const navigate = useNavigate();
  const Social = [
    {
      icon: <LiaLinkedinIn className="social_icon" />,
      link: "https://www.linkedin.com/company/fantasy-card-usa/?viewAsMember=true",
    },
    {
      icon: <LiaInstagram className="social_icon" />,
      link: "https://www.instagram.com/fantasycardsusa/",
    },
    {
      icon: <LiaFacebookF className="social_icon" />,
      link: "https://www.facebook.com/people/Fantasy-Cards/61553796886367/",
    },
    // {
    //   icon: <FaYoutube className="social_icon" />,
    //   link: "https://www.youtube.com/",
    // },
  ];

  const Contact = [
    {
      icon: <FaPhoneAlt />,
      info: { phone1: "+1 605-340-0690" },
    },
    {
      icon: <IoMdMail />,
      info: { emal1: "info@fantasy-cards.com" },
    },
    // {
    //   icon: <MdLocationOn />,
    //   info: "132 Dartmouth Street Boston, Massachusetts 02156 United States",
    // },
  ];

  return (
    <>
      {/* <Navbar /> */}
      <BreadCrumbs />
      <div className="container">
      <div className="d-flex m-auto flex-wrap my-5 contactMain">
        <div
          className="contact-left"
          style={{
            backgroundImage: `url(${contactimgUrl})`,
          }}
        >
          <h3 className="mainTaxt">Contact Information</h3>
          <p className="subtaxt">
            Feel free to share your questions or requests by typing them into the contact form. We're here to help!
          </p>
          {Contact.map((val, idx) => {
            return (
              <div
                className="d-flex flex-wrap thumb-content align-items-center"
                key={idx}
              >
                <div className="links-icon my-3">{val.icon}</div>
                {typeof val.info === "string" ? (
                  <div className="weblinkstext w-75">{val.info}</div>
                ) : (
                  Object.keys(val.info).map((key, index) => (
                    <div className="weblinkstext" key={index}>
                      {val.info[key]}
                    </div>
                  ))
                )}
              </div>
            );
          })}

          {Social.map((val, idx) => (
            <a
              target="_blank"
              className="contactIcons"
              href={val.link}
              key={idx}
            >
              {val.icon}
            </a>
          ))}
        </div>
        <div className="contact-right">
          <ContactForm />
        </div>
      </div>
</div>
      {/* <Footer /> */}
    </>
  );
}
