import React, { createContext, useState } from 'react';
import axios from '../utils/axiosConfig';

export const SummaryContext = createContext();

const SummaryContextProvider = ({ children }) => {
  // State variables
  const [summaryData, setSummaryData] = useState([]);
  const [cardTypeValue, setCardTypeValue] = useState("marketplace");

  // Function to fetch summary data
  const getSummaryData = (type, id, qty) => {
    const serviceType = JSON?.parse(sessionStorage?.getItem('serviceType'))?.[0]?.code;

    // Construct URL based on type of order
    let url = '';
    if (type === "auction") {
      url = serviceType ? `orderSummaryByBid/${id}?serviceType=${serviceType}` : `orderSummaryByBid/${id}`
    } else if (type === "marketplace") {
        url = `orderSummary?serviceType=${serviceType}`;
    } else if (type === "buynow") {
      url = serviceType  ? `orderSummaryByListing/${id}?qty=${qty}&serviceType=${serviceType}` : `orderSummaryByListing/${id}?qty=${qty}`
    } else {
      url =  `orderSummary`;
    }

    const config = {
      method: 'get',
      url: url,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.request(config);
  };

  // Context value
  const contextValue = {
    summaryData,
    setSummaryData,
    getSummaryData,
    cardTypeValue,
    setCardTypeValue
  };

  return (
    <SummaryContext.Provider value={contextValue}>
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryContextProvider;
