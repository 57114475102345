import React, { useContext, useEffect, useState, useRef } from "react";
import { BiSearch, BiHeart } from "react-icons/bi";
import defaultLOGO from "../../asset/user-icon.png";
import logo from "../../asset/fc_log_header.svg";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import styles from "./Navbar.module.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ProfileContext } from "../../Context/ProfileContextProvider";
import { CartContext } from "../../Context/CartItemContextProvider";
import { SearchContext } from "../../Context/SearchContextProvider";
import axios from "axios";
import { CategoryContext } from "../../Context/CategoryContext";
import NavBarModal from "./NavBarModal";
import GuestLogin from "../Login/GuestLogin";
import { toast } from "react-toastify";
function NavScrollBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    userName,
    profilInfo,
    setUserImg,
    userImg,
    fetchName,
    setUserName,
    setProfileInfo,
  } = useContext(ProfileContext);
  const { itemCounter, getCartItemDetail, setItemCounter } =
    useContext(CartContext);
  const { setCatSelectedTab } = useContext(CategoryContext);
  const { searchKey, setSearchKey, searchKeyword, setSearchKeyword } =
    useContext(SearchContext);
  const [guestLogin, setGuestLogin] = useState();
  const [showGuest, setShowGuest] = useState(false);
  const toggleGuest = () => setShowGuest(!showGuest);
 const [loggingOut, setLoggingOut] = useState(false);
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
 const [logged, setLogged] = useState(null);
  const handleSearch = (e, condition) => {
  if (searchKey) {
    if (e?.key === "Enter" || e?.keyCode === 13) {
      const keyword = searchKey;
      setSearchKeyword(keyword);
      navigate("/search");
    } else if (condition) {
      setSearchKeyword(searchKey);
      navigate("/search");
    }
  }
};
  useEffect(() => {
    //used this because above function this will not working
    if (logged) {
      navigate("/login");
    }
  }, [logged]);
 const sidebarData = [
    {
      name: "Personal Info",
      url: "/profile",
      },
     {
      name: "Address",
      url: "/address",
    },
    {
      name: "Wishlist",
      url: "/wishlist",
    },
    {
      name: "My Orders",
      url: "/orders",
    },
    {
      name: "Sold Items",
      url: "/sold-items",
    },
    {
      name: "My Vault",
      url: "/vault",
      },
      {
      name: "Earnings",
      url: "/earnings",
    },
    {
      name: "Logout",
    },
  ];
   const logOutHandler = () => {
  // If already logging out, prevent further calls
  if (loggingOut) {
    return;
  }

  setLoggingOut(true); // Set loggingOut to true to indicate logout process started

  let config = {
    method: "GET",
    url: `/logout`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Logout successful!");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userLastName");
        localStorage.removeItem("userFirstName");
        localStorage.removeItem("image");
        localStorage.removeItem("guestLogin");

        setLogged(Math.random());
        navigate("/login");
      } else {
        toast.error(
          response?.data?.message || "Something went wrong try again later."
        );
      }
    })
    .catch((error) => {})
    .finally(() => {
      setLoggingOut(false); // Reset loggingOut state variable after logout process is complete
    });
};

  const resetSearchOnPageChange = () => {
    setSearchKey("");
    setCatSelectedTab({ name: "", id: "" });
  };
  useEffect(() => {
    guestLogin == "false" &&
      fetchName().then((response) => {
         const firstName = response?.data.data.user.firstName
      setUserName(firstName)
      setUserImg(response?.data.data.user.image)
      // localStorage.setItem("userImg",response?.data.data.user.image);
      setProfileInfo(response?.data.data.user)
      });
  }, [guestLogin]);

  useEffect(() => {
    guestLogin == "false" &&
      getCartItemDetail().then((res) => {
        setItemCounter(res?.data?.data);
      });
  }, []);

  useEffect(() => {
    resetSearchOnPageChange();
  }, [location]);

  useEffect(() => {
    const updatedName = localStorage.getItem("username");
    setUserName(updatedName);
  }, []);

  useEffect(() => {
    const gLogin = localStorage.getItem("guestLogin");
    setGuestLogin(gLogin);
  }, []);
  const [navContainerVisible, setNavContainerVisible] = useState(false);
   const navContainerRef = useRef(null);

const handleNavContainerEnter = () => {
    setNavContainerVisible(true);
};
 const handleNavContainerLeave = () => {
    setNavContainerVisible(false);
  };

const handleNavContainerClick = () => {
    setNavContainerVisible(!navContainerVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navContainerRef.current && !navContainerRef.current.contains(event.target)) {
        setNavContainerVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navContainerRef]);
  return (
    <Navbar
      expand="lg"
      className={`d-flex align-items-center ${styles.navbar}`}
    >
      <div className="container">
        {/* <Container fluid style={{backgroundColor: 'rgb(255, 255, 255)'}}> */}
        {/* Logo*/}
        <Navbar.Brand
          className={`${styles.logo}`}
          onClick={() => navigate("/dashboard")}
        >
          <img src={logo} alt="User Avatar" />
        </Navbar.Brand>

        {/* Nav items*/}
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => {
            setShow(!show);
          }}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className={`lg-my-2 my-lg-0 md-12 col-12 ${styles.navAlign}`}
            navbarScroll
          >
            {/* Search Tab */}
            <div
              className={`d-flex align-items-center sortBy ${styles.searchInputGroup}`}
            >
              <div className={`input-group sortBy`}>
                <Form.Control
                  className={`rounded-pill ${styles.searchForm}`}
                  type="search"
                  placeholder="Search for cards, categories..."
                  value={searchKey}
                  maxLength={30}
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={handleSearch}
                />
                <div className="input-group-append">
                  <span
                    className={`input-group-text bg-transparent border-0 ${styles.icon} ${styles.searchIcon}`}
                  >
                    <BiSearch onClick={()=>handleSearch('',true)}/>
                  </span>
                </div>
              </div>
            </div>
            {/* Conditionally render the Profile component based on the toggle state */}
            {window.innerWidth <= 991 && (
              <NavBarModal show={show} toggle={toggle} />
            )}

            {(guestLogin == "true" || guestLogin == null) && (
              <GuestLogin show={showGuest} toggle={toggleGuest} />
            )}
            {/* Wishlist Icon */}
            <Nav.Link
              className={`sortBy`}
              onClick={() => {
                if (guestLogin == "false") {
                  navigate("/wishlist");
                } else {
                  setShowGuest(!showGuest);
                }
              }}
            >
              <BiHeart className={`${styles.icon}`} />
              <span>Wishlist</span>
            </Nav.Link>

            <Nav.Link className="sortBy">
              <span className={`${styles.verticalBar}`}></span>
            </Nav.Link>

            {/* Cart Icon */}
            <Nav.Link
              className="sortBy"
              style={{ position: "relative" }}
              onClick={() => {
                if (guestLogin == "false") {
                  navigate("/cart", { state: { currentStep: 0 } });
                } else {
                  setShowGuest(!showGuest);
                }
              }}
            >
              <img src="/Images/CartIcon.svg" className={`${styles.icon}`} />
              {guestLogin == "false" && itemCounter?.totalQty > 0 && (
                <span className={`${styles.itemCount}`}>
                  {itemCounter?.totalQty}
                </span>
              )}
              <span>Cart</span>


            </Nav.Link>
        <Nav.Link className="sortBy">
              <span className={`${styles.verticalBar}`}></span>
            </Nav.Link>
            <Nav.Link
              className="sortBy"
              style={{ position: "relative" }}
              onClick={() => {
                if (guestLogin == "false") {
                  navigate("/list-card");
                } else {
                  setShowGuest(!showGuest);
                }
              }}
            >
                <img
                      src="/Images/list-check.svg" className={`${styles.icon}`}/>
              <span>LIST CARD</span>


            </Nav.Link>
              

            <Nav.Link className="sortBy">
              <span className={`${styles.verticalBar}`}></span>
            </Nav.Link>

            {/* User icon */}
            <Nav.Link
              onMouseEnter={handleNavContainerEnter}
              className={`${styles.username} sortBy`}
              onClick={() => {
                if (guestLogin == "false") {
                  // navigate("/profile");
                  handleNavContainerClick();
                } else {
                  setShowGuest(!showGuest);
                }
              }}
            >
              your profile
              <br />
              {guestLogin == "false" ? (
                <span>{`${
                  userName || localStorage.getItem("userFirstName") || "Guest" 
                }`}</span>
              ) : (
                <span>Guest</span>
              )}
            </Nav.Link>

            <Nav.Link
              onMouseEnter={handleNavContainerEnter}
              className={`sortBy`}
              onClick={() => {
                if (guestLogin == "false") {
                  // navigate("/profile");
                    handleNavContainerClick();
                } else {
                  setShowGuest(!showGuest);
                }
              }}
            >
              { guestLogin == "false" ? 
              <img
                src={userImg || profilInfo?.image || defaultLOGO }
                className={styles.profileLogo }
                /> :
              <img
                src={defaultLOGO}
                className={styles.profileLogo}
              /> 
              }
                </Nav.Link >
 {/* <Nav.Link className="sortBy" onMouseEnter={handleNavContainerEnter}>
  <img src="Images/downArrow.svg" className="downArrow" alt="Down Arrow" 
    onClick={handleNavContainerClick} /> */}
            {guestLogin == "false" && <div
              ref={navContainerRef}
              onMouseLeave={handleNavContainerLeave}
              className={`sidebar-container nav-container ${navContainerVisible ? 'showconatiner' : 'hideconatiner'}`}
  
            >
              {sidebarData.map((value, id) => (
                <div
                  key={id}
                  className={location.pathname === value.url ? "Active" : "unActive"}
                >
                  <Link key={id.toString()} to={value.url} relative="path">
                    {value.url ? (
                      <span>{value.name}</span>
                    ) : (
                      <span
                        onClick={() => {
                          logOutHandler();
                        }}
                      >
                        {value.name}
                      </span>
                    )}
                  </Link>
                </div>
              ))}
            </div>}
{/* </Nav.Link> */}




          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </div>
    </Navbar>
  );
}

export default NavScrollBar;
