import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styles from "./LoginPage.module.css";
import fantasyCards from "../../asset/FantasyCards.png";
import Modal from "react-bootstrap/Modal";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import backgroundImage from "../../asset/loginPageBg.png";
import { Link } from "react-router-dom";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";

export default function GuestLogin({ show, toggle }) {
  const navigate = useNavigate();


  const loginUser = () => {
    navigate("/login");
  };
  return (
    <>
      <Modal show={show} onHide={toggle} dialogClassName="Guestmodal" >
        <Modal.Body>
          <div className="container-fluid">
            <div className={`bg-white rounded ${styles.guestForm}`}>
              <h2 className="lg-mb-4 text-center">
                <img
                  src={fantasyCards}
                  alt="logo"
                  className={styles.mainLogo}
                />
              </h2>
              <div className={styles.signinText}>
                Checkout as a New Customer
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ButtonIcon
                  type="submit"
                  className={styles.submit}
                  clickHandler={loginUser}
                  text={"Existing  Customer"}
                />
              </div>
              <div className={styles.orText}>OR</div>

              <div className={styles.signupText}>
                <div>
                  No Account yet?
                  <Link to="/signup" relative="path">
                    {" "}
                    Sign up now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
