import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routing";
import history from "./components/history";
import CustomRouter from "./components/CustomBrowserRouter";
import { ToastContainer } from "react-toastify";
import ProfileContextProvider from "./Context/ProfileContextProvider";
import CartCountContextProvider from "./Context/CartItemContextProvider";
import SearchContextProvider from "./Context/SearchContextProvider";
import CategoryProvider from "./Context/CategoryContext";
import SummayContextProvider from "./Context/OrderSummaryContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Navbar from "./Containers/Navigation/Navbar";
import Footer from "./Containers/Footer/Index";

function AppContent() {
  const [shouldShowNavFooter, setShowNavFooter] = useState(true);

  const routesWithoutLogin = [
    '/login',
    '/forgotPassword',
    '/changePassword',
    '/signup',
    '/otpVerify',
    '/forgot-password',
    '/change-password'

  ];

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    setShowNavFooter(!path.startsWith("/admin") && !routesWithoutLogin.includes(path));
  }, [location.pathname, routesWithoutLogin]);

  return (
    <>
      {shouldShowNavFooter && <Navbar />}
      <Routes />
      {shouldShowNavFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <>
      <GoogleOAuthProvider clientId="569360280057-gs2ot4tb751m6sdrs4f9guvv8hgmvqcv.apps.googleusercontent.com">
        <SummayContextProvider>
          <CategoryProvider>
            <SearchContextProvider>
              <CartCountContextProvider>
                <ProfileContextProvider>
                  <React.Suspense fallback="">
                    <CustomRouter history={history}>
                      <AppContent />
                    </CustomRouter>
                  </React.Suspense>
                </ProfileContextProvider>
              </CartCountContextProvider>
            </SearchContextProvider>
          </CategoryProvider>
        </SummayContextProvider>
        <ToastContainer
          position="top-right"
          autoClose={1200}
          hideProgressBar={false}
          newestOnTop={false}
          draggable={false}
          pauseOnVisibilityChange
          closeOnClick
          pauseOnHover
        />
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
