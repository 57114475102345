export function commonFunction(currDate) {
    const inputDate = new Date(currDate);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const formattedDate = `${months[inputDate.getUTCMonth()]}-${inputDate.getUTCDate()}-${inputDate.getUTCFullYear()}`;
    return formattedDate;
}

export function convertDateIntoTimer(endDate) {
  const endDateObj = new Date(endDate);
  const currDateObj = new Date();

  const timeDifference =  currDateObj - endDateObj;

  // Calculate the time components
  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Build the formatted string
  let formattedTimer = "ago";
  if (seconds >= 0) {
    formattedTimer = `${seconds}s ${formattedTimer}`;
  }
   if (minutes >= 0) {
    formattedTimer = `${minutes}m ${formattedTimer}`;
  }
   if (hours >= 0) {
    formattedTimer = `${hours}h ${formattedTimer}`;
  }
  if (days >= 0) {
    formattedTimer = `${days}days ${formattedTimer}`;
  }
 
 
 

  return formattedTimer.trim();
}


export function getTimeRemaining(bidTimer) {
  const endTime = new Date(bidTimer);
  const now = new Date();
  const timeDiff = endTime - now;
  if (timeDiff <= 0 || isNaN(timeDiff)) {
    return "0:00:00:00";
  }
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}


export function usaPriceformat(price) {
return parseFloat(Number(price)?.toFixed(2)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}
