import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import emptyCart from "../../asset/emptyCart.svg";
import styles from "./Cart.module.css";
import IncDecCounter from '../../components/IncDecCounter/product/IncDecCounter';
import img1 from "../../asset/cart-page/img1.svg";
import img2 from "../../asset/cart-page/img2.svg";
import img3 from "../../asset/cart-page/img3.svg";
import OrderSummary from '../OrderSummary/OrderSummary';
import axios from "../../utils/axiosConfig";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../../Context/CartItemContextProvider';
import { SummaryContext } from '../../Context/OrderSummaryContext';
import cardRemove from "../../asset/removeCard.svg";
import removeRedCard from "../../asset/removeRedCard.svg";

// import Loader from '../../components/Loader/Loader';
import { ProfileContext } from "../../Context/ProfileContextProvider";
import { usaPriceformat } from '../../utils/commonFunction';

export default function Cart(props) {
  // const { profilInfo , setProfileInfo, fetchName} = useContext(ProfileContext);
  const { itemCounter, setItemCounter, getCartItemDetail } = useContext(CartContext);
  const { setSummaryData, getSummaryData, cardType } = useContext(SummaryContext);
  const navigate = useNavigate();

  const [cartType, setCartType] = useState(cardType || "marketplace")
  const [isLoading, setIsLoading] = useState(false);
  const [isItemUpdate, setIsItemUpdate] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
 
  const uddateCartItems = (cartItemId, newQuantity) => {
    removeFromCart(cartItemId, newQuantity);
  }
   const [currentStatus, setCurrentStatus] = useState(false);

  useEffect(() => {
    const statusVal = itemCounter?.cartItem.some(item => item.listingInfo.status !=="active");

    setCurrentStatus(statusVal);
  }, [itemCounter]);

  const removeFromCart = (cartItemId, newQuantity) => {
    setIsItemUpdate(false);
    const data = JSON.stringify({
      qty: newQuantity ? newQuantity : 0,
    });
    const config = {
      method: "put",
      url: `cartItem/${cartItemId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          !newQuantity && toast.success(response?.data?.message);
          setIsItemUpdate(true);
        }
      })
      .catch((error) => {
      });
  }

  const moveToWishlist = (listingId) => {
    setIsItemUpdate(false);
    const data = JSON.stringify({
      listingId: listingId,
    });
    const config = {
      method: "post",
      url: `moveToWishlist`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          setIsItemUpdate(true);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
        setIsItemUpdate(true);
      });
  }

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  }

  useEffect(() => {
    getCartItemDetail().then((res) => {
      setItemCounter(res?.data?.data)
      setIsLoading(true);
      if (res?.data?.data?.cartItem.length > 0) {
        cartType !== undefined && getSummaryData('',cartType).then((res) => {
          setSummaryData(res?.data?.data?.orderSummary);
        });
      } else {
        setSummaryData({});
      }
    });
  }, [isItemUpdate]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
     window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Cart Page',
      });
  }, []);
//  useEffect(() => {
//     fetchName().then((response) => {
//           setProfileInfo(response?.data.data.user)
//       });
  //   }, []);

  const handleMouseEnter = (idx) => {
    setIsHovered(idx);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  return (
    <Container>
      <Row className="col-12">
        <Col lg={6} md={12} className='mx-md-5 mx-2'>
        <Col  className={`${styles.card_mainContainer}`}>
          <h3 className={`${styles.cartHeading}`}>Description</h3>
          <Col className={styles.card_contentSection}>
            {
              itemCounter?.cartItem?.length <= 0 && (
              <h6 className="d-block text-center">
                <img src={emptyCart} className="emptyImg" />
                <p>Oops! It seems like your shopping cart is currently empty. 🛒</p>
              </h6>
            )}
            {itemCounter?.cartItem?.map((item, idx) => {
             return <Row className={styles.card_Container} key={idx}>
                  <div className="d-flex justify-content-between cartMain">
                    <img src={item?.listingInfo?.cardInfo?.image?.thumbnail ||item?.listingInfo?.cardInfo?.image?.front} onClick={() => onClickHandler(item?.listingId)} alt="card" className={`${styles.card_img} ${styles.thumbnail}`} />
                    <div className={`row-1 ${styles.card_content}`}>
                     <div className='lg-col-5 col-sm-12'>
                       {/* <div className='d-flex flex-wrap infocartDiv'> */}
                        <div className={`${styles.card_title}`}>{item?.listingInfo?.cardInfo?.title}</div>
                        <div className={`cardCat`}>{item?.listingInfo?.cardInfo?.categoryInfo?.name}</div>
                        <div className={`${styles.card_price}`}>{usaPriceformat(item?.listingInfo?.marketplace?.price)}</div>
                         {/* </div> */}
                        {(item?.listingInfo?.qty?.available >= 1 && item?.listingInfo?.status == "active" ) && <div className={` statusbtn instockbtn`}>{item?.listingInfo?.qty?.available} In Stock</div>}
                        {item?.listingInfo?.status == "sold" && <div className={` statusbtn insoldbtn`}>Sold</div>}
                         {item?.listingInfo?.status == "delisted" && <div className={` statusbtn Delistedbtn`}> Delisted</div>}
                   </div>
                 </div>
                 <div className='' style={{width:"100%"}}>
                      <div className='lg-col-7 col-sm-12'>
                     <button className={`${styles.remove_btn}`} onClick={() => removeFromCart(item?.cartItemId)}>
                       {isHovered !== idx ?
                         <img src={cardRemove} title='Remove Card' alt="removeBtn"
                           onMouseEnter={() => handleMouseEnter(idx)}
                           onMouseLeave={handleMouseLeave}
                           
                         /> :
                         <img src={removeRedCard} title='Remove Card' alt="removeBtn"
                             onMouseEnter={() => handleMouseEnter(idx)}
                           onMouseLeave={handleMouseLeave}
                         />}
                     </button>
                     {item?.listingInfo?.status == "active" && <><IncDecCounter key={item.listingId} cartItemId={item.cartItemId} uddateCartItems={uddateCartItems} currentQty={item?.qty} totalQty={item?.listingInfo?.qty?.available} />
                       <button className={`${styles.remove_btn} movetoWishlist mt-2`} onClick={() => moveToWishlist(item?.listingId)}>Move to Wishlist</button> </>}
                      </div>
                    </div>
                  </div>
              </Row>
})}
          </Col>
        </Col>
         </Col>
        <Col lg={4} md={12}  className=' mx-2'>
          <OrderSummary currentStatus={currentStatus} activeStep={props.activeStep} setActiveStep={props.setActiveStep} type={props.type} />
        </Col>
      </Row>
      {/* <Row className="col-12 col-md-12">
        <Col lg={6} md={12} className={`${styles.card_mainContainer} my-4 mx-2`}>
          <h3 className={styles.cartHeading}>Delivery Information</h3>
          <Col className={styles.userSection}>
            <div>{`${profilInfo?.firstName} ${profilInfo?.lastName}`}</div>
            <div className={styles.userEmail}>{`${profilInfo?.email?.value}`}</div>
            <div className={`${styles.deliveryContainer} d-flex`}>
              <div className='d-flex vaulttabs'>
                <img src={img1} className='mx-3' />
                <div>Estimated  Delivery on time in 3 Days</div>
              </div>
              <div className='d-flex vaulttabs'>
                <img src={img2} className='mx-3' />
                <div>Guranteed 100% Original Product</div>
              </div>
              <div className='d-flex vaulttabs'>
                <img src={img3} className='mx-3' />
                <div>1 Day Returns if you change your mind</div>
              </div>
            </div>
          </Col>
        </Col>
      </Row> */}
    </Container>
  );
}
