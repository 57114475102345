import React, { createContext, useEffect, useState } from 'react';
import axios from "../utils/axiosConfig"

export const CartContext = createContext();
 const isAuthenticated = localStorage.getItem("token") ? true : false;

const CartCountContextProvider = ({ children }) => {
  const [itemCounter, setItemCounter] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
    const getCartItemDetail = () => {
          const config = {
          method: 'get',
          url: `cartItem`,
        };
        return axios.request(config);
      };

  useEffect(() => {
    isAuthenticated &&  getCartItemDetail().then((res) => {
      setItemCounter(res?.data?.data)
      setIsLoading(false)
      });
  }, [])
    const contextValue = {
      itemCounter,setIsLoading,
      setItemCounter, getCartItemDetail,
      isLoading

    };
  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};
export default CartCountContextProvider;