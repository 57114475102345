import React, {useState } from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import styles from "./vault.module.css";
import ProfileTabs from "../../components/CustomTabBar/ProfileTabs/ProfileTabs";

import MyVault from "./MyVault";
import ListedForAuction from "./ListedForAuction";
import ListedForSale from "./ListedToMarketplace";
import MyBids from "./MyBids";
import { useLocation } from "react-router-dom";

const Vault = () => {
    const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(state?.currActive || 1);
  const tabs = [
    {
      id: 1,
      name: "My Vault",
    },
    {
      id: 2,
      name: "Marketplace",
    },
    {
      id: 3,
      name: "Auction",
    },
    {
      id: 4,
      name: "My Bids",
    },
  ];
  const onTabClickHandler = (v) => {
    setActiveTab(v);
  };
  //   useEffect(() => {
  //   setActiveTab(state?.currActive);
  // }, [state]);

  return (
    <ProfileContainer>
      <div className={styles.children_container}>
        <ProfileTabs
          tabs={tabs}
          activeTab={activeTab}
          tabClickHandler={onTabClickHandler}
        />
        {activeTab === 1 ? (
          <MyVault />
        ) : activeTab === 2 ? (
          <ListedForSale setActiveTab = {setActiveTab}/>
        ) : activeTab === 3 ? (
          <ListedForAuction setActiveTab = {setActiveTab}/>
        ) : (
          <MyBids setActiveTab = {setActiveTab}/>
        )}
      </div>
    </ProfileContainer>
  );
};

export default Vault;
