import { Input } from "antd";
import React from "react";
export const BInput = (props) => {
  return (
    <>
      {" "}
      {props?.previousSibling ? (
        <div style={{ margin: "5px" }}>{props?.previousSibling}</div>
      ) : (
        <></>
      )}{" "}
      <Input
        placeholder={props?.placeholder}
        value={props?.value}
        onChange={(e) => props?.onChange && props?.onChange(e?.target?.value)}
        className={props?.className}
        disabled={props?.disabled}
        addonAfter={props?.addonAfter}
        suffix={props?.suffix}
        autoFocus={props?.autoFocus}
        id={props?.id}
        onPressEnter={props.onPressEnter}
        status={props.isError ? "error" : ""}
        name={props.name}
      />{" "}
      {props?.isError ? (
        <div className="danger" style={{ color: "red", fontSize: "10px", padding: "0 5px" }}>
          {props?.errorMsg}
        </div>
      ) : (
        <></>
      )}
      {props?.nextSibling ?? props?.nextSibling}{" "}
    </>
  );
};
