import React, { useEffect, useState } from "react";
import ProfileContainer from "../../components/ProfileContainer/Profile";
import { Row, Col,Form } from "react-bootstrap";
import styles from "./myorder.module.css";
import emptyOrder from "../../asset/emptyOrder.svg";
import Pagination from "../../components/Pagination/Paginations";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon";
import OrderHistory from "./OrderHistory";
import Nav from "react-bootstrap/Nav";
import { RxCross2 } from "react-icons/rx";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { commonFunction, usaPriceformat } from "../../utils/commonFunction";
import SearchNotFound from '../../asset/SearchNotFound.svg'

const MyOrder = () => {
  const navigate = useNavigate();

  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState("date");
  const [sortOrder, setSortOrder] = useState(1);
  const [searchResult,setSearchResult] = useState(false)
  const [transactionData, setTransactionData] = useState([]);
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState();
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [searchCard, setSearchCard] = useState();
  const handleSearch = (e, condition) => {
  if (searchCard) {
    if (e?.key === "Enter" || e?.keyCode === 13 || condition) {
      setIsLoading(true); 
      setCurrentActivePage(1)
      setSearchCard(searchCard);
      fetchTransactionData(currentActivePage, sortCriteria, sortOrder, searchCard);
    } 
  }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchCard(value);
    if (value === '') {
      setCurrentActivePage(1)
      fetchTransactionData(currentActivePage, sortCriteria, sortOrder, '');
    }
  }
  const removeText = () => {
     setSearchCard('')
    setIsLoading(true); 
     setCurrentActivePage(1)
     fetchTransactionData(currentActivePage, sortCriteria, sortOrder,'');
  }

  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => (currentActivePage -= 1));
    }
  };

  const nextHandler = () => {
    setCurrentActivePage((currentActivePage) => (currentActivePage += 1));
  };
  useEffect(() => {
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, totalPages]);

  const fetchTransactionData = (currentPage, sortCriteria, sortOrder, keyword = '') => {
    let url = `order/?page=${currentPage}&sort=${sortCriteria}&order=${sortOrder}`;
    if (keyword) {
      url += `&orderId=${keyword}`;
    }
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTotalPages(response?.data?.data?.totalPages);
          setTransactionData(response?.data?.data?.orders);
          setIsLoading(false);
          setSearchResult(false)
        }
      })
      .catch((error) => {
        setTransactionData([])
        setSearchResult(true)
        setIsLoading(false);
      });
  };
  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  };

  const handleSort = (event) => {
    const selectedValue = event.target.value;
    const selectedOrder =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-order"
      );
    setSortCriteria(selectedValue);
    setSortOrder(parseInt(selectedOrder, 10));
  };

  const getOrderDetails = (orderId, status) => {
    if (status == "payment_failed") {
      return;
    }

    setShow(!show);

    const url = `order/${orderId}`;
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setOrderDetail(response?.data?.data);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    fetchTransactionData(currentActivePage, sortCriteria, sortOrder, searchCard);
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }

    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, sortCriteria, sortOrder]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.dataLayer.push({
      event: "Pageview",
      pagePath: `${window.location.href}`,
      pageName: "My Order Page",
    });
  }, []);
  return (
    <ProfileContainer>
      <div className={styles.children_container}>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h3 className={styles.title}>My Orders</h3>
            <div className="customSearch newSearch">
             <div
              className={`d-flex align-items-center`}
            >
              <div className={`input-group`}>
                <Form.Control
                  className={`rounded-pill searchForm`}
                  type="search"
                  placeholder="Search for Order ID"
                  maxLength={20}
                  value={searchCard ? searchCard: ''}
                  onChange={handleChange}
                  onKeyDown={handleSearch}
                />
                <div className="input-group-append">
                  <span
                    className={`input-group-text bg-transparent border-0 icon searchIcon`}
                  >
                   <BiSearch onClick={()=>handleSearch('',true)}/>
                  </span>

                </div>
                 {searchCard && <div className="input-group-append" onClick={()=>removeText()}>
                  <span
                    className={`input-group-text bg-transparent border-0 icon searchIcon CrossIcon`}
                  >
                 <RxCross2 />
                  </span>
                </div> }
              </div>
          </div>
          </div>
          <div className="d-flex align-items-center sortingContainer">
           
            <span className="mx-2 sortBy">Sort by</span>
            <select
              className="sortingdropdown"
              onChange={(e) => handleSort(e)}
              // value={sortCriteria}
            >
              <option value="date" data-order="1">
                Newest to Oldest
              </option>
              <option value="date" data-order="-1">
                Oldest to Newest
              </option>
              {/* <option value="title" data-order="1">Name - A-Z</option>
      <option value="title" data-order="-1">Name - Z-A</option> */}
              <option value="price" data-order="1">
                Price - Low to High
              </option>
              <option value="price" data-order="-1">
                Price - High to Low
              </option>
            </select>
          </div>
        </div>
        <Row className={`${styles.headerRow} mt-4 respDev`}>
          <Col xs={2}>Order</Col>
          <Col>Status</Col>
          <Col>Qty</Col>
          <Col xs={2}>Order ID</Col>
          <Col xs={2}>Date</Col>
          <Col>Amount</Col>
          <Col xs={2}>Action</Col>
        </Row>

        {isLoading ? (
            <div className="mt-5">
            <Loader />
            </div>
        ) : transactionData?.length == 0 && !searchResult ? (
          <div className={styles.emptyVault}>
            <div className="d-flex align-items-center flex-column py-5 gap-3">
              <img src={emptyOrder} />
              <h2 className="emptyheading">No Order History!</h2>
              <p className="emptysubheading">Start Browsing the Marketplace</p>
              <ButtonIcon
                text={`Shop Now!`}
                className={styles.confirmBtn}
                clickHandler={() => {
                  navigate("/category/all-cards");
                }}
              />
            </div>
          </div>
        ) : searchResult ? (
                  <img src={SearchNotFound} alt='No Cards Available...' className='SearchNotFound SearchFailed' />
                ) : (
          transactionData?.length > 0 &&
          transactionData.map((val, idx) => {
            const { totalQty, status, listings, createdAt, orderId, summary } =
              val;
            return (
              <Row className={`${styles.card_Container} ${styles.myOrder}`} key={idx}>
                <Col lg={2} xs={4}>
                  <div className="d-flex align-items-center">
                    {totalQty - 1 >= 1 && (
                      <p
                        className={styles.remCount}
                        onClick={() => getOrderDetails(orderId, status)}
                      >
                        +{totalQty - 1}
                      </p>
                    )}

                    {listings?.slice(0, 1).map((item, index) => {
                      return (
                        <div className="d-flex" key={index}>
                          <img
                            src={
                              item?.listingInfo?.cardDetails?.image
                                ?.thumbnail ||
                              item?.listingInfo?.cardDetails?.image?.front
                            }
                            alt="card"
                            className={styles.card_img}
                            onClick={() => onClickHandler(item?.listingId)}
                          />
                          {listings.length === 1 && (
                            <div className={styles.card_content}>
                              {/* <div className={styles.card_title}>{item?.listingInfo?.cardDetails?.title}</div> */}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col
                  className={
                    status === "completed" ? styles.Tsucess : styles.Tfail
                  }
                >
                  <Col className="hideDesk">Status</Col>
                  {status === "completed" ? "Successful" : "Failed"}
                </Col>
                <Col>
                  <Col className="hideDesk">Qty</Col>
                  {totalQty}
                </Col>
                {/* <Col> <Col className="hideDesk">Order ID</Col>{ orderId?.split('-')[0]?.toUpperCase()}</Col> */}
                <Col lg={2} xs={4}>
                  {" "}
                  <Col className="hideDesk">Order ID</Col>
                  {orderId?.slice(orderId?.lastIndexOf("-") + 1).toUpperCase()}
                </Col>
                <Col lg={2} xs={4}>
                  {" "}
                  <Col className="hideDesk">Date</Col>
                  {commonFunction(createdAt)}
                </Col>
                <Col>
                  {" "}
                  <Col className="hideDesk">Amount</Col>
                  {summary?.total ? usaPriceformat(summary?.total) : "0.00"}
                </Col>
                <Col lg={2} xs={4}>
                  <Col className="hideDesk">Action</Col>
                  <ButtonIcon
                    iconPath=""
                   className={`${styles.seebtn} ${status === "payment_failed" ? styles.disabled : ''}`}

                    text="View Details"
                    clickHandler={() => {
                      getOrderDetails(orderId, status);
                    }}
                    isButtonDisable={status == "payment_failed"}
                  />
                </Col>
              </Row>
            );
          })
        )}
        {orderDetail && (
          <OrderHistory
            show={show}
            toggle={toggle}
            orderDetails={orderDetail}
          />
        )}
        {transactionData?.length > 0 && (
          <div className="mt-4">
            <Pagination
              currentActive={currentActivePage}
              totalPage={totalPages}
              PageHandler={PageHandler}
              previousHandler={previousHandler}
              nextHandler={nextHandler}
              disabledPrev={disabledPrev}
              disabledNext={disabledNext} // Pass the disabledNext state
            />
          </div>
        )}
      </div>
    </ProfileContainer>
  );
};

export default MyOrder;
