import axios from 'axios';
import history from "../components/history";
import { toast } from 'react-toastify';

// Common base URL for regular users
const baseURLRegular = window.location.origin == 'https://www.fantasy-cards.com' ? "https://api.fantasy-cards.com/api" : "https://fantasycards-api.mobileprogramming.net/api";
// Base URL for admin users

// Default headers
const defaultHeaders = {
  common: {
    "X-custom-header": "$Ecur!tyHEadEr"
  }
};

// Function to get token based on user type
const getToken = () => {
  // Check if adminToken is available
  if (window.location.pathname.startsWith("/admin")) {
    return localStorage.getItem('adminToken');
  } else {
    return localStorage.getItem('token');
  }
};

// Set axios base URL and default headers
axios.defaults.baseURL = baseURLRegular;
axios.defaults.headers = {
  ...axios.defaults.headers,
  ...defaultHeaders.common,
  Authorization: getToken()
};

// Request interceptor to set Authorization header
axios.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors
axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      // Handle unauthorized access
      if (window.location.pathname.startsWith("/admin")) {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminRole');
        history.push("/admin");
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userLastName");
        localStorage.removeItem("userFirstName");
        localStorage.removeItem("image");
        localStorage.removeItem("guestLogin");
         history.push("/login");
      }
      window.location.reload();
    } else if (error?.code === "ERR_NETWORK") {
      // Handle network errors
      toast.error("Network Error: Something went wrong.");
    }
    return Promise.reject(error);
  }
);

export default axios;
