import React, { useState } from "react";
import { Form,Container} from "react-bootstrap";
import styles from "./ForgotPassword.module.css";
import fantasyCards from "../../asset/FantasyCards.png";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import backgroundImage from "../../asset/loginPageBg.png";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const verifyEmail = () => {
    setIsLoading(true);

    // Show the alert before making the API call

    let data = JSON.stringify({
      email: email,
    });

    let config = {
      method: "post",
      url: `/forgotPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          localStorage.setItem("forgotEmail", email);
          toast.success("Verification code has been sent to your mail id.");
          navigate("/changePassword", {
            state: {
              email: email,
              forgotPassword: true,
            },
            replace: true,
          });
        } else {
          toast.error(
            response?.data?.message || "Something went wrong try again later."
          );
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message || "Something went wrong try again later"
        );
        setIsLoading(false);
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (isValid(value)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
    setEmail(value);
  };

  const isValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <div className="container-fluid">
      <div className="background-image">
        <img
          src={backgroundImage}
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
      </div>
      <Container className="d-flex justify-content-center align-items-center vheight">
        <div
          className={`bg-white rounded ${styles.forgotPass}`}
         
        >
          <h2 className="mb-4 text-center">
            <img src={fantasyCards} alt="logo" />
          </h2>

          <>
            <div className={styles.signinText}>Forgot Password</div>
            <div style={{ padding: "15px 0", fontSize: "12px" }}>
              We will send the link to reset your password on your email
            </div>
            <Form.Group controlId="text" style={{ marginTop: "10px" }}>
              <Form.Label>Email ID</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <div className={styles.otpText}>
              We’ll send you link on the above mentioned email.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                variant="primary"
                type="submit"
                className={styles.submit}
                onClick={verifyEmail}
                disabled={btnDisabled}
              >
                {/* SEND VERIFICATION CODE */}
                {isLoading ? "Loading..." : "SEND VERIFICATION CODE"}
              </button>
            </div>
          </>
        </div>
      </Container>
    </div>
  );
};

export default ForgotPassword;
