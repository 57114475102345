import React, {useContext, useEffect} from 'react'
import confirmImg from "../../asset/cart-page/confirmImg.svg"
import styles from "./Confirmation.module.css"
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon'
import { useNavigate } from 'react-router-dom'
import { OrderContext } from '../../Context/OrderContextProvider'
import { PaymentCardContext } from '../../Context/PaymentCardContext'
import Loader from '../../components/Loader/Loader';
// import payment_failed from '../../asset/payment-icons/transaction_failed.svg'
import ServerError from '../../asset/payment-icons/server_error.svg'



export default function Confirmation() {
   const navigate = useNavigate()
   const {orderDetail} = useContext(OrderContext);
   const {paymentStatus, isLoading} = useContext(PaymentCardContext);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },[])
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [isLoading])
  
  const alertUser = e => {
    e.preventDefault()
    e.returnValue = 'Are you sure?'
  }

  if (isLoading || paymentStatus ==='') {
    return (
          <div className={styles.confirmationContainer}>
            <div className='text-center'>
            <h2 className={styles.orderCompleted}>Transaction is in progress. Please wait...</h2>
              <Loader />
          </div>
        </div>
        )
  }
  if(paymentStatus === 1){
    return (
      <div className={styles.confirmationContainer}>
        <div className='text-center'>
  
          <img src={confirmImg} />
          <h2 className={styles.orderCompleted}>Your Order Is Completed !</h2>
          <p className={styles.confirmMsg}>You will receive an order confirmation email with details of your order.</p>
          <p className={`${styles.confirmMsg} ${styles.orderMSG}`}>Order ID: {orderDetail?.orderId}</p>
           <ButtonIcon text={`See Details`} className={styles.confirmBtn} clickHandler={() => {
                       navigate('/orders');
                      }} /> 
        </div>
      </div>
    )
  }

  if(paymentStatus === 0){
    return(
      <div className={styles.confirmationContainer}>
        <div className='text-center'>
  
          {/* <img src={payment_failed} /> */}
          <img src={confirmImg} />
          <h2 className={styles.orderCompleted}>Payment Failed!</h2>
          <p className={styles.confirmMsg}>Unfortunately payment was rejected.</p>
          {/* <p className={`${styles.confirmMsg} ${styles.orderMSG}`}>Order ID: {orderDetail?.orderId}</p> */}
           <ButtonIcon text={`Try Again`} className={styles.confirmBtn} clickHandler={() => {
                       navigate("/dashboard");
                      }} /> 
        </div>
      </div>
    )
  }

  if(paymentStatus === -1){
    return(
      <div className={styles.confirmationContainer}>
        <div className='text-center'>
  
          <img src={ServerError} className={styles.serverError} />

          <h2 className={styles.orderCompleted}>Temporary Server Failure!</h2>
          <p className={styles.confirmMsg}>A temporary server error has occurred.</p>
          {/* <p className={`${styles.confirmMsg} ${styles.orderMSG}`}>Order ID: {orderDetail?.orderId}</p> */}
           <ButtonIcon text={`Try Again`} className={styles.confirmBtn} clickHandler={() => {
                        navigate("/dashboard");
                      }} /> 
        </div>
      </div>
    )
  }


}
