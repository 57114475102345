import React, { useEffect, useState, useContext } from 'react';
import './PaymentStepper.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaDotCircle } from 'react-icons/fa';
import Navbar from '../Navigation/Navbar';
import Footer from '../Footer/Index';
import Cart from '../../Containers/Cart/Cart';
import Checkout from '../../Containers/Checkout/Checkout';
import Payment from '../../Containers/Payment/Payment';
import Confirmation from '../../Containers/Confirmation/Confirmation';
import stepper_bg from '../../asset/stepper_bg.jpg';
import BreadCrumbs from '../../Containers/BreadCrumb/BreadCrumbs';
import { CartContext } from '../../Context/CartItemContextProvider';
import Loader from '../../components/Loader/Loader';
import OrderContextProvider from '../../Context/OrderContextProvider';
import PaymentCardContextProvider from '../../Context/PaymentCardContext';

function getSteps(showCartStep) {
  return ['Checkout', 'Payment', 'Confirmation']
}

function Step({ label, index, activeStep, stepLen }) {
  const stepClass = `step ${activeStep === index ? 'step-active' : activeStep > index ? 'step-done' : 'step-inactive'}`;
  return (
    <div className={stepClass}>
      <div>
        <div className="circle">
          {activeStep > index ? <AiOutlineCheck className="check" /> : <FaDotCircle className="dot" />}
        </div>
      </div>
      <div className="label">{label}</div>
      {index < stepLen - 1 && <div className="line"></div>}
    </div>
  );
}

export default function DirectCheckoutStepper() {
  const { state, location } = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [showCartStep, setShowCartStep] = useState(false)
  const { isLoading, getCartItemDetail, setIsLoading, setItemCounter } = useContext(CartContext);

  useEffect(() => {
      setIsLoading(false);
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };
   useEffect(() => {
        return () => {
      if (location?.pathname !== "/cart" && location?.pathname !== "/checkout") {
        // Clear session storage when navigating away from the cart or checkout
       sessionStorage.removeItem('serviceType')
      }
    };
      
  },[location?.pathname])
const steps = getSteps();
  const getSectionComponent = () => {
      switch (activeStep) {
        case 0:
          return <Checkout activeStep={activeStep} setActiveStep={setActiveStep} bidId={state?.bidId} type="auction" buynow={state} />;
        case 1:
          return <Payment activeStep={activeStep} setActiveStep={setActiveStep} type="auction" buynow={state}/>;
        case 2:
          return <Confirmation activeStep={activeStep} setActiveStep={setActiveStep} type="auction" />;
        default:
          return null;
      }

  };

  return (
    <OrderContextProvider>
      <PaymentCardContextProvider>
        <div>
          {/* <Navbar /> */}
          <BreadCrumbs />
          <Container fluid>
            <Row>
              {isLoading ? (
                <Loader />
              ) : (
                <Row className="stepper">
                  <img src={stepper_bg} alt="bg-img" className="stepper-bg" />
                  <div className="steps-container">
                    {steps.map((label, index) => (
                      <Step key={index} label={label} index={index} stepLen={3} activeStep={activeStep} />
                    ))}
                  </div>
                  <Col md={12} className="main-component">
                    {getSectionComponent()}
                  </Col>
                </Row>
              )}
            </Row>
          </Container>
          {/* <Footer /> */}
        </div>
      </PaymentCardContextProvider>
    </OrderContextProvider>
  );
}
