import React, { useEffect, useState } from "react";
import {Row, Col } from "react-bootstrap";
import styles from "./vault.module.css";
import Pagination from "../../components/Pagination/Paginations";
import ProductCard from "../../components/ProductCard/Profile/Index";
// import { useNavigate } from 'react-router-dom'
import bidImg from "../../asset/vault-page/bidImg.svg"
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../components/Loader/Loader";

const MyBids = ({setActiveTab}) => {
  const navigate = useNavigate()

  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState('date');
  const [sortOrder, setSortOrder] = useState(1);
  const [bidsData, setBidsData] = useState([])
    const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const [isLoading, setIsLoading] = useState(true);
   const [totalPages, setTotalPages] = useState()
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
     const [currentStatus, setCurrentStatus] = useState(false);

 
  const PageHandler = (id) => {
    setCurrentActivePage(id);
  };

  const previousHandler = () => {
    if (currentActivePage > 1) {
      setCurrentActivePage((currentActivePage) => (currentActivePage -= 1));
    }
  };

  const nextHandler = () => {
    setCurrentActivePage((currentActivePage) => (currentActivePage += 1));
  };

  const fetchBidData = (currentPage, sortCriteria, sortOrder) => {
    setCurrentStatus(false)
    const url = `bids/?page=${currentPage}&sort=${sortCriteria}&order=${sortOrder}`;
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
           setTotalPages(response?.data?.data?.totalPages)
          setBidsData(response?.data?.data?.bids);
          setIsLoading(false);
        } 
      })
      .catch((error) => {
      toast.error(error?.response?.data?.message)
        setIsLoading(false);
      });
  };
    const onClickHandler = (listingId) =>{
      navigate(`/card-details/${listingId}`);
  }
 
 const handleSort = (event) => {
  const selectedValue = event.target.value;
  const selectedOrder = event.target.options[event.target.selectedIndex].getAttribute('data-order');
    setSortCriteria(selectedValue);
    setSortOrder(parseInt(selectedOrder, 10));
  };


  useEffect(()=>{
    fetchBidData(currentActivePage, sortCriteria, sortOrder)
    if (currentActivePage === 1) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
    }
  
    if (currentActivePage === totalPages) {
      setDisabledNext(true);
    } else {
      setDisabledNext(false);
    }
  }, [currentActivePage, sortCriteria, sortOrder])
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': `${window.location.href}`,
        'pageName': 'Bids Page',
      });
    }, [])
  
   useEffect(() => {
  if (currentActivePage === totalPages) {
    setDisabledNext(true);
  } else {
    setDisabledNext(false);
  }
   }, [currentActivePage, totalPages]);
  
  const handleTimerExpirationForItem =  () => {
     fetchBidData(currentActivePage, sortCriteria, sortOrder)
  }
   useEffect(() => {
    const statusVal = bidsData?.some(item => item.status === "won");
    setCurrentStatus(statusVal);
  }, [bidsData]);

  return (
    <>
      <div className="commonsection">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className={styles.title}>My Bids</h3>
        <div className="d-flex align-items-center">
          <span className="mx-2 sortBy">Sort by</span>
                  <select
      className="sortingdropdown"
      onChange={(e) => handleSort(e)}
      // value={sortCriteria}
    >
      <option value="date" data-order="1">Newest to Oldest</option> 
      <option value="date" data-order="-1">Oldest to Newest</option> 
      <option value="title" data-order="1">Name - A-Z</option>
      <option value="title" data-order="-1">Name - Z-A</option>
      <option value="price" data-order="1">Price - Low to High</option>
      <option value="price" data-order="-1">Price - High to Low</option>
    </select>
        </div>
      </div>
      {isLoading ? <Loader /> :
        (<Row className={styles.card_Container}>
          {
            bidsData?.length == 0 ?
              <div className={styles.emptyVault}>
                <div className='d-flex align-items-center flex-column py-5 gap-3'>
                  <img src={bidImg} />
                  <h2 className="emptyheading">Ready to Bid, Ready to Win,<br />Jump into the Auction Action Today!</h2>
                  <p className="emptysubheading">Click the button to start bidding.</p>
                    <ButtonIcon text={`View Auction Items`} className={styles.confirmBtn} clickHandler={() => {
                       navigate("/category/all-cards", { state: { Type: 'auction' } });
                    }} />
                </div>
              </div>
              :
              bidsData?.map((val, idx) => {
                const { cardId, auction, cardInfo, listingId, topThreeBids, listingInfo, status, bidId } = val
                return (<Col lg={6} md={12} key={idx}>
                  <ProductCard
                   title={listingInfo?.cardInfo?.title}
                   description={`${listingInfo?.cardInfo?.description.length> 18 ? listingInfo?.cardInfo?.description.split(' ').slice(0, 18).join(' ')+"...":listingInfo?.cardInfo?.description }`}
                    imgUrl={listingInfo?.cardInfo?.image?.thumbnail || listingInfo?.cardInfo?.image?.front}
                    currentBid={listingInfo?.auction?.currentBid}
                    price={val?.price}
                    bidTimer = {listingInfo?.auction?.endDate}
                    isTypeBid={true}
                    cardInfo={listingInfo?.cardInfo}
                    listingId={listingId}
                    bidStatus={status}
                    bidId={bidId}
                    paymentTimer={val?.payment?.time}
                    handleTimerExpiration={() => handleTimerExpirationForItem(listingId)}
                    cardDetailType= {listingInfo?.cardInfo?.type}
                  />
                </Col>
              )})}
        </Row>)
      }
      {bidsData?.length > 0 && <div className="mt-4">
          <Pagination
          currentActive={currentActivePage}
          totalPage={totalPages}
          PageHandler={PageHandler}
          previousHandler={previousHandler}
          nextHandler={nextHandler}
          disabledPrev={disabledPrev}
          disabledNext={disabledNext}
        />
        </div>}
        </div>
    </>
  );
};

export default MyBids;
