import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
// import { CloseOutlined } from "@ant-design/icons";
import styles from "./LoginPage.module.css";
import fantasyCards from "../../asset/FantasyCards.png";
import google from "../../asset/google-icon.svg";
import apple from "../../asset/apple-icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import backgroundImage from "../../asset/loginPageBg.png";
// import Button from "@mui/material/Button";
import { MailOutlined } from "@ant-design/icons";
import { BInput } from "../../components/bInput";
import { BPasswordInput } from "../../components/bPasswordInput";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonIcon from "../../components/ButtonWithIcon/buttonIcon"
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignIn  from 'react-apple-signin-auth';


export default function LoginPage() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(localStorage?.getItem("remembered") ? true : false);
  const [email, setEmail] = useState(localStorage?.getItem("email") || "");
  const [password, setPassword] = useState(localStorage?.getItem("password") || "");
  const [enableLoginBtn, setEnableLoginBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const remembered = localStorage.getItem("token");
    if (remembered) {
      navigate("/dashboard");
    }
  }, []);

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  const isValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  useEffect(() => {
    if (isValid(email) && password.length > 0) {
      setEnableLoginBtn(true);
    } else {
      setEnableLoginBtn(false);
    }
  }, [email, password]);



  
  const handleAppleSignIn = () => {
    // Perform Apple sign-in logic here
  };
  
  
  const handleGoogleSignIn = useGoogleLogin({
  onSuccess: async ({ code }) => {
    try {
      const response = await axios.post('auth/google', {
        code,
      });
      if (response.status === 200) {

        localStorage.setItem("token", response?.data?.data?.token);
          localStorage.setItem("userId", response?.data?.data?.userId);
          localStorage.setItem("userFirstName", response?.data?.data?.firstName);
          localStorage.setItem("userLastName", response?.data?.data?.lastName);
          localStorage.setItem("email", response?.data?.data?.email?.value);
        localStorage.setItem("guestLogin", false);
         navigate("/dashboard", { state: { email: email }, replace: true });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  },
  flow: 'auth-code',
});





  const loginUser = () => {
    setIsLoading(true);
    let data = JSON.stringify({
      email: email,
      password: password,
    });
    let config = {
      method: "post",
      url: `login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.statusCode === 200) {
          localStorage.setItem("token", response?.data?.data?.token);
          localStorage.setItem("userId", response?.data?.data?.userId);
          localStorage.setItem("userFirstName", response?.data?.data?.firstName);
          localStorage.setItem("userLastName", response?.data?.data?.lastName);
          // localStorage.setItem("image", response?.data?.data?.image);
          localStorage.setItem("email", response?.data?.data?.email?.value);
          localStorage.setItem("password", password);
          localStorage.setItem("guestLogin", false);
          if (rememberMe && response?.data?.data?.user?.token) {
            localStorage.setItem("remembered", true)
          } else {
            localStorage.removeItem("remembered")
            localStorage.removeItem("email")
             localStorage.removeItem("password")
          }
          navigate("/dashboard", { state: { email: email }, replace: true });
        } else if(response?.data?.message == "Email not verified") {
          navigate("/otpVerify", { state: { email: email, emailyVerIfy:true }, replace: true });
        } else {
       toast.error(response?.data?.message);
          // setEmail("");
          // setPassword("");
          setEnableLoginBtn(false);
        }
      })
      .catch((error) => {
         toast.error(error.response?.data?.message)
        setIsLoading(false);
        setEmail("");
        setPassword("");
        setEnableLoginBtn(false);
      });
  };
  return (
    <div className="container-fluid">
      <div className={styles.backgroundImage}>
        <img
          src={backgroundImage}
          style={{ objectFit: "cover", height: "100%", width: "100%", overflow:"hidden" }}
        />
      </div>
      <Container className="d-flex justify-content-center align-items-center vheight">
        <div
          className="bg-white rounded "
        >
          <h2 className="lg-mb-4 text-center">
            <img src={fantasyCards} alt="logo" className={styles.mainLogo} />
          </h2>
          <div className={styles.signinText}>SIGN IN</div>
          <div className="mt-3">
            <BInput
              previousSibling={<div className={styles.formLabel}>Email Id</div>}
              placeholder={"Enter details"}
              autoFocus={true}
              value={email}
              onChange={(value) => handleEmailChange(value)}
              suffix={isValid(email) && <MailOutlined />}
              errorMsg={""}
              className={styles.formControl}
            />
          </div>

          <div className="mt-3">
            <BPasswordInput
              previousSibling={
                <div>
                  <span className={styles.formLabel}>Password</span>
                </div>
              }
              placeholder={"Password"}
              className={styles.formControl}
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              errorMsg={""}
              onPressEnter={() => enableLoginBtn && loginUser()}
            />
          </div>
          <div
            className="form-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
              fontSize: "10px",
            }}
          >
            <div className="checkbox-group" style={{ display: "flex" }}>
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                checked={rememberMe}
                style={{ marginRight: "4px" }}
                onChange={handleRememberMeChange}
              />
              <label htmlFor="rememberMe">Remember Me</label>
            </div>
            <Link
              to="/forgotPassword"
              className="lost-password-link"
              relative="path"
            >
              Forgot Password?
            </Link>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonIcon
              type='submit'
              className={`${styles.submit} userLogin`}
              clickHandler={loginUser}
              isButtonDisable={!enableLoginBtn}
              disabled={!enableLoginBtn || isLoading}
              text = {isLoading ? "LOADING..." : "Login"}
            />
            {/* <Button
              variant="contained"
              type="submit"
              color="secondary"
              className={styles.submit}
              onClick={loginUser}
              // disabled={!enableLoginBtn}
              disabled={!enableLoginBtn || isLoading}
            > */}
              {/* LOG IN */}
              {/* {isLoading ? "LOADING..." : "LOG IN"}
            </Button> */}
          </div>
          <div className={styles.orText}>OR</div>
          <div className={styles.socialLogin}>
          <ButtonIcon 
              iconPath = {google}
              type='submit'
              className={`${styles.googleLogin} googleSignIn`}
              clickHandler={handleGoogleSignIn}
              disabled={!enableLoginBtn || isLoading}
              text = {"Continue with Google"}
            />
             {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
          
       
<AppleSignIn
  authOptions={{
    clientId: 'com.fantasycards.web',
    scope: 'email name',
    redirectURI: `${window.location.origin=="https://www.fantasy-cards.com/" ? "https://www.fantasy-cards.com/" : "https://fantasycards-qa.mobileprogramming.net/"}`,
    state: 'origin:web',
    nonce: 'nonce',
    usePopup: true,
  }}
  onSuccess={async function(response) {

    try {
      const res = await axios.post('auth/apple', {
        id_token: response?.authorization?.id_token, 
      });

      if (res?.status === 200) {

        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("userId", res.data.data.userId);
        localStorage.setItem("userFirstName", res.data.data.firstName || "");
        localStorage.setItem("userLastName", res.data.data.lastName || "");
        localStorage.setItem("email", res.data.data.email.value);
        localStorage.setItem("guestLogin", false);
        

        navigate("/dashboard");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }}
  render={(props) => (
    <div className="btnStyle" {...props}>
      <ButtonIcon
        iconPath={apple}
        type='submit'
        className={`${styles.googleLogin} ${styles.appSign} appleSignIn`}
        clickHandler={handleAppleSignIn}
        disabled={!enableLoginBtn || isLoading}
        text="Continue with Apple"
      />
    </div>
  )}
/>



            {/* <Button
              variant="secondary"
              onClick={handleAppleSignIn}
              className={styles.googleLogin}
              style={{ backgroundColor: "#ffffff", color: "black" }}
            >
              <img src={google} alt="google" height="25px" />
              Continue with Google
            </Button>
            <Button
              variant="secondary"
              onClick={handleAppleSignIn}
              className={styles.googleLogin}
              style={{ backgroundColor: "#ffffff", color: "black" }}
            >
              <img src={apple} alt="google" height="25px" />
              Continue with Apple
            </Button> */}
          </div>
          <div className={styles.signupText}>
            <div>
              New Here?
              <Link to="/signup" relative="path">
                {" "}
                Sign up now
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
