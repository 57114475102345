import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';

export default function SortFilters({ fields, selected, onSortingChange }) {
  const sortedFields = [...fields];
  
  return (
    <Nav>
      <NavDropdown
        id="nav-dropdown-dark-example"
        title={selected.name ? selected.name :'Newest'}
        menuVariant="light"
        style={{ border: '1px solid #E1E1E1', borderRadius: '5px', color: '#78828B' }}
      >
        {sortedFields.map((item, index) => (
          <NavDropdown.Item
            key={index}
            active={selected.id === item.id}
            onClick={() => onSortingChange(item.name, item.id)}
          >
            {item.name}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  );
}
