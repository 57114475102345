import React from "react";
import "./Index.css";

const Index = ({ title, subTitle, textClr }) => {
  return (
    <>
      <div className="mt-5 side_title_container">
        <div className="side_title text-uppercase">{title}</div>
        <div className="mt-1">
          <h2 className="side_subTitle text-uppercase" style={{ color: textClr }}>
            {subTitle}
          </h2>
        </div>
      </div>
    </>
  );
};

export default Index;
