import React from 'react'
import ProductGrid from '../../components/ProductCard/ProductGrid/Index'
import styles from './GridView.module.css'


export default function GridView({products}) {
  return (
    <div className="container my-5" >
      <div className="row row-cols-1 row-cols-sm-3 row-cols-md-4">
          {products!== undefined && products.map((val, index) =>{
            const {marketplace, cardInfo, listingId, auction, type, isFavourite} =  val
            return(
            <div key = {index} className = {styles.product} >
              <ProductGrid
                key = {index}
                title={`${cardInfo?.title}`}
                price={`${marketplace?.price}`}
                imgUrl={cardInfo?.image?.thumbnail || cardInfo?.image?.front }
                type = {type}
                minBid ={auction?.minBid}
                currentBid = {auction?.currentBid}
                bidTime = {auction?.endDate}
                rarity = {cardInfo?.rarity}
                listingId={listingId}
                isFavourite={isFavourite}
              />
            </div>     
            )})}
          </div>
        </div>
  )
}
