import React, { createContext,useState} from 'react';
import axios from "../utils/axiosConfig";

export const CategoryContext = createContext();
//  const isAuthenticated = localStorage.getItem("token") ? true : false;

const CategoryProvider = ({ children }) => {
    const [catSelectedTab, setCatSelectedTab] = useState({id:'', name:''})
    const [catTabs, setCatTabs] = useState([])
  
    const fetchCategory =()=>{ 
        const url = 'category/all'
        let config = {
        method: "get",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
    };  
    axios
    .request(config)
    .then((response) => {
    if (response?.data?.statusCode === 200) {
        const catTab = response?.data?.data?.categoryList
        setCatTabs([{name:'all-cards', categoryId:''}, ...catTab]);
    }
    })
    .catch((error) => {
    });
    };
    

    // useEffect(()=>{
    //    isAuthenticated && fetchCategory()
    // },[])
    const contextValue = {
        catSelectedTab, setCatSelectedTab,
        catTabs, setCatTabs,fetchCategory
        
    }
    return (
    <CategoryContext.Provider value={contextValue}>
      {children}
    </CategoryContext.Provider>
  );
};
export default CategoryProvider;