import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { BiStopwatch } from 'react-icons/bi';
import { getTimeRemaining, usaPriceformat } from '../../utils/commonFunction';
import ButtonIcon from '../../components/ButtonWithIcon/buttonIcon';
import styles from './ListView.module.css';
import { Link, useNavigate } from 'react-router-dom';
import heartFillBtn from "../../asset/heartFillBtn.svg";
import GuestLogin from '../../Containers/Login/GuestLogin';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function ListCard({
  index,
  marketplace,
  minBid,
  currentBid,
  type,
  bidTime,
  listingId,
  cardInfo,
  isFavourite
}) {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(bidTime));
  const navigate = useNavigate();
  const [heartBtns, setHeartBtns] = useState(isFavourite);
    const [guestLogin, setGuestLogin] = useState()
  const [showGuest, setShowGuest] = useState(false);
  const toggleGuest = () => setShowGuest(!showGuest);
  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(getTimeRemaining(bidTime));
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [bidTime]);

  const addRemoveToWishList = () => {
    if (guestLogin == "true" || guestLogin==null) {
      setShowGuest(!showGuest);
    } else {
      setHeartBtns(value => !value);
      let data = JSON.stringify({
        listingId: listingId,
      });
      let config = {
        method: "post",
        url: `addRemoveWishlist`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            toast.success(response?.data?.message);         
          }
        })
        .catch((error) => {
          
        });
    }
  }
   useEffect(() => {
    const gLogin = localStorage.getItem('guestLogin');
    setGuestLogin(gLogin);
  }, []);

  return (
    <Container
      key={index}
      fluid
      className={`md-12 ${styles.container}`}
     
    >
      <Row>
        <Col xs={12} md={2} className={`productimg ${styles.productimg}`}>
          {/* Apply module style */}
          <img
            src={cardInfo?.image?.thumbnail || cardInfo?.image?.front}
            alt={cardInfo.title}
            className='cursor-pointer'
             onClick={() => onClickHandler(listingId)}
          />
        </Col>
        <Col xs={12} md={10} className={`d-flex`}>
          <div className={`${styles.cardDetails}`}>
            <div className={`${styles.cardTitle}`}>
              <h4>{cardInfo.title}</h4>
               { type === 'marketplace' && <div className="card-wishlist cursor-pointer" >
                <img className={styles.whiteWishlist} src={heartBtns ? heartFillBtn : "/Images/wishlistBlank.svg"} onClick={()=>addRemoveToWishList(listingId)}/>
                  </div> }
             &nbsp; {type === 'auction' && (
               <span className="time">
                  <BiStopwatch
                    color="#9547cf"
                    fontSize="20px"
                    className="stopWatch"
                  />
                  {timeRemaining !== '0:00:00:00' ? (
                    `${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`
                  ) : (
                    timeRemaining
                  )}
                </span>
              )}
            </div>
            {type === 'marketplace' ? (
              <h5 className={`price ${styles.price}`}>
                {usaPriceformat(marketplace?.price)}
              </h5>
            ) : (
              <div className={`${styles.auctionPrice}`}>
                {currentBid >0 && (
                  <h5 className={`price ${styles.currentBid}`}>
                    Current Bid: <span>{usaPriceformat(currentBid)}</span>
                  </h5>
                )}
                {minBid && (
                  <h5 className={`price ${styles.minBid}`}>
                    Min Bid: <span> {usaPriceformat(minBid)}</span>
                  </h5>
                )}
              </div>
            )}

            <p>
              {cardInfo.description.split(' ').slice(0, 40).join(' ')}
                 {cardInfo.description.length>40 && <>
              ... <Link to={`/card-details/${listingId}`} >See more</Link>
              </>}

            </p>
            <ButtonIcon
              clickHandler={() => {
                onClickHandler(listingId);
              }}
              text={'Details'}
            />
          </div>
        </Col>
      </Row>
      <hr className={`mt-5 mb-5 ${styles.divider}`} />
       {(guestLogin=="true" || guestLogin==null) && <GuestLogin
                show={showGuest}
              toggle={toggleGuest} />
            }
    </Container>
  );
}
