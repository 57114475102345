import Spinner from 'react-bootstrap/Spinner';
import React from 'react';
import styles from './Loader.module.css'

function Loader() {
  return (
    <Spinner animation="border" role="status" className ={styles.loadercontainer}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Loader