import React, { useState } from "react";
import styles from "./OrderHistory.module.css";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Toast } from "react-bootstrap";
import { commonFunction, usaPriceformat } from "../../utils/commonFunction";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function OrderHistory({ show, toggle, orderDetails }) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const onClickHandler = (listingId) => {
    navigate(`/card-details/${listingId}`);
  };

  const downloadInvoice = (orderId) => {
    // return;
    toast.success("Downloading Invoice");
    const url = `downloadInvoice/${orderId}`;
    axios
      .get(url, { responseType: "blob" })
      .then((response) => {
        if (response?.data) {
          const blob = new Blob([response?.data], { type: "application/pdf" });

          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute("download", `invoice_${orderId}.pdf`);
          downloadLink.click();

          window.URL.revokeObjectURL(downloadLink.href);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const emailInvoice = (orderId) => {
    // return;
    let config = {
      method: "get",
      url: `sendInvoice/${orderId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={toggle}
        dialogClassName="bid-modal orderdetails"
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles.OrderDetailstitle}>
            <div>Order Details</div>
            {orderDetails?.status == "completed" && (
              <div className={styles.invoiceBtns}>
                <img
                  src="/Images/emailInvoice.svg"
                  title="Email Invoice"
                  className="cursor-pointer"
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => emailInvoice(orderDetails?.orderId)}
                  style={{ display: isHovered === true ? "none" : "block" }}
                />

                <img
                  src="/Images/emailPurple.svg"
                  title="Email Invoice"
                  className="cursor-pointer"
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => emailInvoice(orderDetails?.orderId)}
                  style={{ display: isHovered === false ? "none" : "block" }}
                />

                <img
                  src="/Images/pdfDownload.svg"
                  title="Download Invoice"
                  className="cursor-pointer"
                  onMouseEnter={() => handleMouseEnter1()}
                  onMouseLeave={handleMouseLeave1}
                  onClick={() => downloadInvoice(orderDetails?.orderId)}
                  style={{ display: isHovered1 === true ? "none" : "block" }}
                />

                <img
                  src="/Images/pdfPurple.svg"
                  title="Download Invoice"
                  className="cursor-pointer"
                  onMouseEnter={() => handleMouseEnter1()}
                  onMouseLeave={handleMouseLeave1}
                  onClick={() => downloadInvoice(orderDetails?.orderId)}
                  style={{ display: isHovered1 === false ? "none" : "block" }}
                />
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <div className="orderInfo">
          <div className="mb-2 orderDet">
            <div>
              <b>Order ID :</b>
              &nbsp;
              {orderDetails?.orderId
                ?.slice(orderDetails?.orderId?.lastIndexOf("-") + 1)
                .toUpperCase()}
            </div>
            <div className="mx-md-5">
              <b>Date :</b> {commonFunction(orderDetails?.createdAt)}
            </div>
          </div>
          {/* <div className="d-flex justify-content-between mb-3 mt-3 orderDet">
          <div><b>Sold by :</b> 
            &nbsp;Kalie Morgan</div>
          <div className=""><b>Delivery Status :</b> <span style={{color:"#00A324"}}>Delivered</span>, 5, December, 2023</div>
        </div> */}
          <div className="d-flex justify-content-between mb-3 orderDet">
            <div className="addressInfo">
              <b>Billing Address :</b>
              &nbsp;
              <span>
                {orderDetails?.billingDetails?.addressLine1},{" "}
                {orderDetails?.billingDetails?.addressLine2}
                {orderDetails?.billingDetails?.landmark
                  ? ", " + orderDetails?.billingDetails?.landmark
                  : ""}
                , {orderDetails?.billingDetails?.postCode},
              </span>
              <div>
                {orderDetails?.billingDetails?.townCity},{" "}
                {orderDetails?.billingDetails?.state},{" "}
                {orderDetails?.billingDetails?.country}{" "}
              </div>
              <div>
                Phone Number : {orderDetails?.billingDetails?.phoneNumber}
              </div>
            </div>
            <div className="md-mx-2" >
              <b>Payment Method :</b> <span style={{textTransform:"capitalize"}}>{orderDetails?.paymentCard?.cardType} Card Ending in {orderDetails?.paymentCard?.last4}</span>
            </div>
          </div>
        </div>
        <Modal.Body className={styles.ordermodal}>
          <Row className={`${styles.headerRow} mt-4 respDev`}>
            <Col xs={2}>Order</Col>
            <Col xs={2}>Title</Col>
            <Col xs={2}>Seller Name</Col>
            <Col xs={2}>Quantity</Col>
            <Col xs={2}>Unit Price</Col>
            {/* <Col>Tax</Col>
          <Col>Total</Col> */}
            <Col xs={2}>Status</Col>
          </Row>

          {/* {isLoading ? (
        <div><Loader/></div>
      ) : */}
          {orderDetails?.listings?.length > 0 &&
            orderDetails?.listings?.map((val, idx) => {
              const { qty, cardInfo, listingInfo, orderId, listingId } = val;
              return (
                <Row className={styles.card_Container} key={idx}>
                  <Col lg={2} xs={4}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex ">
                        <img
                          src={
                            val?.listingInfo?.cardDetails?.image?.thumbnail ||
                            val?.listingInfo?.cardDetails?.image?.front
                          }
                          alt="card"
                          className={styles.card_img}
                          onClick={() => onClickHandler(listingId)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} xs={4}>
                    <Col className="hideDesk">Title</Col>
                    <div className={styles.card_content}>
                      <div className={styles.card_title}>
                        {val?.listingInfo?.cardDetails?.title}
                      </div>
                      <div className={`${styles.card_dec} respDev`}>
                        {val?.listingInfo?.cardDetails?.description?.length <=
                        100
                          ? val.listingInfo.cardDetails.description
                          : `${val.listingInfo.cardDetails.description.slice(
                              0,
                              100
                            )}...`}
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} xs={4}> <Col className="hideDesk">Sold By</Col> {val?.listingInfo?.sellerName}</Col>

                  <Col lg={2} xs={4}>
                    {" "}
                    <Col className="hideDesk">Quantity</Col>
                    {qty}
                  </Col>
                  {val?.listingInfo?.type === "auction" ? (
                    <Col lg={2} xs={4}>
                      {" "}
                      <Col className="hideDesk">Unit Price</Col>
                      {val?.listingInfo?.auction?.currentBid
                        ? usaPriceformat(val?.listingInfo?.auction?.currentBid)
                        : "$0.00"}
                    </Col>
                  ) : (
                    <Col lg={2} xs={4}>
                      <Col className="hideDesk">Unit Price</Col>
                      {val?.listingInfo?.marketplace?.price
                        ? usaPriceformat(val?.listingInfo?.marketplace?.price)
                        : "$0.00"}
                    </Col>
                  )}
                  <Col lg={2} xs={4} style={{ textTransform: "capitalize" }}>
                    <Col className="hideDesk">Status</Col> 
                    {val?.status==="shipment created" ? <a href={`https://www.fedex.com/wtrk/track/?trknbr=${val?.trackingNumber}`} className="trackingCard" target="_blank">Track</a> : val?.status}
                  </Col>
                </Row>
              );
            })}
          <div className="d-block taxCal">
            <p>
              <b className="mt-2">
                Item Subtotal : {orderDetails?.summary?.subTotal
                  ? usaPriceformat(orderDetails?.summary?.subTotal)
                  : "$0.00"}
                
              </b>
            </p>
            <p>
              <b className="mt-2">
                Shipping : {orderDetails?.summary?.shipping
                  ? usaPriceformat(orderDetails?.summary?.shipping)
                  : "$0.00"}
                
              </b>
            </p>
            <p>
              <b className="mt-2">
                Taxes : {orderDetails?.summary?.tax
                  ? usaPriceformat(orderDetails?.summary?.tax)
                  : "$0.00"}
                
              </b>
            </p>
            <p>
              <b className="mt-2">
                Grand Total : {orderDetails?.summary?.total
                  ? usaPriceformat(orderDetails?.summary?.total)
                  : "$0.00"}
                
              </b>
            </p>
          </div>

          {/* <h1>DSAFS</h1> */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OrderHistory;
